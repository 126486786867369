// Chakra imports
import { Text, Flex, Grid, GridItem, Input, useColorModeValue, useToast, Textarea, Checkbox } from "@chakra-ui/react";
import ProfileBgImage from "../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import ProductThumbnailUpload from "./../components/productThumbnailUpload";
import ProductPrices from "../components/productPrices";
import AddExtraServiceProductsList from "../components/addExtraServiceProductsList";
import ConditionList, { conditionType } from "../components/conditionList";

const GET_PRODUCT = gql`
query getProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    description
    extraService
    allowMultiple
    conditions {
      id
      conditionType
      field {
        id
        name
        fieldType
      }
      operator
      value
    }
    thumbnail {
      id
      filename
      orgUrl
    }
  },
  conditionFields {
    id
    name
    fieldType
    values
  }

  
}`


const UPDATE_PRODUCT = gql`
mutation updateProduct($input: UpdateProductInput!){
  updateProduct(input:$input){
    id
  }
}`

/*
const CreateProductObj = {
  name: '' as string,
  productCategoryId: '' as string,
}

const UpdateProductCategoryObj = {
  id: '' as string,
  name: '' as string,
  showOnOrderForm: false as boolean,
}
*/

function Product() {
  // Chakra color mode
  const toast = useToast()

  interface Params {
    productId: string;
  }

  const { productId } = useParams<Params>();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );


  const [updateProduct] = useMutation(
    UPDATE_PRODUCT,
    {
      refetchQueries: [GET_PRODUCT],
      fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
    }
  )

  const { loading, error, data, refetch } = useQuery(GET_PRODUCT, {
    variables: {
      id: productId
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }


  const updateData = (field, value) => {
    // value = value.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");

    const dataObj = {}

    dataObj['id'] = productId;
    dataObj[field] = value;

    updateProduct({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };


  const colspan = data.getProduct.extraService ? 3 : 2;

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Produkter"}
        description={"Her kan du redigere dine produkt kategorier og produkter."}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={true}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={colspan} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Produkt
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
                    navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.getProduct.name}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
                </Flex>
                <Flex direction='column' style={{ width: '100%' }}>
                  <Flex align='center' mb='18px'>
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"} height={'100%'}>
                      Beskrivelse:{" "}
                    </Text>
                    <Textarea padding={0} variant="flushed" name="description" defaultValue={data.getProduct.description} onBlur={(e) => updateData('description', e.target.value)} />
                  </Flex>
                </Flex>
                {data.getProduct.extraService && (
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
                    Mulighed for køb af flere på samme ordre:{" "}
                  </Text>
                  <Checkbox
                    variant="flushed"
                    onChange={(e) => updateData('allowMultiple', e.target.checked)}
                    defaultChecked={data.getProduct.allowMultiple}
                  />
                  </Flex>
                )}

              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        {!data.getProduct.extraService && (
        <GridItem colSpan={1} rowSpan={0} >
          <ProductThumbnailUpload data={data.getProduct} heading={"Thumbnail"} subHeading={"(160 x 80 px)"} forceRerender={refetch} />
        </GridItem>
        )}

        <GridItem colSpan={3} rowSpan={0} >
          <ConditionList conditionFields={data.conditionFields} refId={data.getProduct.id} type={conditionType.Product} forceRerender={refetch} conditions={data.getProduct.conditions} />
        </GridItem>

        <GridItem colSpan={3} rowSpan={0} >
          <ProductPrices productId={data.getProduct.id} />
        </GridItem>

        {!data.getProduct.extraService && (
        <GridItem colSpan={3} rowSpan={0} >
          <AddExtraServiceProductsList productId={data.getProduct.id} />
        </GridItem>
        )}
      </Grid>




    </Flex>


  );
}

export default Product;
