import {
    Flex, Text, useColorModeValue, useToast,
    Icon,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Button, Tabs, TabList, Tab, TabPanels, TabPanel, Input, Grid, Tooltip, GridItem, Checkbox
} from "@chakra-ui/react";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { useEffect, useState, useRef } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import Editor from "../../../../components/Editor/Editor";
import Uppy from '@uppy/core'
import { FaInfoCircle } from "react-icons/fa";

import AwsS3 from '@uppy/aws-s3'
import { Dashboard } from '@uppy/react'
import Danish from '@uppy/locales/lib/da_DK';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import React from "react";

const CREATE_SUBCONTRACTOR_ORDER = gql`
  mutation createSubcontractorOrder($input: CreateSubcontractorOrderInput!) {
    createSubcontractorOrder(input: $input) {
      id
    }
  }
`;

const CREATE_LOG = gql`
  mutation createLog($input: CreateLogInput!) {
    createLog(input: $input) {
      id
    }
  }
`;


const GET_SUBCONTRACTORS = gql`
  query currentUser($id: ID!) {
    order: getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
    }
    currentUser {
      id
        photographyCompany {
            id
            subcontractors {
                id
                name
                email
                subcontractorType
                language
                mailSubject
                mailText
                bookingEmail
                isGlobal
                price
                priceType
                price2
                priceType2
                description
                deliveryType
            }
        }   
    }
}`

const GET_SUBCONTRACTOR_RENDERED_TEXTS = gql`
  query getSubcontractorRenderedMailText($id: ID!,$orderId: ID!) {
    getSubcontractorRenderedMailText(id: $id, orderId: $orderId) {
        mailSubject
        mailText
    }
}`

interface IProps {
    onClose: any,
    orderId: string,
    isOpen: boolean
}

function createUppy() {
    return new Uppy({
        locale: Danish,
        allowMultipleUploads: false,
        restrictions: {
            maxTotalFileSize: 8200 * 1024 * 1024, //8GB
            //maxNumberOfFiles: 1,
            //allowedFileTypes: ['.zip']
        }
    }).use(AwsS3, {
        endpoint: process.env.REACT_APP_EXTERNAL_UPLOAD_API_URL,
        shouldUseMultipart: true
    });
}

const MediaProcesserV4: React.FC<IProps> = ({ orderId, isOpen, onClose }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const contractorBoxColor = useColorModeValue("gray.200", "white");
    const bgPrevButton = useColorModeValue("gray.100", "gray.100");
    const [activeBullets, setActiveBullets] = useState({
        subcontractorTab: true,
        uploadTab: false,
        messageTab: false,
    });
    const [selectedSubContractor, setSelectedSubContractor] = useState(null);
    const [showNextButtonUpload, setShowNextButtonUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const subcontractorTab = useRef<HTMLButtonElement>();
    const uploadTab = useRef<HTMLButtonElement>();
    const messageTab = useRef<HTMLButtonElement>();
    const uploadStartTimeRef = useRef<number | null>(null);

    const [directDelivery, setDirectDelivery] = useState(null);

    if (directDelivery === null && selectedSubContractor) {
        if (selectedSubContractor.deliveryType === 'MANUALY_SELECTED' || selectedSubContractor.deliveryType === 'DIRECT') {
            setDirectDelivery(true);
        } else {
            setDirectDelivery(false);
        }
    }

    //upload
    const [uppy] = React.useState(createUppy)
    const { loading, error, data } = useQuery(GET_SUBCONTRACTORS,
        {
            variables: { id: orderId },
        }
    );
    const [createOrder] = useMutation(CREATE_SUBCONTRACTOR_ORDER);
    const [createLog] = useMutation(CREATE_LOG);
    const [getRenderedTexts, { data: dataRenderedTexts }] = useLazyQuery(GET_SUBCONTRACTOR_RENDERED_TEXTS, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const [totalUploadTime, setTotalUploadTime] = useState(null);
    const [uploadStartTime, setUploadStartTime] = useState(null);



    useEffect(() => {
        uppy.on('upload-start', (file) => {
            console.log('uppy - upload-start - file', file);
            setUploadStartTime(Date.now());
            uploadStartTimeRef.current = Date.now();
            //setTimeRemaining(null);
        });

        uppy.on('file-added', (file) => {

            setShowNextButtonUpload(true);
            Dashboard.setFileMeta(file.id, {
                filename: file.name,
                relativePath: file.meta.relativePath
            });


            // Perform actions here when a file is added
        });

        uppy.on('file-removed', () => {
            setShowNextButtonUpload(false);
            // Perform actions here when a file is removed
        });

        uppy.on('complete', (file) => {
            console.log('uppy - complete - file', file);
            const elapsedTime = (Date.now() - uploadStartTime) / 1000; // seconds
            setTotalUploadTime(elapsedTime); // seconds
            //setTimeRemaining(null); // Reset once upload completes
            setShowNextButtonUpload(false);
        });
    }, [uppy, uploadStartTime]);

    const setSubContractor = (subcontractorId) => {
        //find the subcontractor
        data.currentUser.photographyCompany.subcontractors.forEach((subcontractor) => {
            if (subcontractor.id === subcontractorId) {
                setSelectedSubContractor({ ...subcontractor });
                const input = {
                    id: subcontractorId,
                    orderId: orderId
                }
                getRenderedTexts({ variables: input }).then((data) => {
                    let tmp = { ...subcontractor };
                    tmp.mailText = data.data.getSubcontractorRenderedMailText.mailText;
                    tmp.mailSubject = data.data.getSubcontractorRenderedMailText.mailSubject;
                    setSelectedSubContractor(tmp);
                })
            }
        });
    }
    const updateData = (field: string, data: any) => {
        selectedSubContractor[field] = data;
        setSelectedSubContractor({ ...selectedSubContractor });
    };

    const updateText = (text) => {
        if (text !== "") {
            selectedSubContractor.mailText = text;
            setSelectedSubContractor({ ...selectedSubContractor });
        }
    };

    const createSubContractorOrder = async (mediaRef: string, files: any[]) => {

        let inputData = {} as any;
        inputData.orderId = orderId;
        inputData.subcontractorId = selectedSubContractor.id;
        inputData.mailSubject = selectedSubContractor.mailSubject;
        inputData.mailText = selectedSubContractor.mailText;
        inputData.subcontractorMediaRef = mediaRef;
        inputData.deliveryType = directDelivery ? 'DIRECT' : 'NORMAL';

        //media data
        inputData.subcontractorMedia = files;

        const createOrderDataId = await createOrder({ variables: { input: inputData } }).then((result) => {
            toast({
                title: "Ordren er oprettet.",
                description: "",
                status: "success",
                duration: 2000,
                isClosable: true,
            })

            onClose();

            return result.data.createSubcontractorOrder.id
        }).catch(() => {
            toast({
                title: "Ordren kunne ikke oprettes.",
                description: "Der opstod en fejl i oprettelsen af ordren.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });

            return null;
        });

        return createOrderDataId;
    }

    if (loading) return <p></p>; //loading

    if (error) {
        return <p>Error getting subcontractors from server (1)</p>;
    }

    if (!data) {
        return <p>Error getting subcontractors from server (2)</p>;
    }

    const MediaProcessers = data.currentUser.photographyCompany.subcontractors.filter((subcontractor: any) => subcontractor.subcontractorType === 'MEDIA_PROCESSOR');

    const translatePriceType = (priceType) => {
        switch (priceType) {
            case 'PRICE_PER_FLOORPLAN':
                return 'plantegning';
            case 'PRICE_PER_IMAGE':
                return 'billede';
            case 'PRICE_PER_ORDER':
                return 'ordre';
            case 'NONE':
                return '';
            default:
                return '';
        }
    }

    const sentMaterial = () => {

        //move uppy dialog to the #UploadStatus div
        const uppyInner = document.querySelector('.uppy-Dashboard-inner') as HTMLElement;
        uppyInner.style.height = '465px';
        const uppyDialog = document.querySelector('.uppy-Dashboard');
        const uploadStatus = document.getElementById('UploadStatus');
        uploadStatus.appendChild(uppyDialog);

        //Start the upload
        uppy.upload().then(async (result) => {
            console.log('successful uploads:', result);
            uppyDialog.remove();
            if (result.successful.length > 0) {

                console.log('successful uploads:', result);

                //add file info to array
                let files = [];
                for (let i = 0; i < result.successful.length; i++) {
                    files.push({
                        key: result.successful[i].s3Multipart.key,
                        name: result.successful[i].name,
                        //name: result.successful[i].meta.relativePath, //folder structure
                        type: result.successful[i].type,
                        size: Math.round(result.successful[i].size / 1024)
                    });
                }

                const subcontractorOrderId = await createSubContractorOrder(result.uploadID, files)
                const elapsedTime = (Date.now() - uploadStartTimeRef.current) / 1000; // seconds

                createLog({
                    variables: {
                        input: {
                            orderId: orderId,
                            subcontractorOrderId: subcontractorOrderId,
                            logType: 'INFO',
                            activity: 'Media ordre sendt til leverandør',
                            info: "Upload id: " + result.uploadID + " - Upload time: " + Math.floor(elapsedTime) + " seconds, upload size in MB: " + Math.round(result.successful[0].size / 1024 / 1024),
                        }
                    }
                });

            }
            if (result.failed.length > 0) {
                createLog({
                    variables: {
                        input: {
                            orderId: orderId,
                            logType: 'ERROR',
                            activity: 'Media ordre kunne ikke sendes til leverandør',
                            info: "Upload id: " + result.uploadID + " - Upload time: " + totalUploadTime + " seconds, upload size in MB: " + Math.round(result.successful[0].size / 1024 / 1024),
                        }
                    }
                });
            }
        }).catch((error) => {
            console.log('error', error);
        }
        );
        setIsUploading(true);
    }

    return (
        <Modal onClose={onClose} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxH="800px" maxW="1000px" bg={'#f9f9f9'}>
                <ModalHeader>Send materiale til redigering<br />
                    <div style={{ fontSize: '14px', color: 'grey' }}>{data.order.caseNo} - {data.order.estateAddress}, {data.order.estateZipCode} {data.order.estateCity}</div>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex
                            direction="column"
                            minH="100vh"
                            align="center"
                            pt={{ sm: "0px", md: "0px" }}
                        >
                            <Tabs variant="unstyled" mt="24px" width={'100%'}>
                                <TabList display="flex" justifyContent="center">
                                    <Tab ref={subcontractorTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: false,
                                                messageTab: false,
                                            })
                                        }
                                    >
                                        <Flex
                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: "80px", md: "200px" },
                                                height: "3px",
                                                bg: activeBullets.uploadTab ? textColor : "gray.200",
                                                left: { sm: "12px", md: "32px" },
                                                top: { sm: activeBullets.subcontractorTab ? "6px" : "4px", md: null },
                                                position: "absolute",
                                                bottom: activeBullets.subcontractorTab ? "40px" : "38px",
                                                zIndex: -1,
                                                transition: "all .3s ease",
                                            }}
                                        >
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.subcontractorTab ? textColor : "gray.300"}
                                                w={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                h={activeBullets.subcontractorTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.subcontractorTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.subcontractorTab ? "bold" : "normal"}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Leverandør
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        ref={uploadTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: true,
                                                messageTab: false,
                                            })
                                        }
                                    >
                                        <Flex

                                            direction="column"
                                            justify="center"
                                            align="center"
                                            position="relative"
                                            _before={{
                                                content: "''",
                                                width: { sm: "80px", md: "200px" },
                                                height: "3px",
                                                bg: activeBullets.messageTab ? textColor : "gray.200",
                                                left: { sm: "12px", md: "60px" },
                                                top: { sm: activeBullets.uploadTab ? "6px" : "4px", md: null },
                                                position: "absolute",
                                                bottom: activeBullets.uploadTab ? "40px" : "38px",
                                                zIndex: -1,
                                                transition: "all .3s ease",
                                            }}
                                        >
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.uploadTab ? textColor : "gray.300"}
                                                w={activeBullets.uploadTab ? "16px" : "12px"}
                                                h={activeBullets.uploadTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.uploadTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.uploadTab ? "bold" : "normal"}
                                                transition="all .3s ease"
                                                _hover={{ color: textColor }}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Upload materiale
                                            </Text>
                                        </Flex>
                                    </Tab>
                                    <Tab
                                        ref={messageTab}
                                        _focus={{ outline: "none" }}
                                        w={{ sm: "80px", md: "200px" }}
                                        onClick={() =>
                                            setActiveBullets({
                                                subcontractorTab: true,
                                                uploadTab: true,
                                                messageTab: true,
                                            })
                                        }
                                    >
                                        <Flex direction="column" justify="center" align="center">
                                            <Icon
                                                as={BsCircleFill}
                                                color={activeBullets.messageTab ? textColor : "gray.300"}
                                                w={activeBullets.messageTab ? "16px" : "12px"}
                                                h={activeBullets.messageTab ? "16px" : "12px"}
                                                mb="8px"
                                            />
                                            <Text
                                                color={activeBullets.messageTab ? { textColor } : "gray.300"}
                                                fontWeight={activeBullets.messageTab ? "bold" : "normal"}
                                                transition="all .3s ease"
                                                _hover={{ color: textColor }}
                                                display={{ sm: "none", md: "block" }}
                                            >
                                                Send besked
                                            </Text>
                                        </Flex>
                                    </Tab>
                                </TabList>
                                <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="40px">
                                                <Flex direction="column">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >
                                                        Leverandør
                                                    </Text>
                                                    <Text color="gray.400" fontWeight="normal" fontSize="sm">
                                                        Vælg en underleverandør
                                                    </Text>
                                                </Flex>
                                            </CardHeader>
                                            <CardBody>
                                                <Flex direction="column" w="100%">
                                                    <Grid
                                                        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                                                        templateRows={{ md: "repeat(1, 1fr)" }}
                                                        gap="10px"
                                                    >
                                                        {MediaProcessers.length === 0 ? (

                                                            <Text fontSize="md" color={textColor} textAlign="center">
                                                                <i>Der er ikke oprettet nogen leverandører</i>
                                                            </Text>
                                                        ) : (<>
                                                            {MediaProcessers.map((subcontractor: any) => {
                                                                if (selectedSubContractor && selectedSubContractor.id === subcontractor.id) {

                                                                    return (
                                                                        <Card border={'2px solid'} borderColor={contractorBoxColor} bg={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer">
                                                                            {subcontractor.isGlobal ? (
                                                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                        - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                    </Text>
                                                                                    {subcontractor.price2 > 0 && (
                                                                                        <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                            - {subcontractor.price2} kr. pr. {translatePriceType(subcontractor.priceType2)}
                                                                                        </Text>
                                                                                    )}
                                                                                    {subcontractor.description.length > 5 && (
                                                                                        <Tooltip hasArrow label={subcontractor.description} aria-label='A tooltip' placement='bottom'>
                                                                                            <span>
                                                                                                <FaInfoCircle style={{ color: '#000' }} />
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                    {subcontractor.name}
                                                                                </Text>

                                                                            )
                                                                            }
                                                                        </Card>
                                                                    )
                                                                } else {

                                                                    return (
                                                                        <Card border={'2px solid'} borderColor={contractorBoxColor} key={subcontractor.id} width={'100%'} color="white" p="10px" borderRadius="5px" cursor="pointer" onClick={() => { setSubContractor(subcontractor.id); uploadTab.current.click() }}>
                                                                            {subcontractor.isGlobal ? (
                                                                                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={textColor} >
                                                                                        {subcontractor.name}
                                                                                    </Text>
                                                                                    <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                        - {subcontractor.price} kr. pr. {translatePriceType(subcontractor.priceType)}
                                                                                    </Text>
                                                                                    {subcontractor.price2 > 0 && (
                                                                                        <Text fontSize="sm" fontWeight="bold" color={'#adadad'}>
                                                                                            - {subcontractor.price2} kr. pr. {translatePriceType(subcontractor.priceType2)}
                                                                                        </Text>
                                                                                    )}
                                                                                    {subcontractor.description.length > 5 && (
                                                                                        <Tooltip hasArrow label={subcontractor.description} aria-label='A tooltip' placement='bottom'>
                                                                                            <span>
                                                                                                <FaInfoCircle style={{ color: '#000' }} />
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Text fontSize="sm" fontWeight="bold" color={textColor}>
                                                                                    {subcontractor.name}
                                                                                </Text>
                                                                            )
                                                                            }
                                                                        </Card>
                                                                    )
                                                                }
                                                            })}
                                                        </>
                                                        )}

                                                    </Grid>
                                                    {selectedSubContractor !== null && (
                                                        <Button
                                                            variant="no-hover"
                                                            bg="#151928"
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (uploadTab.current) { uploadTab.current.click() } }}
                                                        >
                                                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                Næste
                                                            </Text>
                                                        </Button>
                                                    )}
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel>
                                        <Card>
                                            <CardHeader mb="20px">
                                                <Text
                                                    color={textColor}
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    mb="3px"
                                                >
                                                    Upload materiale

                                                </Text>
                                            </CardHeader>
                                            <CardBody>
                                                <Flex direction="column" w="100%">
                                                    <div>
                                                        <Dashboard uppy={uppy} height={'365px'} width={'100%'}
                                                            proudlyDisplayPoweredByUppy={false}
                                                            hideCancelButton={true}
                                                            showProgressDetails={true}
                                                            hideUploadButton={true}
                                                            hideRetryButton={true}
                                                            hidePauseResumeButton={true}
                                                            hideProgressAfterFinish={false}
                                                            //fileManagerSelectionType={'both'} 
                                                            allowMultipleUploads={false}
                                                            inline={true}
                                                        />

                                                        {selectedSubContractor && (selectedSubContractor.deliveryType === 'MANUALY_SELECTED' || selectedSubContractor.deliveryType === 'MANUALY_NOT_SELECTED')  && (
                                                        <>
                                                            <br/>
                                                            <Checkbox  isChecked={directDelivery} onChange={(e) => setDirectDelivery(e.target.checked)}>
                                                                Levere det færdige materiale direkte til kundes system.
                                                            </Checkbox>
                                                        </>
                                                        )}

                                                    </div>
                                                    <Flex justify="space-between">
                                                        <Button
                                                            variant="no-hover"
                                                            bg={bgPrevButton}
                                                            alignSelf="flex-end"
                                                            mt="24px"
                                                            w="100px"
                                                            h="35px"
                                                            onClick={() => { if (subcontractorTab.current) { subcontractorTab.current.click() } }}

                                                        >
                                                            <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                Tilbage
                                                            </Text>
                                                        </Button>
                                                        {showNextButtonUpload && (
                                                            <Button
                                                                variant="no-hover"
                                                                bg="#151928"
                                                                alignSelf="flex-end"
                                                                mt="24px"
                                                                w="100px"
                                                                h="35px"
                                                                onClick={() => { if (messageTab.current) { messageTab.current.click() } }}
                                                            >
                                                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                    Næste
                                                                </Text>
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel>

                                        <Card>
                                            {!isUploading && (
                                                <CardHeader mb="40px">
                                                    <Text
                                                        color={textColor}
                                                        fontSize="lg"
                                                        fontWeight="bold"
                                                        mb="3px"
                                                    >Send besked</Text>
                                                </CardHeader>
                                            )}
                                            <CardBody style={{ flexDirection: 'column' }}>
                                                <Flex style={{ flexDirection: 'column' }} direction="column" w="100%">
                                                    {isUploading ? (
                                                        <>
                                                            <Text fontSize="md" textAlign={"center"} color={textColor} whiteSpace={"nowrap"} width={'100%'}>
                                                                Uploader og sender ordren.
                                                            </Text>
                                                            <br />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Flex align="center" mb="18px">
                                                                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                    Emne:{" "}
                                                                </Text>
                                                                <Input
                                                                    variant="flushed"
                                                                    type="text"
                                                                    name="mailSubject"
                                                                    defaultValue={(selectedSubContractor !== null ? selectedSubContractor.mailSubject : "")}
                                                                    onBlur={(e) =>
                                                                        updateData("mailSubject", e.target.value)
                                                                    }
                                                                />
                                                            </Flex>

                                                            {selectedSubContractor && !selectedSubContractor.isGlobal && (
                                                                <Flex align="center" mb="18px">
                                                                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                        Sendes til:{" "}
                                                                    </Text>

                                                                    {selectedSubContractor && (
                                                                        <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"100px"}>
                                                                            {selectedSubContractor.bookingEmail}
                                                                        </Text>
                                                                    )}
                                                                </Flex>
                                                            )}

                                                            <Flex align="center" mb="18px" className="mediaProcesserEditorContainer">
                                                                {dataRenderedTexts && (
                                                                    <Editor value={(dataRenderedTexts !== null ? dataRenderedTexts.getSubcontractorRenderedMailText.mailText : "")} changed={(e) => (updateText(e))} showFields={false} />
                                                                )}
                                                            </Flex>


                                                            <Flex justify="space-between">
                                                                <Button
                                                                    variant="no-hover"
                                                                    bg={bgPrevButton}
                                                                    alignSelf="flex-end"
                                                                    mt="24px"
                                                                    w="100px"
                                                                    h="35px"
                                                                    onClick={() => { if (uploadTab.current) { uploadTab.current.click() } }}
                                                                >
                                                                    <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                                        Tilbage
                                                                    </Text>
                                                                </Button>
                                                                <Button
                                                                    variant="no-hover"
                                                                    bg="#151928"
                                                                    alignSelf="flex-end"
                                                                    mt="24px"
                                                                    w="100px"
                                                                    h="35px"
                                                                    onClick={() => { sentMaterial() }}
                                                                >
                                                                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                                        Send
                                                                    </Text>
                                                                </Button>
                                                            </Flex>
                                                        </>
                                                    )}
                                                </Flex>
                                                <div id="UploadStatus"></div>
                                            </CardBody>
                                        </Card>
                                    </TabPanel>

                                </TabPanels>
                            </Tabs>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent >
        </Modal >
    );
}

export default MediaProcesserV4;