import { useQuery, gql } from "@apollo/client";
import { DocumentIcon } from "./../../../../components/Icons/Icons";
import { FaArrowRight } from "react-icons/fa";
import { Flex, Icon, useColorModeValue, Stack, Text } from "@chakra-ui/react";
import Card from "./../../../../components/Card/Card.js";
import CardBody from "./../../../../components/Card/CardBody.js";
import CardHeader from "./../../../../components/Card/CardHeader.js";
import IconBox from "./../../../../components/Icons/IconBox";
import React, { useState } from "react";
import { useAuth } from "./../../../../auth-context/auth.context";

const GET_DATA = gql`
  query GET_Data($input: GetOrdersByUserInput!) {
    getOrdersByUser(input: $input){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
      createdAt
      assignedUser {
        id
        name
      }
    }
  }
`;

const NewOrders = () => {
  console.log('NewOrders.js');
  const textColor = useColorModeValue("gray.700", "white");
  const iconTeal = useColorModeValue("gray.400", "gray.400");
  const userData = useAuth()

  const preSetShowAll = () => {

    let showAll = true;
    if (localStorage.getItem("dashbord_new_orders_showAll") !== null) {
      showAll = localStorage.getItem("dashbord_new_orders_showAll") === 'yes' ? true : false;
    }
    if (!['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) {
      showAll = true
    }

    return showAll;
  }

  const [showAll, setShowAll] = useState(preSetShowAll());
  const { loading, data } = useQuery(GET_DATA, {
    variables: {
      input: {
        userId: localStorage.getItem("system_user_id"),
        status: ['RECEIVED']
      }
    },
    manual: true,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  if (loading) return (
    <Card w={{ sm: "100%" }} h="375px" >
      <CardHeader pt="6px" mb="8px" style={{ justifyContent: 'space-between' }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Nye Ordrer
        </Text>
      </CardHeader>
      <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>

      </CardBody>
    </Card>
  );

  const filterClicked = () => {
    localStorage.setItem("dashbord_new_orders_showAll", !showAll ? 'yes' : 'no');
    setShowAll(!showAll);
  }


  let newOrders = data.getOrdersByUser.map(order => {
    let keyCase = '';
    if (order.keyCase === "COORDINATE_WITH_CONTACTPERSON" || order.keyCase === "COORDINATE_WITH_ESTATEAGENT") {
      keyCase = ' 🔑';
    }

    return {
      id: order.id,
      address: order.estateAddress + ", " + order.estateZipCode + ' ' + order.estateCity,
      caseNo: order.caseNo + keyCase,
      addressLink: '/#/admin/order/' + order.id + '/planning',
      assignedUser: order.assignedUser ? order.assignedUser.name : null
    }
  });

  if (showAll === false) {
    // Filter out orders that are assigned to a user
    newOrders = newOrders.filter(order => order.assignedUser === null);
  }



  return (
    <Card w={{ sm: "100%" }} h="375px">
      <CardHeader pt="6px" mb="8px" style={{ justifyContent: 'space-between' }}>

        {showAll ? (
          <Flex direction="column">
            {newOrders.length > 5 ? (
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Nye Ordrer ({newOrders.length})
              </Text>
            ) : (
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Nye Ordrer
              </Text>
            )}
          </Flex>
        ) : (
          <Flex direction="column">
            {newOrders.length > 5 ? (
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Nye ikke tildelte Ordrer ({newOrders.length})
              </Text>
            ) : (
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Nye ikke tildelte Ordrer
              </Text>
            )}
          </Flex>
        )}

        {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (
          <Flex>
            <Text fontSize="sm" color="gray.400" style={{ cursor: 'pointer' }} onClick={filterClicked}>
              {!showAll ? <><Icon as={FaArrowRight} me="6px" style={{ position: "relative", top: "1px" }} /> Vis alle ordrer</> :
                <><Icon as={FaArrowRight} me="6px" style={{ position: "relative", top: "1px" }} /> Vis kun ikke tildelte ordrer</>
              }
            </Text>
          </Flex>
        )}
      </CardHeader>
      <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
        <Stack direction="column" spacing="20px">
          {newOrders.map((item, cid) => (
            <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`newOrder-${cid}`} className='draggable-calendar-event'>
              <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                  <Icon as={DocumentIcon} h={"22px"} w={"22px"} color={iconTeal} />
                </IconBox>
                <Flex direction="column">
                  <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {item.caseNo}
                  </Text>
                  <Text color="gray.400" fontSize="sm" fontWeight="normal">
                    {item.address}
                  </Text>
                </Flex>
              </a>
            </Flex>

          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default NewOrders;
