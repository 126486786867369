// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Select } from "@chakra-ui/react";
// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth } from "../../../../auth-context/auth.context";

const updateCustomerInfoGql = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const GET_USERS = gql`
  query GET_USERS {
  users {
    id
    name
    userType
    calenderColor
  }
}`

const CustomerMainPhotografer = ({ data }) => {
  const { loading, error, data: usersData } = useQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const userData = useAuth()
  const toast = useToast();
  const [updateCustomerInfo] = useMutation(updateCustomerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  if (loading) {
    return <p></p>; //loading
  }
  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }
  if (!usersData) {
    return <p>Error getting stats from server (2)</p>;
  }

  const updateData = (customerId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = customerId;
    updateCustomerInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  console.log('UserData:', usersData)

  /*
  const filterUsers = () => {
    if (userData.user.userType === 'PHOTOGRAPHER') {
      return data.users.filter((user) => user.id === userData.user.id);
    }

    if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

      return data.users.filter((user) => user.userType !== 'ADMIN');
    }

    if (userData.user.userType === 'ADMIN') {
      return data.users;
    }
  }
    */

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Automatisk tildeling af ordrer
        </Text>
      </CardHeader>
      <CardBody px="5px" height={"100%"}>
        <Flex direction="column" style={{ width: "100%", height: "100%" }}>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Fotograf:{" "}
            </Text>
            <Select multiple={false} disabled={userData.user.userType === 'PHOTOGRAPHER'} onChange={(e) =>
              updateData(data.id, "mainPhotographerUserId", e.target.value)
            }>
              {data.mainPhotographerUser === null ? (
                <option key={'manual'} selected value={'manual'}>
                  Ingen
                </option>
              ) : (
                <option key={'manual'} value={'manual'}>
                  Ingen
                </option>
              )}
              {
                usersData.users.map((user) => {
                  if (data.mainPhotographerUser && user.id === data.mainPhotographerUser.id) {
                    return (
                      <option key={user.id} selected value={user.id}>
                        {user.name}
                      </option>
                    );
                  } else {
                    if (userData.user.userType !== 'ADMIN') {
                      if (user.userType !== 'ADMIN') {
                        return (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        );
                      } else {
                        return ""
                      }
                    }

                    if (userData.user.userType === 'ADMIN') {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      );
                    }
                  }
                })
              }

            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CustomerMainPhotografer;
