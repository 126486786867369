import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
//import React from "react";

interface RouteParams {
  photographerId: string;
  userId?: string;
}

const Calendar = () => {
  const { photographerId, userId } = useParams<RouteParams>();
  //const { photographerId, userId } = useParams<Record<string, string | undefined>>();
  let calendarUrl = process.env.REACT_APP_EXTERNAL_API_URL + "/calender/" + photographerId;

  if (userId) {
    calendarUrl = calendarUrl + "/" + userId
  }

  const copyToClipBoard = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(calendarUrl);

    // Alert the copied text
    alert("Linket er blevet kopieret til udklipsholderen");
  }

  return (
    <>

      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Box
          position="absolute"
          
          w={{ sm: "calc(100vw)", md: "calc(100vw - 50px)" }}
          borderRadius={"15px"}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          bgColor={"gray.600"}
          bgSize="cover"
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}
          pb={{ sm: "54px" }}
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mt="6.5rem"
            mb="30px"
            pl="10px"
            pr="10px"
          >
            <div>
              <Text fontSize="26px" color="white" fontWeight="bold">
                Tilføj fotograf kalender. <br /><br />
              </Text>

              <Text fontSize="14px" color="white" >
                For at tilføje fotograf kalenderen, skal du kopiere følgende link:<br /><br />
                <div onClick={copyToClipBoard} style={{ cursor: 'pointer', fontWeight:'bold' }}>{calendarUrl}</div>
              </Text>
              <Text fontSize="16px" color="white" fontWeight={'bold'} margin={'10px'} marginTop={'30px'}>
                Apple - Iphone
              </Text>
              <Text fontSize="14px" color="white" textAlign="left" >
                <ol style={{padding:'15px'}}>
                  <li>Åben din kalender app, klik "Kalendere" og "tilføj kalender"</li>
                  <li>Vælg "Tilføj abbonementskalender" og indsæt linket ovenfor.</li>
                </ol>
           
                <i>Iphone kalenderen opdateres automatisk, ca. 1 gang i timen.<br/>Opdateringsintervallet styres af kalenderen og kan ikke ændres af fotosystemet.</i>
              </Text>

              <Text fontSize="16px" color="white" fontWeight={'bold'} margin={'10px'} marginTop={'30px'}>
                Google - Android
              </Text>
              <Text fontSize="14px" color="white" textAlign="left">
              <ol style={{padding:'15px'}}>
                <li>Åben din google kalender på en computer ved at gå til denne url: <a href="https://calendar.google.com" target="_blank" rel="noreferrer">https://calendar.google.com</a></li>
                <li>Klik på "Andre kalendere" og "Tilføj via URL"</li>
                <li>Indsæt linket ovenfor og klik "Tilføj kalender"</li>
                <li>Åben din google kalender på din telefon og klik på de 3 prikker øverst til højre</li>
                <li>Klik på "Indstillinger" og "Synkroniser" og vælg din nye kalender</li>
              </ol>
   
              <i>Google kalenderen opdateres automatisk, ca. 1 gang hver 12 time.<br/>Opdateringsintervallet styres af kalenderen og kan ikke ændres af fotosystemet.</i>
              </Text>
                
              
            </div>
          </Flex>
        </Box>
      </Flex>
    </>

  );
}

export default Calendar;
