// Chakra imports
import {
    Flex, useColorModeValue, Text, Grid, GridItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter

} from "@chakra-ui/react";
import { useAuth } from "./../../../auth-context/auth.context";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import React from "react";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import CustomersHeader from "./components/customersHeader"
import SettleTable from "../../../components/Tables/SettleTable";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";

const CUSTOMERS_GQL = gql` 
query getCustomersByUser{
    getCustomersByUser{
        id
        name
        address
        zipCode
        city
        cvr
        email
        phoneNo
        createdAt
    }
}`

const CREATE_CUSTOMER_GQL = gql`
  mutation createCustomer($input: CreateCustomerInput!){
    createCustomer(input:$input){
        id
    }
  }`

const DELETE_CUSTOMER_GQL = gql`
  mutation deleteCustomer($id: ID!){
    deleteCustomer(id:$id)
}`



const CreateCustomerObj = {
    name: '',
    address: '',
    zipCode: '',
    city: '',
    email: ''
}


function Customers() {
    const userData = useAuth()
    const toast = useToast()
    const [validate, setValidate] = React.useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState('');
    const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const { isOpen: createCustomerIsOpen, onOpen: createCustomerOnOpen, onClose: createCustomerOnClose } = useDisclosure();
    const history = useHistory();

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [deleteCustomer] = useMutation(
        DELETE_CUSTOMER_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL],
            variables: {
                userId: localStorage.getItem("system_user_id"),
            },
        }
    )

    const [createNewCustomer] = useMutation(
        CREATE_CUSTOMER_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL],
            variables: {
                userId: localStorage.getItem("system_user_id"),
            },
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        //variables: {
        //    userId: localStorage.getItem("system_user_id"),
        //},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (
        <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
            <CustomersHeader
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Kunder"}
                description={'Opret, rediger og slet dine kunder i systemet'}
            />
            <Grid templateColumns={{ sm: "1fr", xl: "repeat(1, 1fr)" }} gap="22px">
                <GridItem w='100%'  >

                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting orders from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting orders from server (2)</p>);
    }

    const deleteCustomerClicked = (obj) => {
        //alert('delete' + obj.id)
        setSelectedCustomerId(obj.id)
        deleteWarningOnOpen();

    }

    const deleteCustomerConfirmed = () => {
        deleteCustomer({ variables: { id: selectedCustomerId } } as any);
        deleteWarningOnClose();
    }

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "Kunde navn",
            accessor: "name",
            onClick: rowClicked
        },
        {
            header: "Adresse",
            accessor: "fullAddress",
            onClick: rowClicked
        },
        {
            header: "Email",
            accessor: "email",
            onClick: rowClicked
        },
        {
            header: "Oprettet",
            accessor: "createdAt",
            onClick: rowClicked
        },
        
    ] as any;

    if (['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) {

        columns.push({
            header: "Slet",
            icon: "delete",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "delete",
            onClick: deleteCustomerClicked,
        });
    }


    function rowClicked(obj) {
        //history.push('/admin/customer/' + obj.id + '/info');
        history.push('/admin/setting/customer/' + obj.id );
    }



    const createCustomer = () => {
        if (CreateCustomerObj.address === '' || CreateCustomerObj.city === '' || CreateCustomerObj.email === '' || CreateCustomerObj.name === '' || CreateCustomerObj.zipCode === '') {
            setValidate(true)
        } else {
            createNewCustomer({
                variables: { input: CreateCustomerObj } as any
            }).then(() => createCustomerOnClose())
                .catch((() => {
                    toast({
                        title: 'Opret kunde fejlede.',
                        description: "Det var ikke muligt at oprette kunden.",
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    });

                })
                );
        }
    }

    const showCreateCustomerDialog = () => {
        createCustomerOnOpen()
    }

    const updateDate = (field, value) => {
        CreateCustomerObj[field] = value;
    }

    //create obj for table component
    //console.log('data:', data)
    let tableData = JSON.parse(JSON.stringify(data.getCustomersByUser));

    for (var i = 0; i < tableData.length; i++) {
        tableData[i].createdAt = moment(tableData[i].createdAt).format('DD-MM-YYYY');
        tableData[i].fullAddress = tableData[i].address + ' - ' + tableData[i].zipCode + ' ' + tableData[i].city;
    }

    return (
        <div>
            <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
                <CustomersHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Kunder"}
                    description={'Opret, rediger og slet dine kunder i systemet'}
                />
                <br />
                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">

                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold">
                                Brugere
                            </Text>
                        </Flex>
                        {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize="12px"
                                fontWeight="bold"
                                mb="6px"
                                cursor="pointer"
                                onClick={() => {
                                    showCreateCustomerDialog();
                                }}
                            >
                                Opret ny kunde
                            </Text>
                        </Flex>
                        )}
                    </CardHeader>
                    <CardBody>
                        <SettleTable data={tableData} columnsData={columns} setDragData={null} csvData={null} enableSelect={false} />
                    </CardBody>
                </Card>
            </Flex>


            <Modal onClose={createCustomerOnClose} size="xl" isOpen={createCustomerIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret ny kunde</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprettet en ny kunde, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Kunde:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Navn:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="name"
                                    onChange={(e) => { updateDate('name', e.currentTarget.value) }}
                                    className={validate && CreateCustomerObj.name === '' ? 'error' : 'ingenFejl'}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    E-mail:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="email"
                                    onChange={(e) => { updateDate('email', e.currentTarget.value) }}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Adresse:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name="address"
                                    onChange={(e) => { updateDate('address', e.currentTarget.value) }}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Post nr.:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name='zipCode'
                                    onChange={(e) => { updateDate('zipCode', e.currentTarget.value) }}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    By:{" "}
                                </Text>
                                <Input
                                    variant='flushed'
                                    type='text'
                                    name='city'
                                    onChange={(e) => { updateDate('city', e.currentTarget.value) }}
                                />
                            </Flex>
                        </Flex>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createCustomerOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createCustomer} ml={3}>Opret</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>



            <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slet kunden
                        </AlertDialogHeader>

                        <AlertDialogBody>Er du sikker?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={deleteWarningOnClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={deleteCustomerConfirmed} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </div>


    );
}

export default Customers;
