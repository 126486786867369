// Chakra imports
import {
  Flex, ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import './messageViewImageSelector.css';

interface IProps {
  isVisible: boolean;
  orderId: string;
  onClose: any;
}

const GET_ORDER_MEDIA = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      getMessagesMedias {
        id
        markerOverLay
        media {
          id
          heightInPx
          widthInPx
          filename
          fileSizeInKB
          orientation
          thumpnailUrl
          orgUrl
          mimeType
          description
          mediaType
        }
      }
      medias {
        id
        heightInPx
        widthInPx
        filename
        fileSizeInKB
        orientation
        thumpnailUrl
        orgUrl
        mimeType
        description
        mediaType
      }
    }
  }
`;

const MessageViewImageSelector: React.FC<IProps> = ({ orderId, onClose, isVisible }) => {
  const [selectedMedias, setSelectedMedias] = React.useState([]);
  const { loading, error, data } = useQuery(
    GET_ORDER_MEDIA,
    {
      variables: { id: orderId },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }

  if (!isVisible) {
    return null;
  }

  const CloseMessageMediaSelector = () => {
    onClose([]);
  }

  const AddSelectedToMessage = () => {
    onClose([...selectedMedias]);
  }

  const isSelected = (mediaId) => {
    //check if media is in selected list
    const filtered = selectedMedias.filter((media) => {
      if (media.id === mediaId) {
        return true;
      }
    });

    if (filtered.length > 0) {
      return true;
    }
    return false;
  }

  const handleCheckBoxClicked = (media: any) => {
    //add media to selected list if not already there
    if (isSelected(media.id)) {
      const filtered = selectedMedias.filter((selectedMedia) => {
        if (selectedMedia.id !== media.id) {
          return true;
        }
      });
      setSelectedMedias(filtered);
    } else {
      setSelectedMedias([...selectedMedias, media]);
    }
  }

  return (
    <Modal onClose={CloseMessageMediaSelector} size="xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent maxW="880px">
        <ModalHeader>Vælg billeder</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant='enclosed'>
            <TabList mb='1em'>
              <Tab>Sags billeder</Tab>
              <Tab>Billeder fra andre beskeder</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex flexDirection={'row'} flexWrap={'wrap'} overflow={'auto'} maxHeight={'400px'}>
                  {data.getOrder.medias.map((media: any) => (
                    <div className="mediaItem" onClick={() => { handleCheckBoxClicked(media) }} style={{ cursor: 'pointer' }}>
                      <img src={media.thumpnailUrl} alt="placeholder" style={{ maxWidth: '180px' }} />
                      <div style={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '18px', paddingTop: '10px', paddingBottom: '10px' }}>
                        <div style={{ display: 'flex' }}>
                          <div><b>Navn:&nbsp;</b></div>
                          <div>{media.filename}</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div><b>Størrelse:&nbsp;</b></div>
                          <div>{media.heightInPx} x {media.widthInPx}</div>
                        </div>
                      </div>
                      <div className="SelectFileCheckboxContainer">
                        <input id={'checkbox_' + media.id} checked={isSelected(media.id)} type="checkbox" value={media.id} className={'SelectFileCheckbox_' + media.id} name="SelectFileCheckbox" onChange={() => { }} onClick={() => { handleCheckBoxClicked(media) }} />
                      </div>
                    </div>
                  ))}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex flexDirection={'row'} flexWrap={'wrap'} overflow={'auto'} maxHeight={'400px'}>
                  {data.getOrder.getMessagesMedias.map((messageMedia: any) => (
                    <div className="mediaItem" onClick={() => { handleCheckBoxClicked(messageMedia) }} style={{ cursor: 'pointer' }}>
                      <img src={messageMedia.media.thumpnailUrl} alt="placeholder" style={{ maxWidth: '180px' }} />
                      {messageMedia.markerOverLay && (
                        <div className="mediaOverlay mediaSelectorOverlay" dangerouslySetInnerHTML={{ __html: messageMedia.markerOverLay }}></div>
                      )}
                      <div style={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '18px', paddingTop: '10px', paddingBottom: '10px' }}>
                        <div style={{ display: 'flex' }}>
                          <div><b>Navn:&nbsp;</b></div>
                          <div>{messageMedia.media.filename}</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div><b>Størrelse:&nbsp;</b></div>
                          <div>{messageMedia.media.heightInPx} x {messageMedia.media.widthInPx}</div>
                        </div>
                      </div>
                      <div className="SelectFileCheckboxContainer">
                        <input id={'checkbox_' + messageMedia.media.id} checked={isSelected(messageMedia.id)} type="checkbox" value={messageMedia.id} className={'SelectFileCheckbox_' + messageMedia.id} name="SelectFileCheckbox" onChange={() => { }} onClick={() => { handleCheckBoxClicked(messageMedia) }} />
                      </div>
                    </div>
                  ))}
                </Flex>
              </TabPanel>
            </TabPanels>



          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button onClick={CloseMessageMediaSelector}>Annuller</Button>
          <Button colorScheme='green' onClick={AddSelectedToMessage} ml={3}>Tilføj til besked</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MessageViewImageSelector;
