// Chakra imports
import {
    Flex,
    useColorModeValue,
    useToast,
    Tooltip,
    Button,
    Text,
    Select,
    Image,
    Grid,
    GridItem
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import Header from "../../../../../components/Headers/Header";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { useQuery, useMutation, gql } from "@apollo/client";
import eConomic_logo from "./../../../../../assets/img/eConomic_logo.png";
import './../integrations.css';
import { FaInfoCircle } from "react-icons/fa";
import React from "react";

const CUSTOMERS_GQL = gql`
query customers {
    getCustomers {
        id
        name
        eConomicCustomerId
    }
    getProducts {
        id
        name
        extraService
        eConomicProductId
    }   
    getPhotographyCompany {
        id
        integration(integrationType: ECONOMIC) {
            id
            organizationId
            accessLink
            haveAccess
            customSettings1 {
                id
                name
            }
            customData1 {
                id
                name
            }
            customSetting1Value

            organizations {
                id
                name
            }
            customers {
                id
                name
            }
        }
    }
}`

const UPDATE_PRODUCT_GQL = gql`
mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input:$input){
        id
    }
}`

const UPDATE_CUSTOMER_GQL = gql`
mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input:$input){
        id
    }
}`

const UPDATE_INTEGRATION_GQL = gql`
mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input:$input){
        id
    }
}`

function Economic() {
    const toast = useToast()
    const invoiceTemplateRef = React.createRef<HTMLSelectElement>();

    //Colors
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [updateCustomer] = useMutation(
        UPDATE_CUSTOMER_GQL,
        {
            //refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const [updateProduct] = useMutation(
        UPDATE_PRODUCT_GQL,
        {
            //refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const [updateIntegration] = useMutation(
        UPDATE_INTEGRATION_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const handleProductChange = (e, productId) => {
        const value = e.target.value;
        updateProduct({
            variables: {
                input: {
                    id: productId,
                    eConomicProductId: value
                }
            }
        })
    }

    const handleInvoiceTemplateSelected = () => {
        const customSetting1value = invoiceTemplateRef.current.value;

        updateIntegration({
            variables: {
                input: {
                    id: data.getPhotographyCompany.integration.id,
                    customSetting1: customSetting1value
                }
            }
        });
    }

    const handleCustomerChange = (e, customerId) => {
        const value = e.target.value;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    eConomicCustomerId: value
                }
            }
        }).then(() => {
            toast({
                title: "Kunden er opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af Kunden.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    return (
        <div className="EconomicSettings">
            <Flex direction="column">
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Indstillinger - e-conomic"}
                    description={'Opsætning af e-conomic integration'}
                    showBackButton={true}
                />

                <Card p="16px" my={{ sm: "0px", xl: "24px" }}>
                    <CardHeader p="12px 5px" justifyContent="space-between" flexDirection="column" >
                        <Flex justifyContent="center" align="center" style={{ width: "100%" }}>
                            <Image
                                src={eConomic_logo}
                                alt='e-conomic logo'
                                maxWidth={'270px'}
                            />
                        </Flex>
                        {data.getPhotographyCompany.integration.haveAccess && data.getPhotographyCompany.integration.customSetting1Value !== "" && (
                            <Flex flexDirection="column" style={{ width: "100%" }} pt={'20px'}>
                                <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">
                                    <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                        Du er nu forbundet med e-conomic!
                                    </Text>
                                    <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                        Nedenfor skal du matche dine kunder og produkter i e-conomic med dine kunder og produkter i Magna Multi.
                                    </Text>
                                </Flex>
                            </Flex>

                        )}
                    </CardHeader>
                </Card>

                {!data.getPhotographyCompany.integration.haveAccess && (
                    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>

                        <CardBody width={'100%'}>
                            <Flex direction="column" style={{ width: "100%" }}>
                                <Flex align="center" mb="28px" mt="28px" style={{ width: "100%" }}>
                                    <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                        For at kunne bruge e-conomic, skal du forbinde til e-conomic.<br />Klik på linket nedenfor for at forbinde.
                                    </Text>
                                </Flex>
                                <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                    <Button mt="10px" onClick={() => window.location = data.getPhotographyCompany.integration.accessLink}>Forbind til e-conomic</Button>
                                </Flex>
                            </Flex>
                        </CardBody>
                    </Card>
                )}

                {(data.getPhotographyCompany.integration.haveAccess &&
                    data.getPhotographyCompany.integration.customSetting1Value === "" &&
                    !data.getPhotographyCompany.integration.customSettings1) && (
                        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>

                            <CardBody width={'100%'}>
                                <Flex direction="column" style={{ width: "100%" }}>
                                    <Flex align="center" mb="28px" mt="28px" style={{ width: "100%" }}>
                                        <Text fontSize="md" color={'red.500'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Der er opstået en fejl, prøv at forbinde til e-conomic igen.
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <Button mt="10px" onClick={() => window.location = data.getPhotographyCompany.integration.accessLink}>Forbind til e-conomic</Button>
                                    </Flex>
                                </Flex>
                            </CardBody>
                        </Card>
                    )}

                {(data.getPhotographyCompany.integration.haveAccess &&
                    data.getPhotographyCompany.integration.customSetting1Value === "" &&
                    data.getPhotographyCompany.integration.customSettings1) && (
                        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                            <CardBody width={'100%'}>
                                <Flex direction="column" style={{ width: "100%" }}>
                                    <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">
                                        <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Du er nu forbundet med e-conomic, vælg det faktura layout du vil bruge i e-conomic.
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <span style={{ width: "400px" }}>
                                            <Select ref={invoiceTemplateRef} >
                                                {data.getPhotographyCompany.integration.customSettings1.map((invoiceTemplate) => (
                                                    <option key={invoiceTemplate.id} value={invoiceTemplate.id}>{invoiceTemplate.name}</option>
                                                ))}
                                            </Select>
                                        </span>
                                    </Flex>
                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <Button width={"200px"} mt="10px" onClick={handleInvoiceTemplateSelected} >OK</Button>
                                    </Flex>
                                </Flex>
                            </CardBody>
                        </Card>
                    )}

                {data.getPhotographyCompany.integration.haveAccess && data.getPhotographyCompany.integration.customSetting1Value !== "" && (
                    <>
                        <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(1fr)" }} width={'100%'}>
                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                                    <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                                        <Flex flexDirection="column" style={{ width: "100%" }}>
                                            <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">
                                                <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                                    Kunder
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <div className="CustomersContainer">
                                            <div className="CustomerContainerHeader">
                                                <div className="CustomerContainerHeaderName">Magna Multi - Kunde</div>
                                                <div className="CustomerContainerHeaderSelected">
                                                    <span>e-conomic - Kunde</span>
                                                </div>
                                            </div>
                                            {data.getCustomers.map((customer) => (
                                                <div className="CustomerContainer" key={customer.id}>
                                                    <div className="CustomerName">{customer.name}</div>
                                                    <div className="CustomerSelected">
                                                        <Select defaultValue={customer.eConomicCustomerId} onChange={(e) => handleCustomerChange(e, customer.id)} >
                                                            <option value={""}>-</option>
                                                            {data.getPhotographyCompany.integration.customers.map((customer) => (
                                                                <option key={customer.id} value={customer.id}>{customer.name}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                                    <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                                        <Flex flexDirection="column" style={{ width: "100%" }}>
                                            <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">
                                                <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                                    Produkter
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <div className="CustomersContainer">
                                            <div className="CustomerContainerHeader">
                                                <div className="CustomerContainerHeaderName">Magna Multi - Produkt</div>
                                                <div className="CustomerContainerHeaderSelected">
                                                    <span>e-conomic - Produkt</span>
                                                </div>
                                            </div>
                                            {data.getProducts.map((product) => (
                                                <div className="CustomerContainer" key={product.id}>
                                                    {product.extraService ? (
                                                        <div className="CustomerName">{product.name} (Tillægsprodukt) </div>
                                                    ) : (
                                                        <div className="CustomerName">{product.name}</div>
                                                    )}

                                                    <div className="CustomerSelected">
                                                        <Select defaultValue={product.eConomicProductId} onChange={(e) => handleProductChange(e, product.id)} >
                                                            <option value={""}>-</option>
                                                            {data.getPhotographyCompany.integration.customData1.map((economicProduct) => (
                                                                <option key={economicProduct.id} value={economicProduct.id}>{economicProduct.name}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </Grid>
                    </>
                )}
            </Flex>
        </div>
    );
}

export default Economic;
