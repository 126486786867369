import { useQuery, gql } from "@apollo/client";
import MiniStatistics from "./../components/MiniStatistics";
import { WalletIcon } from "./../../../../components/Icons/Icons";
import { useColorModeValue } from "@chakra-ui/react";

const GET_DATA = gql`
  query GET_Data {
    turnoverCurrentMonth
  }
`;

const TurnoverCurrentMonth = () => {
  const iconTeal = useColorModeValue("gray.400", "gray.400");
  const { loading, data } = useQuery(GET_DATA, {});

  if (loading) return <MiniStatistics
    title={"Aktuel omsætning"}
    amount={''}
    percentage={null}
    icon={<WalletIcon h={"24px"} w={"24px"} color={iconTeal} />}
  />;

  const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <MiniStatistics
      title={"Aktuel omsætning"}
      amount={thousandSeparator(data.turnoverCurrentMonth) + ' kr.'}
      percentage={null}
      icon={<WalletIcon h={"24px"} w={"24px"} color={iconTeal} />}
    />
  );
};

export default TurnoverCurrentMonth;
