/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!

import interactionPlugin, { Draggable } from "@fullcalendar/interaction"; // needed for dayClick
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import { useRef, useEffect } from 'react';

function EventCalendar(props) {
  //onSelectUserChanged
  const { initialDate, onCreateClicked, onDateDblClicked, onEventDblClicked, onEventClicked, onDownloadClicked, onExternalItemDropped, onItemChanged, users, getCalendarEvents } = props;
  const calendarRef = useRef(null);
  const eventTimeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false
  }

  let clickCount = 0;
  let singleClickTimer = null;
  const handleDayClicked = (info) => {
    console.log('handleDayClicked - info:', info);
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
        //alert('single click');
      }, 400);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      onDateDblClicked(info);
      //alert('double click');
      //console.log('double click, info:', info)
    }
  }

  const handleEventClicked = (info) => {
    console.log('handleEventClicked - info:', info);
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
        onEventClicked(info);
      }, 400);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      onEventDblClicked(info);
      //alert('double click');
      //console.log('double click, info:', info)
    }
  }

  setTimeout(() => {
    let draggableEl = document.getElementsByClassName('draggable-calendar-event');
    for (let i = 0; i < draggableEl.length; i++) {
      new Draggable(draggableEl[i]);
    }
  }, 100);

  function handleEventReceive(info) {
    console.log('handleEventReceive - info:', info);
    onExternalItemDropped(info.dateStr, info.draggedEl.getAttribute('data-orderid'), info.draggedEl.getAttribute('data-ordertitle'))
  }

  function handleEventChanged(info) {
    console.log('handleEventChanged - info:', info);
    onItemChanged(info.event.startStr, info.event.endStr, info.event.id)
  }

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }
  useEffect(() => {
    // This effect will run once the component mounts

    /*
    if (users.length === 1) {
      console.log('only one user, hiding dropdown')
      const toolbar = document.querySelector('.fc-toolbar');
      const customButton = toolbar.querySelector('.fc-customButton-button');
      customButton.style.display = 'none';
      return;
    }
      */

    var userDropdown = document.querySelector('.userDropdown');
    var toolbar = document.querySelector('.fc-toolbar');

    toolbar.children[2].style.display = 'flex';
    toolbar.children[2].style.flexDirection = 'row';
    toolbar.children[2].style.flexWrap = 'wrap';
    toolbar.children[2].style.justifyContent = 'flex-end';
    toolbar.children[2].style.alignItems = 'center';

    if (users.length === 1) {
      userDropdown.style.display = 'none';
    } else {
      try {
        toolbar.children[2].appendChild(userDropdown);
      } catch (error) {
        console.log('error:', error)
      }
    }

  }, []);

  let initialView = "dayGridMonth";
  if (localStorage.getItem("dashboard_calenderView")) {
    initialView = localStorage.getItem("dashboard_calenderView");
  }

  const getCalendarData = (fetchInfo, successCallback, failureCallback) => {


    if (document.querySelector('.calendarLoadingContainer')) {
      document.querySelector('.calendarLoadingContainer').style.display = 'flex';
    }
    getCalendarEvents(fetchInfo.startStr, fetchInfo.endStr, (result) => {
      if (document.querySelector('.calendarLoadingContainer')) {
        document.querySelector('.calendarLoadingContainer').style.display = 'none';
      }
      successCallback(result);
    });
  }

  return (
    <>
      <div className="calendarLoadingContainer">
        <div className="calenderLoadingBox">Henter kalender aftaler
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          start: 'title', // will normally be on the left. if RTL, will be on the right
          center: '',
          end: 'downloadCals myCustomButton dayGridMonth,dayGridWeek,dayGridDay prev,next' // will normally be on the right. if RTL, will be on the left
        }}
        initialView={initialView}
        contentHeight="600"
        //events={calendarData}
        events={
          (fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)
        }
        initialDate={initialDate}
        editable={true}
        droppable={true}
        drop={handleEventReceive}
        eventDrop={handleEventChanged}
        selectable={false}
        height="auto"
        locales={allLocales}
        locale={'dk'}
        firstDay={1}
        weekNumbers={true}
        weekText={''}
        displayEventTime={true}
        displayEventEnd={true}
        eventTimeFormat={eventTimeFormat}

        buttonText={{
          today: 'I dag',
          month: 'Måned',
          week: 'Uge',
          day: 'Dag',
          list: 'Liste'
        }}


        customButtons={{

          downloadCals: {
            text: 'Hent kalender',
            click: onDownloadClicked,
          },
          myCustomButton: {
            text: 'Opret aftale',
            click: onCreateClicked,
          },
        }}
        dateClick={function (info) {
          handleDayClicked(info);
        }}
        eventClick={function (info) {
          handleEventClicked(info);
        }

        }
        eventContent={renderEventContent}
        datesSet={function (info) {
          localStorage.setItem("dashboard_calenderView", info.view.type);
        }}

      />
    </>
  );
}

export default EventCalendar;
