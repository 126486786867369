// Chakra imports
import {
    Flex, useColorModeValue, Text, Grid, GridItem, FormControl, FormLabel, Switch, Input
} from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState } from "react";
import Card from "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";

import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useAuth } from "../../../auth-context/auth.context";
import MessageList, { MessageFilter } from "./components/messageList";
import MessageView from "./components/messageView";


const MESSAGES_DATA_GQL = gql`
query getData($id: ID!, $includeOrder: Boolean!) {
  users {
    id
    name
    userType
  }
  getOrder(id: $id) @include(if: $includeOrder) {
    id
    caseNo
    estateAddress
    estateZipCode
    estateCity
  }
}`

function Messages() {
    const userData = useAuth();
    const { orderId } = useParams();
    let CalenderSelectedUser = localStorage.getItem("dashboard_calenderSelectedUser") ? localStorage.getItem("dashboard_calenderSelectedUser").split(',') : [localStorage.getItem("system_user_id")];
    const [calenderSelectedUser, setCalenderSelectedUser] = useState(CalenderSelectedUser);
    const [selectedMessageThreadId, setSelectedMessageThreadId] = useState("");
    const [filter, setFilter] = useState(MessageFilter.ALL);
    const [searchStr, setSearchStr] = useState("");

    //const { isOpen: changeStatusIsOpen, onOpen: changeStatusOnOpen, onClose: changeStatusOnClose } = useDisclosure();
    //const history = useHistory();
    //const toast = useToast();

    //Colors
    //const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const { loading, error, data, refetch } = useQuery(MESSAGES_DATA_GQL, {
        variables: { id: orderId ? orderId : '', includeOrder: !!orderId },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    }
    );

    if (loading) {
        return <p></p>; //loading
    }
    if (error) {
        return <p>Error getting messages from server (1)</p>;
    }
    if (!data) {
        return <p>Error getting messages from server (2)</p>;
    }



    const filterUsers = () => {
        if (userData.user.userType === 'PHOTOGRAPHER') {
            return sortUsers(data.users.filter((user) => user.id === userData.user.id));
        }

        if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

            return sortUsers(data.users.filter((user) => user.userType !== 'ADMIN'));
        }

        if (userData.user.userType === 'ADMIN') {
            return sortUsers(data.users);
        }
    }

    const sortUsers = (users) => {
        let sortedUsers = users.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return sortedUsers;
    }

    const mappedUsers = filterUsers().map((user) => {
        return {
            value: user.id,
            label: user.name
        }
    });

    const getAllSelectedUsers = () => {
        let selectedUsers = [];
        for (let i = 0; i < calenderSelectedUser.length; i++) {
            let user = data.users.find((x) => x.id === calenderSelectedUser[i]);
            if (user) {
                selectedUsers.push({
                    value: user.id,
                    label: user.name
                })
            }
        }
        return selectedUsers
    }

    const handleUserChange = (selectedOptions) => {
        //get selected user ids
        let selectedUserIds = selectedOptions.map((option) => option.value);

        setCalenderSelectedUser(selectedUserIds);
        localStorage.setItem("dashboard_calenderSelectedUser", selectedUserIds.join(','))
    };

    const userDropdownLocalize = {
        "allItemsAreSelected": "Alle fotografer er valgt.",
        "clearSearch": "Ryd søgning",
        "clearSelected": "Ryd valgte",
        "noOptions": "Ingen valgmuligheder",
        "search": "Søg",
        "selectAll": "Vælg alle",
        "selectAllFiltered": "Vælg alle (Filtreret)",
        "selectSomeItems": "Vælg fotograf",
        "create": "Opret",
    }

    const handleFilterChange = (filter) => {
        if (filter.target.checked) {
            setFilter(MessageFilter.UNREAD);
        } else {
            setFilter(MessageFilter.ALL);
        }
    }

    const handleMessageSelected = (threadId, reloadList) => {
        setSelectedMessageThreadId(threadId);
        if (reloadList) {
            //reload message list
            refetch();
        }
    }

    let timeoutId;
    const handleSearchStrChange = (searchStr) => {
        //update search string when this function is not called for 500ms  
        
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setSearchStr(searchStr);
          }, 500);
    }

    return (
        <div className="test">
            <Flex direction="column" style={{ height: 'calc(100vh - 60px)' }} pt={{ base: "20px", md: "35px" }} >
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={data.getOrder ? "Beskeder - " + data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ' - ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity : "Beskeder"}
                    description={'Her kan du kommunikere med dine kunder, sæglere, leverandører mm.'}
                    showBackButton={orderId ? true : false}
                />
                <Card px="0px" style={{ paddingLeft: '20px', paddingRight: '20px' }} pt={{ sm: "10px", md: "5px", lg: "5px" }} pb={{ sm: "10px", md: "5px", lg: "5px" }} mb={{ sm: "10px", md: "10px", lg: "24px" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' , flexWrap: 'wrap' }}>
                            {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (

                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', paddingRight: '25px' }} >
                                    <div style={{ width: '80px' }}>
                                        <Text>Fotograf: </Text>
                                    </div>
                                    <div  >
                                        <MultiSelect
                                            options={mappedUsers}
                                            value={getAllSelectedUsers()}
                                            onChange={handleUserChange}
                                            labelledBy="Select"
                                            placeholder="Vælg bruger"
                                            className="userDropdown"
                                            style={{ width: '100%' }}
                                            overrideStrings={userDropdownLocalize}
                                        />
                                    </div>
                                </div>
                            )}

                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
                                <div style={{ width: '80px' }}>
                                    <Text>Filtrér: </Text>
                                </div>
                                <div>
                                    <Input w={'260px'} onChange={(e) => {handleSearchStrChange(e.target.value)}} />
                                </div>
                            </div>
                        </div>

                        <div style={{ width: '250px' }} >
                            <FormControl display='flex' alignItems='center' justifyContent={{ sm: 'flex-start', md: 'flex-end', xl: 'flex-end' }} >
                                <FormLabel htmlFor='email-alerts' mb='0'>Vis kun ulæste beskeder? </FormLabel>
                                <Switch id='email-alerts' onChange={handleFilterChange} />
                            </FormControl>
                        </div>
                    </div>

                </Card>

                <Grid templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(10, 1fr)", xl: "repeat(10, 1fr)" }} style={{ height: '100%' }} gap={{ sm: "0px", md: "10px", xl: "24px" }} my="0px" mb={{ lg: "26px" }}>

                    <GridItem colSpan={4} paddingBottom={{ sm: "10px", md: "0px", xl: "0px" }}>
                        <Card px="0px" height='100%'>
                            <CardBody height='100%'>
                                <MessageList order={data.getOrder ? data.getOrder : null} filter={filter} searchStr={searchStr} fotographerUserIds={calenderSelectedUser} onMessageSelected={handleMessageSelected} />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={6} >
                        <Card px="0px" height='100%'>
                            <CardBody height='100%'>
                                <MessageView threadId={selectedMessageThreadId} />
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </Flex>

        </div>
    );
}

export default Messages;
