// Chakra imports
import { Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import './messageList.css';
import moment from "moment";
import SentMessageDialog, { messageTypeEnum } from "./sentMessageDialog";
import { FaPaperPlane } from "react-icons/fa";

const MARK_THREAD_AS_READ_GQL = gql`
  mutation markMessagesAsRead($threadId: ID!) {
    markMessagesAsRead(threadId: $threadId)
  }
`;

const GET_MESSAGES_GQL = gql`
query getMessagesByUser($input: getMessagesByUserInput!){
  getMessagesByUser(input: $input){
    id
    threadId
    messageSubject
    messageText
    dateRead
    createdAt
    messageSender
    order {
      id
      caseNo
    }
  }
}`;

/*

const deleteTextSectionGql = gql`
  mutation deleteTextSection($id: ID!) {
    deleteTextSection(id: $id)
  }
`;

const updateTextSectionGql = gql`
  mutation updateTextSection($input: UpdateTextSectionInput!) {
    updateTextSection(input: $input) {
      id
    }
  }
`;

const updateTextSectionSortingGql = gql`
  mutation updateTextSectionSorting($input: [UpdateTextSectionSortingInput]) {
    updateTextSectionSorting(input: $input) 
  }
`;
*/
export enum MessageFilter {
  ALL = 'ALL',  // eslint-disable-line
  UNREAD = 'UNREAD',  // eslint-disable-line
  READ = 'READ',  // eslint-disable-line
}

interface IProps {
  fotographerUserIds: [String] | null
  searchStr: String
  filter: MessageFilter
  onMessageSelected?: any
  order?: any
}

const MessageList: React.FC<IProps> = ({ fotographerUserIds, searchStr, filter, onMessageSelected, order }) => {
  const [showSentNewMessage, setShowSentNewMessage] = useState(false);
  const [messagesRead, setMessagesRead] = useState([]);
  const [markMessagesAsRead] = useMutation(MARK_THREAD_AS_READ_GQL);
  const { loading, error, data, refetch } = useQuery(GET_MESSAGES_GQL, {
    variables: { input: { userIds: fotographerUserIds, searchStr: searchStr, filter: filter, orderId: order?.id } },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }

  const closeSentNewMessage = (threadId) => {
    setShowSentNewMessage(false)
    if (threadId !== null && threadId !== '') {
      onMessageSelected(threadId, true);
    }
  }

  const handleCreateNewMessageClicked = () => {
    setShowSentNewMessage(true)
  }

  const handleRowClick = (message: any) => {
    if (onMessageSelected) {
      markMessagesAsRead({ variables: { threadId: message.threadId } }).then(() => {
        setMessagesRead([...messagesRead, message.threadId]);
      })
      onMessageSelected(message.threadId, false);
    }
  }

  return (
    <>
      <SentMessageDialog key={'OrderSentMessage'} order={order ? order : null} isVisible={showSentNewMessage} onClose={(threadId) => { closeSentNewMessage(threadId) }} messageType={messageTypeEnum.NORMAL_MESSAGE} />

      <div className="messageListContainer">
        <div className="createButton" onClick={handleCreateNewMessageClicked}>
        <Icon as={FaPaperPlane} me="6px" /> Opret ny besked
        </div>
        

        <table className="messageTable">
          <tr>
            <th className="messageHeadingSubject">EMNE</th>
            <th className="messageHeadingCaseNo">SAGSNR.</th>
            <th className="messageHeadingCreatedAt">OPRETTET</th>
          </tr>
        </table>
        <div className="messageTableContainer scrollbar">


          <table className="messageTable">
            <tbody>
              {data.getMessagesByUser.map((message) => (
                <>
                  {
                    (message.dateRead === null &&  ["PHOTOGRAPHY_COMPANY","PHOTOGRAPHER"].indexOf(message.messageSender) === -1 && messagesRead.indexOf(message.threadId) === -1 ) ? (
                      <tr id={message.id} key={message.id} onClick={() => { handleRowClick(message) }}>
                        <td className={'messageSubject'}><b>{message.messageSubject}</b></td>
                        <td className={'messageCaseNo'}><b>{message.order.caseNo}</b></td>
                        <td className={'messageCreatedAt'} style={{ textAlign: 'end',paddingRight: '5px' }}><b>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</b></td>
                      </tr>
                    ) : (
                      <tr id={message.id} key={message.id} onClick={() => { handleRowClick(message) }}>
                        <td className={'messageSubject'}>{message.messageSubject}</td>
                        <td className={'messageCaseNo'}>{message.order.caseNo}</td>
                        <td className={'messageCreatedAt'} style={{ textAlign: 'end', paddingRight: '5px' }}>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                      </tr>
                    )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MessageList;
