// Chakra imports
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, Checkbox, useToast } from "@chakra-ui/react";
import ProfileBgImage from "../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import { useHistory } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import SettleTable from "./../../../../components/Tables/SettleTable";
import React from "react";
import CardHeader from "../../../../components/Card/CardHeader";

const GET_PRODUCT_CATEGORIES = gql`
query getProductCategories {
  getProductCategories {
    id
    name
    showOnOrderForm
    sorting
    products {
      id
    }
  }
}`

const DELETE_PRODUCT_CATEGORY = gql`
mutation deleteProductCategory($id: ID!){
    deleteProductCategory(id:$id)
}`

const CREATE_PRODUCT_CATEGORY = gql`
mutation createProductCategory($input: CreateProductCategoryInput!){
  createProductCategory(input:$input){
    id
  }
}`

const UPDATE_PRODUCT_CATEGORY = gql`
mutation updateProductCategory($input: UpdateProductCategoryInput!){
  updateProductCategory(input:$input){
    id
  }
}`

const UPDATE_PRODUCT_CATEGORY_SORTING = gql`
mutation updateProductCategorySorting($input: [UpdateProductCategorySortingInput]){
  updateProductCategorySorting(input:$input)
}`

const CreateProductCategoryObj = {
  name: '' as string,
  showOnOrderForm: false as boolean,
}

function Products() {
  // Chakra color mode
  const toast = useToast()
  const history = useHistory();
  const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
  const [validate, setValidate] = React.useState(false);
  const [createProductCategoryData, setCreateProductCategoryData] = React.useState(CreateProductCategoryObj);
  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const { isOpen: createProductCategoryIsOpen, onOpen: createProductCategoryOnOpen, onClose: createProductCategoryOnClose } = useDisclosure();
  const [selectedProductCategoryId, setSelectedProductCategoryId] = React.useState('');
  const [isCreateMode, setIsCreateMode] = React.useState(true);

  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [deleteProductCategory] = useMutation(
    DELETE_PRODUCT_CATEGORY,
    {
      refetchQueries: [GET_PRODUCT_CATEGORIES],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [createNewProductCategory] = useMutation(
    CREATE_PRODUCT_CATEGORY,
    {
      refetchQueries: [GET_PRODUCT_CATEGORIES],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [updateProductCategory] = useMutation(
    UPDATE_PRODUCT_CATEGORY,
    {
      refetchQueries: [GET_PRODUCT_CATEGORIES],

    }
  )

  const [updateProductCategorySorting] = useMutation(
    UPDATE_PRODUCT_CATEGORY_SORTING,
    {
      refetchQueries: [GET_PRODUCT_CATEGORIES],
    }
  )

  const { loading, error, data } = useQuery(GET_PRODUCT_CATEGORIES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }

  function rowClicked(obj) {
    history.push('/admin/setting/productCategory/' + obj.id);
  }

  const updateProductCategoryClicked = () => {
    if (CreateProductCategoryObj.name === '') {
      console.log('Validate - CreateUserObj:', CreateProductCategoryObj)
      setValidate(true)
    } else {


      const input = { ...CreateProductCategoryObj, id: selectedProductCategoryId }

      updateProductCategory({
        variables: { input: input } as any
      }).then(() => createProductCategoryOnClose())
        .catch((() => {
          toast({
            title: 'Opdatering af produktkategorien fejlede.',
            description: "Det var ikke muligt at oprette produktkategorien.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });

        })
        );
    }
  }

  const createProductCategory = () => {
    if (CreateProductCategoryObj.name === '') {
      console.log('Validate - CreateUserObj:', CreateProductCategoryObj)
      setValidate(true)
    } else {
      createNewProductCategory({
        variables: { input: CreateProductCategoryObj } as any
      }).then(() => createProductCategoryOnClose())
        .catch((() => {
          toast({
            title: 'Opret produkt kategori fejlede.',
            description: "Det var ikke muligt at oprette produkt kategorien.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });

        })
        );
    }
  }

  const createProductCategoryClicked = () => {
    setIsCreateMode(true);
    CreateProductCategoryObj.name = '';
    CreateProductCategoryObj.showOnOrderForm = false;

    setCreateProductCategoryData(CreateProductCategoryObj)
    setValidate(false)
    createProductCategoryOnOpen();
  }

  const updateData = (field, value) => {
    CreateProductCategoryObj[field] = value;
    setCreateProductCategoryData(CreateProductCategoryObj);
  }

  const deleteProductCategoryClicked = (obj) => {
    setSelectedProductCategoryId(obj.id)
    deleteWarningOnOpen();
  }

  const deleteProductCategoryConfirmed = () => {
    deleteProductCategory({ variables: { id: selectedProductCategoryId } } as any);
    deleteWarningOnClose();
  }

  const setTableData = (obj) => {
    var tableData = obj(data.getProductCategories);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((productCategory) => ({ id: productCategory.id, sorting: productCategory.sorting }));

    updateProductCategorySorting({
      variables: {
        input: result,
      },
    });


  };

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "showOnOrderForm",
      accessor: "showOnOrderForm",
      onClick: rowClicked,
      hidden: true,
      width: 200,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Produkter",
      accessor: "productCount",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Vis på ordreformular",
      accessor: "showOnOrderFormTranslated",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: rowClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: deleteProductCategoryClicked,
    },
  ];

  let tableData = JSON.parse(JSON.stringify(data.getProductCategories));

  for (var i = 0; i < tableData.length; i++) {
    tableData[i].showOnOrderFormTranslated = tableData[i].showOnOrderForm ? 'Ja' : 'Nej';
    tableData[i].productCount = tableData[i].products.length;
  }

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Produkter"}
        description={"Her kan du redigere dine produkt kategorier og produkter."}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={false}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
              <Flex direction="column">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Produktekategorier
                </Text>
              </Flex>
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize="12px"
                  fontWeight="bold"
                  mb="6px"
                  cursor="pointer"
                  onClick={() => {
                    createProductCategoryClicked();
                  }}
                >
                  Opret ny produktkategori
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <SettleTable data={tableData} columnsData={columns} setDragData={setTableData} csvData={[]}  enableSelect={false}/>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>

      <Modal onClose={createProductCategoryOnClose} size="xl" isOpen={createProductCategoryIsOpen}>
        <ModalOverlay />
        <ModalContent>
          {isCreateMode ? <ModalHeader>Opret produktkategori</ModalHeader> : <ModalHeader>Rediger produktkategori</ModalHeader>}

          <ModalCloseButton />
          <ModalBody>
            {isCreateMode ? <Text>Du er ved at oprette en ny produktkategori, udfyld nedenstående<br /> og klik opret.</Text> : <Text>Du er ved at redigere en produktkategori, ret nedenstående<br /> og klik opdater.</Text>}
            <br /><br />
            <Text fontSize='md' fontWeight='bold'>Produkt kategori:</Text>

            <Flex direction='column' style={{ width: '100%' }}>
              <Flex align='center' mb='18px'>
                <Text fontSize='md' color={textColor} fontWeight="bold" me='10px' whiteSpace={'nowrap'} minWidth={'110px'}>
                  Navn:{" "}
                </Text>
                <Input

                  variant='flushed'
                  type='text'
                  name="name"
                  defaultValue={createProductCategoryData.name}
                  onKeyUp={(e) => { updateData('name', e.currentTarget.value) }}
                  className={validate && createProductCategoryData.name === '' ? 'error' : ''}
                />
              </Flex>
            </Flex>
            <Flex direction='column' style={{ width: '100%' }}>
              <Flex align='center' mb='18px'>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
                  Vis:{" "}
                </Text>
                <Checkbox onChange={(e) => updateData('showOnOrderForm', e.target.checked)} defaultChecked={createProductCategoryData.showOnOrderForm}></Checkbox>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={createProductCategoryOnClose}>Fortryd</Button>
            {isCreateMode ? <Button colorScheme='green' onClick={createProductCategory} ml={3}>Opret</Button> : <Button colorScheme='green' onClick={updateProductCategoryClicked} ml={3}>Opdater</Button>}

          </ModalFooter>
        </ModalContent>
      </Modal>


      <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet produkt kategori
            </AlertDialogHeader>

            <AlertDialogBody>Du er ved at slette en produkt kategori og alle underlæggende produkter. <br />Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteWarningOnClose}>
                Nej, behold
              </Button>
              <Button colorScheme="red" onClick={deleteProductCategoryConfirmed} ml={3}>
                Ja, jeg er sikker
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>


  );
}

export default Products;
