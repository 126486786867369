// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import { useQuery, gql } from "@apollo/client";
import ProfileContactInfo from "./components/profileContactInfo";
import ProfileSmtpSetting from "./components/profileSmtpSetting";
import ProfileBranding from "./components/profileBranding";
import ProfileDocument, { DocumentType } from "./components/profileDocument";
import ProfileLogoUpload from "./components/profileLogoUpload";
import ProfileAddOns from "./components/profileAddOns";
import ProfileMindworking from "./components/profileMindworking";
//import { datastore } from "../../../../dataStore";
import { useAuth } from "./../../../../auth-context/auth.context";
import ProfileSettings from "./components/profileSettings";

const GET_PHOTOGRAHY_COMPANY = gql`
query GET_PHOTOGRAHY_COMPANY{
  getPhotographyCompany{
    id
    name
    address
    zipCode
    city
    cvr
    email
    phoneNo
    logoUrl
    primaryColor
    secondaryColor
    textColor
    buyingTermsUrl

    smsEnabled
    emailSmtpServer
    emailSmtpServerPort
    emailUser

    usedStorageSpace
    extraUsers

    mindworkingUsername

    ownMindworkingAccountEnabled

    contactPersonName
    websiteUrl
    billingPeriodStart
    mediaprocessors {
      id
    }
    authers {
      id
    }

    media {
      id
      filename
      orgUrl
      thumpnailUrl
      mediaType
    }

    documents {
      id
      filename
      orgDocStorageKey
      orgDocStorageUrl
      documentType
    }
  }
}`

function Profile() {
  // Chakra color mode
  const userData = useAuth()
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { loading, error, data, refetch } = useQuery(GET_PHOTOGRAHY_COMPANY);

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }

  function forceRerender() {
    refetch()
    userData.user.photographyCompany.logoUrl = data.getPhotographyCompany.logoUrl;
    userData.user.photographyCompany.name = data.getPhotographyCompany.name;
    userData.setUser({ ...userData.user });

  }

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={data.getPhotographyCompany.name}
        description={"Profil"}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={false}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap="24px">
        <GridItem colSpan={1} rowSpan={3} >
          <ProfileContactInfo data={data.getPhotographyCompany} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <ProfileLogoUpload data={data.getPhotographyCompany} heading={"Logo"} subHeading={"(200 x 80 px)"} forceRerender={forceRerender} />
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <ProfileSettings data={data.getPhotographyCompany} />
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <ProfileDocument allowMultiple={false} forceRerender={forceRerender} data={data.getPhotographyCompany} documentType={DocumentType.BUYING_TERMS} heading={'Handelsbetingelser'} />
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <ProfileBranding data={data.getPhotographyCompany} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <ProfileSmtpSetting data={data.getPhotographyCompany} />
        </GridItem>

        <GridItem colSpan={0} rowSpan={0} >
          <ProfileAddOns data={data.getPhotographyCompany} forceRerender={forceRerender} />
        </GridItem>

        {data.getPhotographyCompany.ownMindworkingAccountEnabled && (
          <GridItem colSpan={0} rowSpan={0} >
            <ProfileMindworking data={data.getPhotographyCompany} />
          </GridItem>
        )}

      </Grid>
    </Flex>
  );
}

export default Profile;
