// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Select, Input, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { useState } from "react";
//import { useHistory } from 'react-router-dom';
import moment from "moment";
import { gql, useMutation } from "@apollo/client";
import { Tooltip } from '@chakra-ui/react'
import OrderSentMessage, { MessageType } from "./orderSentMessage";
import OrderChangeProductsDialog from "./orderChangeProductsDialog";
import OrderChangeAssignedUserDialog from "./orderChangeAssignedUserDialog";
import TextOrderDialog from "./textOrderDialog";
import { useAuth } from "./../../../../auth-context/auth.context";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const OrderInfo = ({ data, dataChanged }) => {
  ///const toast = useToast();
  // Chakra color mode
  const userData = useAuth()
  const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const [showProductChangeDialog, setShowProductChangeDialog] = useState(false);
  const [showAssignedUserChangeDialog, setShowAssignedUserChangeDialog] = useState(false);
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [sentMessageType, setSentMessageType] = useState(MessageType.ORDER_BOOKED);
  const [selectedStatus, setSelectedStatus] = useState(data.status)
  const [showOrderTextDialog, setShowOrderTextDialog] = useState(false);

  //const history = useHistory();
  const updateData = (orderId, field, data) => {
    let inputData = {};
    inputData.id = orderId;
    inputData[field] = data;


    updateOrderInfo({
      variables: { input: inputData },
    })
      .then(() => {

        if (field === 'status') {
          setSelectedStatus(data)
          //console.log('data:', data)
          switch (data) {
            case 'RECEIVED':
              setSentMessageType(MessageType.AWAITING_RESPONSE_FROM_CONTACT_PERSON);
              setShowSentMessage(false);
              break;
            case 'SELLER_NOT_RESPONDING':
              setSentMessageType(MessageType.UNSUCCESSFULL_CONTACT_ATTEMPT);
              setShowSentMessage(true);
              break;
            case 'AWAITING_RESPONSE_FROM_CONTACT_PERSON':
              setSentMessageType(MessageType.AWAITING_RESPONSE_FROM_CONTACT_PERSON);
              setShowSentMessage(false);
              break;
            case 'PUT_ON_HOLD':
              setSentMessageType(MessageType.PUT_ON_HOLD);
              setShowSentMessage(false);
              break;
            case 'FOTOSHOT_PLANED':
              setSentMessageType(MessageType.ORDER_BOOKED);
              setShowSentMessage(true);
              break
            case 'SEASON_FOTOSHOT_PLANED':
              setSentMessageType(MessageType.ORDER_SEASON_UPDATE_BOOKED);
              setShowSentMessage(true);
              break
            case 'WEATHER_UPDATE_FOTOSHOT_PLANED':
              setSentMessageType(MessageType.ORDER_WEATHER_UPDATE_BOOKED);
              setShowSentMessage(true);
              break
            case 'BEING_PROCESSED':
              setSentMessageType(MessageType.BEING_PROCESSED);
              setShowSentMessage(false);
              break;
            case 'PROCESSING_COMPLETED':
              setSentMessageType(MessageType.PROCESSING_COMPLETED);
              setShowSentMessage(false);
              break;
            case 'DELIVERED':
              setSentMessageType(MessageType.ORDER_DELIVERED);
              setShowSentMessage(true);
              break;
            case 'INVOICED':
              setSentMessageType(MessageType.INVOICED);
              setShowSentMessage(false);
              break;
            case 'CANCELED':
              setSentMessageType(MessageType.ORDER_CANCELED);
              setShowSentMessage(true);
              dataChanged();
              break;
            default:
              return "Ukendt";
          }
        }

        console.log('calling dataChanged')
        // dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.error(e)
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere ordren.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };

  const closeSentMessage = () => {
    console.log('closeSentMessage');
    setShowSentMessage(false)
  }

  /*
  const ShowCalenderMode = () => {
    history.push('/admin/order/' + data.id + '/planning');
  }
    */

  if (data === undefined) {
    return ('')
  }

  if (selectedStatus === null) {
    setSelectedStatus(data.status)
  }

  let showMessageIcon = false;
  switch (selectedStatus) {
    case 'SELLER_NOT_RESPONDING':
    case 'FOTOSHOT_PLANED':
    case 'DELIVERED':
    case 'CANCELED':
      showMessageIcon = true;
      break;
    default:
      showMessageIcon = false;
      break;
  }

  const getProductName = (product) => {
   
    if (product.allowMultiple) {
      if (product.amount > 1) {
        return product.name + ' (' + product.amount + ' stk.)';
      }
    }

    return product.name;
  }

  return (
    <>
      {showSentMessage && (
        <OrderSentMessage key={'OrderSentMessage'} messageType={sentMessageType} orderId={data.id} isVisible={showSentMessage} onClose={() => { closeSentMessage() }} />
      )}
      <Card key={'OrderInfoCard'} p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%", overflow: 'hidden' }}>
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            Ordre information
          </Text>
        </CardHeader>
        <CardBody px="5px">
          <Flex direction="column" style={{ width: "100%" }}>
            <Flex align="center" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                    Status:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} display={'flex'} alignItems={'center'} >
                  <Select
                    multiple={false}
                    value={selectedStatus}
                    onChange={(e) => {
                      updateData(
                        data.id,
                        "status",
                        e.target.value
                      );
                    }}
                  >
                    <option key={"RECEIVED"} value="RECEIVED">
                      Modtaget
                    </option>
                    <option key={"SELLER_NOT_RESPONDING"} value="SELLER_NOT_RESPONDING">
                      Kontaktperson træffes ikke
                    </option>
                    <option key={"AWAITING_RESPONSE_FROM_CONTACT_PERSON"} value="AWAITING_RESPONSE_FROM_CONTACT_PERSON">
                      Afventer svar fra kontaktperson
                    </option>
                    <option key={"PUT_ON_HOLD"} value="PUT_ON_HOLD">
                      Ordren er sat i bero
                    </option>
                    <option key={"FOTOSHOT_PLANED"} value="FOTOSHOT_PLANED">
                      Fotografering planlagt
                    </option>
                    <option key={"SEASON_FOTOSHOT_PLANED"} value="SEASON_FOTOSHOT_PLANED">
                      Seasonopdatering planlagt
                    </option>
                    <option key={"WEATHER_UPDATE_FOTOSHOT_PLANED"} value="WEATHER_UPDATE_FOTOSHOT_PLANED">
                      Vejropdatering planlagt
                    </option>
                    <option key={"BEING_PROCESSED"} value="BEING_PROCESSED">
                      Ordren efterbehandles
                    </option>
                    <option key={"PROCESSING_COMPLETED"} value="PROCESSING_COMPLETED">
                      Efterbehandling er afsluttet
                    </option>
                    <option key={"DELIVERED"} value="DELIVERED">
                      Ordren er leveret
                    </option>
                    <option key={"INVOICED"} value="INVOICED">
                      Ordren er faktureret
                    </option>
                    <option key={"CANCELED"} value="CANCELED">
                      Ordren er annulleret
                    </option>
                  </Select>

                  {showMessageIcon && (
                    <div style={{ cursor: 'pointer' }} onClick={() => { setShowSentMessage(true) }}>
                      <svg height="25px" width="25px" style={{ marginLeft: '20px', marginRight: '20px' }} version="1.1" id="Capa_1" viewBox="0 0 25.951 25.951">
                        <g>
                          <path fill="#030104" d="M3,0.225h18c1.656,0,3,1.344,3,3v10c0,0.313-0.062,0.609-0.15,0.893l-2.056-1.832
		c-0.451-0.457-1.358-0.662-2.078-0.369l-3.692-3.779L23,2.7L12,8.632L1,2.7l6.977,5.438l-5.77,5.906l7.037-5.025L12,10.813
		l2.758-1.795l4.467,3.191c-0.451,0.366-0.725,0.922-0.725,1.531v1.043c-1.135,0.168-2.473,0.565-3.703,1.441H3
		c-1.656,0-3-1.344-3-3V3.225C0,1.569,1.344,0.225,3,0.225z"/>
                          <g>
                            <path fill="#030104" d="M20,13.741v2.434c-3.227,0-7.5,1.564-7.5,9.551c1.412-5.096,3.314-5.488,7.5-5.488v2.473
			c0,0.191,0.105,0.363,0.281,0.437c0.059,0.024,0.121,0.036,0.182,0.036c0.123,0,0.244-0.048,0.334-0.139l5.016-4.504
			c0.184-0.184,0.184-0.484,0-0.668l-5.016-4.465c-0.135-0.135-0.34-0.176-0.516-0.103S20,13.549,20,13.741z"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                  )}
                </GridItem>
              </Grid>

            </Flex>
            <Flex align="center" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                    Sags nr:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} >
                  <Input
                    variant="flushed"
                    type="text"
                    name="caseNo"
                    defaultValue={data.caseNo}
                    onBlur={(e) => updateData(data.id, 'caseNo', e.currentTarget.value)}
                  />
                </GridItem>
              </Grid>
            </Flex>



            <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "150px", md: "150px", xl: "150px" }}>
                    Produkt(er):{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} >
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>
                      {data.products.map(product => {
                        return (
                          <div style={{ cursor: 'pointer' }} key={product.id} className={'productName'} >

                            <Tooltip label={
                              <Text dangerouslySetInnerHTML={{ __html: product.description }} ></Text>
                            } aria-label='A tooltip'>
                              {getProductName(product)}
                            </Tooltip>
                          </div>
                        )
                      })}

                    </Text>
                    <div onClick={() => setShowProductChangeDialog(true)} style={{ cursor: 'pointer' }}>
                      <svg width="17px" height="17px" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                    </div>
                  </span>
                </GridItem>
              </Grid>
            </Flex>

            <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Fotografering d.:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} >
                  {data.photeshotEvent ? (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                      {moment(data.photeshotEvent.start).format("DD-MM-YYYY")}  Kl.:{String(new Date(data.photeshotEvent.start).getHours()).padStart(2, '0') + ":" + String(new Date(data.photeshotEvent.start).getMinutes()).padStart(2, '0')} - {String(new Date(data.photeshotEvent.end).getHours()).padStart(2, '0') + ":" + String(new Date(data.photeshotEvent.end).getMinutes()).padStart(2, '0')}
                    </Text>
                  ) : (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} >Ikke planlagt</Text>
                  )}
                </GridItem>
              </Grid>
              {/*
              <div onClick={() => { ShowCalenderMode() }} style={{ cursor: 'pointer' }}>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Interface / External_Link">
                    <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </div>
              */}
            </Flex>
            <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Tildelt:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} display={'flex'} >
                  {data.assignedUser ? (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>{data.assignedUser.name}</Text>
                  ) : (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>Ikke tildelt</Text>
                  )}
                  {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (
                    <div onClick={() => setShowAssignedUserChangeDialog(true)} style={{ cursor: 'pointer' }}>
                      <svg width="17px" height="17px" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                    </div>
                  )}
                </GridItem>
              </Grid>
            </Flex>
            <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Tekst forfatter:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} display={'flex'} >
                  {data.authorOrder ? (
                    <>
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>{data.authorOrder.subcontractor.name}</Text>
                      <div style={{ cursor: 'pointer' }}>
                        <a href={data.authorOrder.orderUiUrl} target='_blank' rel="noreferrer">
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Interface / External_Link">
                              <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>Ikke tildelt</Text>
                      <div onClick={() => setShowOrderTextDialog(true)} style={{ cursor: 'pointer' }}>
                        <svg width="17px" height="17px" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                      </div>
                    </>
                  )}
                </GridItem>
              </Grid>

            </Flex>

            {['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType) && (
              <Flex align="flex-start" mb="18px">
                <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                  <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                      Samlet pris:{" "}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={5} rowSpan={1} >
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"}>
                      {data.totalPrice} kr.
                    </Text>
                  </GridItem>
                </Grid>
              </Flex>
            )}


            <Flex align="center" mb="18px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Oprettet:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} >
                  <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                    {moment(data.createdAt).format("DD-MM-YYYY")} kl.: {moment(data.createdAt).format("HH:mm")}
                  </Text>
                </GridItem>
              </Grid>
            </Flex>


            <Flex align="center" mb="40px">
              <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Leveringsdato:{" "}
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} >
                  {data.deliveryDate ? (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                      {moment(data.deliveryDate).format("DD-MM-YYYY")} kl.: {moment(data.deliveryDate).format("HH:mm")}
                    </Text>
                  ) : (
                    <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                      Ordren er ikke leveret
                    </Text>
                  )}
                </GridItem>
              </Grid>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <OrderChangeProductsDialog key={'OrderChangeProductsDialog'} orderId={data.id} products={data.products} customerId={data.customer.id} isOpen={showProductChangeDialog} onClose={(updateData) => { setShowProductChangeDialog(false); if (updateData) { dataChanged(); } }} />

      <OrderChangeAssignedUserDialog key={'OrderChangeAssignedUserDialog'} orderIds={[data.id]} assignedUserId={data.assignedUser?.id} isOpen={showAssignedUserChangeDialog} onClose={(updateData) => { setShowAssignedUserChangeDialog(false); if (updateData) { dataChanged(); } }} />

      <TextOrderDialog isOpen={showOrderTextDialog} onClose={() => { setShowOrderTextDialog(false); dataChanged(); }} orderId={data.id} />
    </>
  );
};

export default OrderInfo;
