// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select
} from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
//import { useTranslation } from "react-i18next";
import SettleTable from "../../../../../components/Tables/SettleTable";

const createTextSectionGql = gql`
  mutation createTextSection($input: CreateTextSectionInput!) {
    createTextSection(input: $input) {
      id
    }
  }
`;

const deleteTextSectionGql = gql`
  mutation deleteTextSection($id: ID!) {
    deleteTextSection(id: $id)
  }
`;

const updateTextSectionGql = gql`
  mutation updateTextSection($input: UpdateTextSectionInput!) {
    updateTextSection(input: $input) {
      id
    }
  }
`;

const updateTextSectionSortingGql = gql`
  mutation updateTextSectionSorting($input: [UpdateTextSectionSortingInput]) {
    updateTextSectionSorting(input: $input) 
  }
`;

const CreateTextSectionObj = {
  name: '' as string,
  textType: 'TEXT' as string,
  textLength: 100 as number,
}

interface IProps {
  textSections: any[]
  forceRerender: any
  profileId: String
}

const TextSectionList: React.FC<IProps> = ({ textSections, forceRerender, profileId }) => {
  const toast = useToast();
  //const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAddTextSectionDialogOpen, onOpen: onAddTextSectionDialogOpen, onClose: onAddTextSectionDialogClose } = useDisclosure();

  const cancelRef = React.useRef();
  const [createTextSection] = useMutation(createTextSectionGql);
  const [deleteTextSection] = useMutation(deleteTextSectionGql);
  const [updateTextSection] = useMutation(updateTextSectionGql);
  const [updateTextSectionSorting] = useMutation(updateTextSectionSortingGql);

  //const [operators, setOperators] = useState([]);
  //const [selectedField, setSelectedField] = useState(conditionFields[0]);
  const [selectedTextSection, setSelectedTextSection] = useState<any>();
  //const [fieldValues, setFieldValues] = useState([]);
  //const [numberInputValue, setNumberInputValue] = useState(0);
  //const [stringInputValue, setStringInputValue] = useState("");
  //const [createTextSectionData, setCreateTextSectionData] = React.useState(CreateTextSectionObj);
  const textColor = useColorModeValue("gray.700", "white");

  const handleAddTextSectionClicked = () => {

    CreateTextSectionObj.name = '';
    CreateTextSectionObj.textType = 'TEXT';
    CreateTextSectionObj.textLength = 100;
    //setCreateTextSectionData(CreateTextSectionObj);
    onAddTextSectionDialogOpen();
  };

  const onAddTextSectionClicked = () => {

    if (selectedTextSection) {
      //update
      const inputData = {
        id: selectedTextSection.id,
        name: CreateTextSectionObj.name,
        textType: CreateTextSectionObj.textType,
        textLength: CreateTextSectionObj.textLength
      }
      updateTextSection({ variables: { input: inputData } });
    }else{
      //create
      const inputData = { ...CreateTextSectionObj, profileId: profileId }
      createTextSection({ variables: { input: inputData } });
    }

    onAddTextSectionDialogClose();

    toast({
      title: "Feltet er nu oprettet.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    setSelectedTextSection(null);

    setTimeout(() => {
      forceRerender();
    }, 1000);
  }

  const handleDeleteClick = (selectedObj) => {
    for (let f = 0; f < textSections.length; f++) {
      if (selectedObj.id === textSections[f].id) {
        setSelectedTextSection(textSections[f]);
      }
    }
    onOpen();
  };

  const handleEditClick = (selectedObj) => {
    for (let f = 0; f < textSections.length; f++) {
      if (selectedObj.id === textSections[f].id) {
        setSelectedTextSection(textSections[f]);

        CreateTextSectionObj.name = textSections[f].name;
        CreateTextSectionObj.textType = textSections[f].textType;
        CreateTextSectionObj.textLength = textSections[f].textLength;
        //setCreateTextSectionData(CreateTextSectionObj);
      }
    }

    onAddTextSectionDialogOpen();
  };

  const deleteTextSectionClicked = () => {
    onClose();
    deleteTextSection({ variables: { id: selectedTextSection.id } });

    setTimeout(() => {
      forceRerender();
    }, 200);
    setSelectedTextSection(null);
  };

  const onTextSectionModalClose = () => {
    onAddTextSectionDialogClose();
    setSelectedTextSection(null);
  };

  const updateData = (field, value) => {
    CreateTextSectionObj[field] = value;
    if (field === 'textLength') {
      CreateTextSectionObj[field] = parseInt(value);
    }
    //setCreateTextSectionData(CreateTextSectionObj);
  }

  const setTableData = (obj) => {
    var tableData = obj(textSections);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((product) => ({ id: product.id, sorting: product.sorting }));

    updateTextSectionSorting({
      variables: {
        input: result,
      },
    }).then(() => {
      forceRerender()
    });
  };

  const convertTextType = (textType) => {
    switch (textType) {
      case 'HEADING':
        return 'Overskrift';
      case 'SUBHEADING':
        return 'Under overskrift';
      case 'TEXT':
        return 'Tekst';
      case 'SOME':
        return 'SoMe tekst';
      case 'SALES_BULLETS':
        return 'Salgspunkter';
      default:
        return '';
    }
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Type",
      accessor: "fieldType",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Længde",
      accessor: "textLength",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: handleEditClick,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: handleDeleteClick,
    },
  ];

  let tableData = JSON.parse(JSON.stringify(textSections));

  for (var i = 0; i < tableData.length; i++) {
    tableData[i].fieldType = convertTextType(tableData[i].textType);
  }

  return (
    <Card p="16px">
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Tekst felter
        </Text>
        <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleAddTextSectionClicked()}>
          Tilføj
        </Text>
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        <SettleTable data={tableData} columnsData={columns} csvData={[]} setDragData={setTableData} enableSelect={false} />
      </CardBody>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteTextSectionClicked}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet tekst felt
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteTextSectionClicked} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isAddTextSectionDialogOpen} onClose={onTextSectionModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret nyt tekst felt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprettet et nyt tekst felt, udfyld nedenstående og klik opret.</Text>
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Navn:{" "}
                </Text>
                <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={selectedTextSection ? selectedTextSection.name : ""}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Type:{" "}
                </Text>
                <Select onChange={(e) => updateData('textType', e.target.value)} defaultValue={selectedTextSection ? selectedTextSection.textType : ""}>
                  <option value="HEADING">Overskrift</option>
                  <option value="SUBHEADING">Under overskrift</option>
                  <option value="TEXT">Tekst</option>
                  <option value="SOME">SoMe tekst</option>
                  <option value="SALES_BULLETS">Salgspunkter</option>
                </Select>
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Maks antal karakterer:{" "}
                </Text>
                <Input
                    variant="flushed"
                    type="number"
                    name="textLength"
                    defaultValue={selectedTextSection ? selectedTextSection.textLength : "100"}
                    onBlur={(e) => updateData('textLength', e.target.value)}
                  />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onTextSectionModalClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={onAddTextSectionClicked} ml={3}>
              {selectedTextSection ? <div>Gem</div> : <div>Opret</div>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default TextSectionList;
