// Chakra imports
import {
    Flex,
    useColorModeValue,
    useToast,
    Tooltip,
    Checkbox,
    Text,
    Image
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import Header from "../../../../../components/Headers/Header";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { useQuery, useMutation, gql } from "@apollo/client";
import leadHub_logo from "./../../../../../assets/img/leadHub_logo.png";

import './../integrations.css';
import { FaInfoCircle } from "react-icons/fa";

const CUSTOMERS_GQL = gql`
query customers {
    getCustomers {
        id
        name
        leadhubEnabled
    }
}`

const UPDATE_CUSTOMER_GQL = gql`
mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input:$input){
        id
    }
}`

function LeadHub() {
    const toast = useToast()

    //Colors
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [updateCustomer] = useMutation(
        UPDATE_CUSTOMER_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const handleCustomerEnabledChange = (e, customerId) => {
        const value = e.target.checked;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    leadhubEnabled: value
                }
            }
        }).then(() => {
            toast({
                title: "Butikken er opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af Butikken.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    return (
        <div className="LeadHubSettings">
            <Flex direction="column">
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Indstillinger - LeadHub"}
                    description={'Opsætning af LeadHub'}
                    showBackButton={true}
                />

                <Card p="16px" my={{ sm: "0px", xl: "24px" }}>
                    <CardHeader p="12px 5px" justifyContent="space-between" >
                        <Flex justifyContent="center" align="center" style={{ width: "100%" }} >
                            <div style={{ background: 'rgb(123 123 123)', padding: '20px', borderRadius: '10px' }}>
                            <Image
                                src={leadHub_logo}
                                alt='LeadHub logo'
                                maxWidth={'160px'}
                            />
                            </div>
                        </Flex>
                    </CardHeader>
                </Card>

                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">

                                <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                    Du er nu forbundet med LeadHub!
                                </Text>
                                <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                    Nedenfor skal du aktivere de kunder, som skal have adgang til LeadHub.
                                </Text>


                            </Flex>
                        </Flex>
                        <div className="CustomersContainer">
                            <div className="CustomerContainerHeader">
                                <div className="CustomerContainerHeaderName">Kunde</div>
                                <div className="CustomerContainerHeaderEnabled">
                                    <span>Aktiveret</span>
                                    <Tooltip hasArrow label={'Markere boksen ud for kunden for at aktivere LeadHub.'} aria-label='A tooltip' placement='bottom'>
                                        <span>
                                            <FaInfoCircle style={{ color: '#000' }} />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            {data.getCustomers.map((customer) => (
                                <div className="CustomerContainer" key={customer.id}>
                                    <div className="CustomerName">{customer.name}</div>
                                    <div className="CustomerEnabled">
                                        <Checkbox defaultChecked={customer.leadhubEnabled} onChange={(e) => handleCustomerEnabledChange(e, customer.id)} className="CustomerCheckBox" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </Flex>
        </div>
    );
}

export default LeadHub;
