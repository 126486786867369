// Chakra imports
import { Flex, Grid, GridItem, Hide } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
// assets

// Custom icons
import LeadprMonth from "./components/LeadprMonth";
import { signOut } from "../../../auth-context/authHelper";
import OrderAddress from "./../Orders/components/orderAddress"
import OrderHeader from "./../Orders/components/orderHeader"
import { useAuth } from "../../../auth-context/auth.context";
import OrderInfo from "../Orders/components/orderInfo";
import OrderSeller from "../Orders/components/orderSeller";
import OrderNotes, { NoteType } from "../Orders/components/orderNotes";
import { useHistory } from "react-router-dom";
import OrderCustomer from "../Orders/components/orderCustomer";
import TurnoverCurrentMonth from "./components/TurnoverCurrentMonth";
import NewLeadsToDay from "./components/NewLeadsToDay";
import NewOrders from "./components/NewOrders";
import FollowUpOrders from "./components/FollowUpOrders";
import ProcessingCompletedOrders from "./components/ProcessingCompletedOrders";
import PutOnHoldOrders from "./components/PutOnHoldOrders";
import UserCalender from "./components/UserCalender";

const GET_DASHBOARD_INFO = gql`
  query GET_DASHBOARD_INFO($orderId: ID!, $includeOrder: Boolean!) {
    order: getOrder(id: $orderId) @include(if: $includeOrder) {
      id
      caseNo
      unreadMessageCount
      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      internalNote
      totalPrice
      deliveryDate
      keyCase
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
        amount
        allowMultiple
      }
      customer {
        id 
        name
        address
        zipCode
        city
        email
        phoneNo
      }
      assignedUser {
        id
        name
      }
      authorOrder {
        id
        subcontractor {
          id
          name
          subcontractorType
        }
        orderUiUrl
        status
      }
      
      status
      createdAt
      updatedAt
    }
  }
`;

const Dashboard = () => {
  const userData = useAuth()
  const history = useHistory();
  const { paramsOrderId } = useParams();
  const [newOrdersRenderKey, setNewOrdersRenderKey] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_DASHBOARD_INFO, {
    variables: {
      getOrdersinput: {
        userId: localStorage.getItem("system_user_id"),
        status: ['RECEIVED']
      },
      orderId: paramsOrderId ? paramsOrderId : 0,
      includeOrder: !!paramsOrderId,

    },
    manual: true,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  },
  );


  useEffect(() => {
    //set scroll position to buttom of page
    window.scrollTo(0, 0);
  }, [data]);

  if (loading) {
    return <p></p>; //loading
  }

  if (error) {
    if (error.message.includes('401')) {
      signOut();
    }
    return <p>Error getting data from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting data from server (2)</p>;
  }

  let calenderSpan = {sm:3, md:2, xl:2};
  if (data.order) {
    calenderSpan = 3;
  }

  const updateComponent = (component) => {
    //alert('updateComponent: ' + component)
    switch (component) {
      case 'NewOrders':
        console.log('updateComponent: NewOrders')
        setNewOrdersRenderKey(prevKey => prevKey + 1);
        break;
      default:
        break;
    }
  }

  const handleTextButtonClicked = () => {
    history.push('/admin/order/' + data.order.id + '/text');
  }

  const handleMediaButtonClicked = () => {
    history.push('/admin/order/' + data.order.id + '/media');
  };

  const handleMessagesButtonClicked = () => {
    history.push('/admin/order/' + data.order.id + '/messages');
  };

  return (
    <>

      <Flex flexDirection="column" pt={{ base: "20px", md: "35px" }}>
        {data.order && (
          <OrderHeader
            name={"Ordre - " + data.order.caseNo + ' - ' + data.order.estateAddress + ' - ' + data.order.estateZipCode + ' ' + data.order.estateCity}
            description={'Ordre overblik'}
            onCreateOrderClicked={() => { }}
            showCreateOrderButton={false}
            showBackButton={true}
            onMediaButtonClicked={handleMediaButtonClicked}
            showMediaButton={true}
            showTextButton={true}
            onTextButtonClicked={handleTextButtonClicked}
            onMessagesButtonClicked={handleMessagesButtonClicked}
            showMessagesButton={true}
            unReadMessages={data.order.unreadMessageCount}
          />
        )}

        <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px" my="26px" mb={{ lg: "26px" }}>
          {(!data.order && ['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) && (
            <Hide breakpoint='(max-width: 800px)'>
              <GridItem colSpan={2} rowSpan={2}>
                <LeadprMonth />
              </GridItem>
            </Hide>
          )}
          {(!data.order && ['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) && (
            <>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <TurnoverCurrentMonth />
              </GridItem>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <NewLeadsToDay />
              </GridItem>

            </>
          )}
          {data.order && (
            <GridItem colSpan={3} rowSpan={0}>
              <Grid templateColumns={{
              base: "1fr", // default for small screens
              md: "repeat(2, 1fr)", // 2 columns for medium screens
              lg: "repeat(2, 1fr)", // 2 columns for large screens
              xl: "repeat(2, 1fr)", // 2 columns for extra large screens
              "2xl": "repeat(3, 1fr)" // 3 columns for screens larger than 1600px
              }}
                gap="22px">
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderInfo data={data.order} dataChanged={() => { refetch() }} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderAddress data={data.order} dataChanged={() => { refetch() }} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderCustomer data={data.order} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderSeller data={data.order} dataChanged={() => { refetch() }} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderNotes data={data.order} noteType={NoteType.note} dataChanged={() => { }} heading={'Note - delt med kunde'} />
                </GridItem>
                <GridItem colSpan={0} rowSpan={1} >
                  <OrderNotes data={data.order} noteType={NoteType.internalNote} dataChanged={() => { }} heading={'Note - intern'} />
                </GridItem>
              </Grid>
            </GridItem>
          )}

          <GridItem colSpan={calenderSpan} rowSpan={7} >
            <UserCalender selectedOrder={data.order} updateComponent={(component) => updateComponent(component)} />
          </GridItem>

          {!data.order && (
            <>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <NewOrders key={newOrdersRenderKey} />
              </GridItem>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <FollowUpOrders />
              </GridItem>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <ProcessingCompletedOrders />
              </GridItem>
              <GridItem colSpan={{sm:3, md:1, xl:1}} rowSpan={1}>
                <PutOnHoldOrders />
              </GridItem>
            </>
          )}
        </Grid>
      </Flex>
    </>
  );
};


export default Dashboard;
