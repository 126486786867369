import {
    Flex, Text, useColorModeValue, useToast, Input,
    Select,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Button
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const GET_PRODUCTS = gql`
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
        id
        productCategories {
            id
            name
            products {
                id
                name
                description
                allowMultiple
                amount
                subProducts {
                    id
                    name
                    description
                    allowMultiple
                    amount
                }
            }
        }
    }   
}`



interface IProps {
    onClose: any,
    customerId: string,
    orderId: string,
    isOpen: boolean,
    products: any
}

const OrderChangeProductsDialog: React.FC<IProps> = ({ orderId, customerId, isOpen, onClose, products }) => {
    const [selectedMainProduct, setSelectedMainProduct] = useState({} as any);
    const [selectedSubProducts, setSelectedSubProducts] = useState([] as any);

    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const subProduct1 = useRef(null);
    const subProduct1Amount = useRef(null);
    const subProduct2 = useRef(null);
    const subProduct2Amount = useRef(null);
    const subProduct3 = useRef(null);
    const subProduct3Amount = useRef(null);
    const mainProduct = useRef(null);

    const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
    const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(GET_PRODUCTS, {
        variables: { id: customerId },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });


    useEffect(() => {
        if (dataProducts) {
            let mainProduct = dataProducts.getCustomer.productCategories.map((category: any) => {
                return category.products.filter((product: any) => {
                    //is in products
                    return products.find((p: any) => p.id === product.id) !== undefined;

                })
            }).flat(1)[0];
            setSelectedMainProduct(mainProduct);
            let selectedSubProducts = products.filter((p: any) => p.id !== mainProduct.id);
            setSelectedSubProducts(selectedSubProducts);
        }
    }, [dataProducts, products])


    if (loadingProducts) return <p></p>; //loading

    if (errorProducts) {
        return <p>Error getting products from server (1)</p>;
    }

    if (!dataProducts) {
        return <p>Error getting products from server (2)</p>;
    }


    const handleSaveChanges = () => {

        const mainProductId = mainProduct.current.value;
        let subProductId1 = "";
        let subProductAmount1 = 1;
        if (subProduct1.current) {
            subProductId1 = subProduct1.current.value;
            subProductAmount1 = subProduct1Amount.current ? parseInt(subProduct1Amount.current.value) : 1;
        }

        let subProductId2 = "";
        let subProductAmount2 = 1;
        if (subProduct2.current) {
            subProductId2 = subProduct2.current.value;
            subProductAmount2 = subProduct2Amount.current ? parseInt(subProduct2Amount.current.value) : 1;
        }

        let subProductId3 = "";
        let subProductAmount3 = 1;
        if (subProduct3.current) {
            subProductId3 = subProduct3.current.value;
            subProductAmount3 = subProduct3Amount.current ? parseInt(subProduct3Amount.current.value) : 1;
        }

        let inputData = {} as any;
        inputData.id = orderId;
        inputData.orderProducts = [];
        inputData.orderProducts.push({
            productId: mainProductId,
            amount: 1
        });
        if (subProductId1 !== "") {
            inputData.orderProducts.push(
            {
                productId: subProductId1,
                amount: subProductAmount1
            });
        }
        if (subProductId2 !== "") {
            inputData.orderProducts.push({
                productId: subProductId2,
                amount: subProductAmount2
            });
        }
        if (subProductId3 !== "") {
            inputData.orderProducts.push({
                productId: subProductId3,
                amount: subProductAmount3
            });
        }

        updateOrderInfo({
            variables: { input: inputData },
        })
            .then(() => {
                onClose(true);
                toast({
                    title: "Ændringen er blevet gemt.",
                    description: "",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                console.error(e)
                toast({
                    title: "Opdatering af ordren fejlede.",
                    description: "Det var ikke muligt at opdatere ordren.",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            });
    }

    const handleSubProductChange = (subProductIndex: number) => {
        if (subProductIndex === 1) {
            let subProductId = subProduct1.current.value;
            if (subProductId === "") {
                //remove subproduct
                let newSelectedSubProducts = [...selectedSubProducts];
                newSelectedSubProducts.splice(subProductIndex - 1, 1);
                setSelectedSubProducts(newSelectedSubProducts);
                return;
            }
            let subProduct = { ...selectedMainProduct.subProducts.find((p: any) => p.id === subProductId) };
            subProduct.amount = 1;
            let newSelectedSubProducts = [...selectedSubProducts];
            newSelectedSubProducts[subProductIndex - 1] = subProduct;
            newSelectedSubProducts = newSelectedSubProducts.filter((p: any) => p !== undefined && p !== null);
            setSelectedSubProducts(newSelectedSubProducts);
        }
        if (subProductIndex === 2) {
            let subProductId = subProduct2.current.value;
            if (subProductId === "") {
                //remove subproduct
                let newSelectedSubProducts = [...selectedSubProducts];
                newSelectedSubProducts.splice(subProductIndex - 1, 1);
                setSelectedSubProducts(newSelectedSubProducts);
                return;
            }
            let subProduct = { ...selectedMainProduct.subProducts.find((p: any) => p.id === subProductId) };
            subProduct.amount = 1;
            let newSelectedSubProducts = [...selectedSubProducts];
            newSelectedSubProducts[subProductIndex - 1] = subProduct;
            newSelectedSubProducts = newSelectedSubProducts.filter((p: any) => p !== undefined && p !== null);
            setSelectedSubProducts(newSelectedSubProducts);
        }
        if (subProductIndex === 3) {
            let subProductId = subProduct3.current.value;
            if (subProductId === "") {
                //remove subproduct
                let newSelectedSubProducts = [...selectedSubProducts];
                newSelectedSubProducts.splice(subProductIndex - 1, 1);
                setSelectedSubProducts(newSelectedSubProducts);
                return;
            }
            let subProduct = { ...selectedMainProduct.subProducts.find((p: any) => p.id === subProductId) };
            subProduct.amount = 1;
            let newSelectedSubProducts = [...selectedSubProducts];
            newSelectedSubProducts[subProductIndex - 1] = subProduct;
            newSelectedSubProducts = newSelectedSubProducts.filter((p: any) => p !== undefined && p !== null);
            setSelectedSubProducts(newSelectedSubProducts);
        }

    }

    const handleMainProductChange = (productId: string) => {
        console.log('productId', productId)
        //find adon products
        let mainProduct = dataProducts.getCustomer.productCategories.map((category: any) => {
            return category.products.filter((product: any) => {
                return product.id === productId;
            })
        }).flat(1)[0];
        setSelectedMainProduct(mainProduct);
    }

    return (
        <Modal onClose={() => { onClose(false) }} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxW="800px" bg={'#f9f9f9'}>
                <ModalHeader>Vælg produkt</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex align='center' mb='18px'>
                            <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                Produkt:{" "}
                            </Text>
                            <Select ref={mainProduct} multiple={false} onChange={(e) => { handleMainProductChange(e.target.value) }}>
                                {dataProducts.getCustomer.productCategories.map((category: any) => {
                                    return (
                                        <optgroup key={category.id} label={category.name}>
                                            {category.products.map((product: any) => {

                                                if (products.find((p: any) => p.id === product.id) === undefined) {
                                                    return (
                                                        <option key={product.id} value={product.id}>{product.name}</option>
                                                    )
                                                }
                                                return (
                                                    <option key={product.id} value={product.id} selected>{product.name}</option>
                                                )
                                            })}
                                        </optgroup>
                                    )

                                })}
                            </Select>
                        </Flex>
                        {selectedMainProduct && selectedMainProduct.subProducts && selectedMainProduct.subProducts.length > 0 && (
                            <>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 1:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct1} onChange={() => handleSubProductChange(1)} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 0 && subProduct.id === selectedSubProducts[0].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            } else {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                    {selectedSubProducts.length > 0 && (
                                        <>
                                            <Text fontSize='md' color={textColor} pl='10px' me='10px' whiteSpace={'nowrap'} minWidth={'50px'}>
                                                Antal:{" "}
                                            </Text>
                                            <Input type='number' ref={subProduct1Amount} disabled={!selectedSubProducts[0].allowMultiple} w={'50px'} defaultValue={selectedSubProducts[0].allowMultiple ? selectedSubProducts[0].amount : 1} />
                                        </>
                                    )}
                                </Flex>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 2:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct2} onChange={() => handleSubProductChange(2)} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 1 && subProduct.id === selectedSubProducts[1].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            } else {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                    {selectedSubProducts.length > 1 && (
                                        <>
                                            <Text fontSize='md' color={textColor} pl='10px' me='10px' whiteSpace={'nowrap'} minWidth={'50px'}>
                                                Antal:{" "}
                                            </Text>
                                            <Input type='number' ref={subProduct2Amount} disabled={!selectedSubProducts[1].allowMultiple} w={'50px'} defaultValue={selectedSubProducts[1].allowMultiple ? selectedSubProducts[1].amount : 1} />
                                        </>
                                    )}
                                </Flex>
                                <Flex align='center' mb='18px'>
                                    <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                        Tillægsprodukt 3:{" "}
                                    </Text>
                                    <Select multiple={false} ref={subProduct3} onChange={() => handleSubProductChange(3)} >
                                        <option key={'none'} value={''} selected>Ingen</option>
                                        {selectedMainProduct.subProducts.map((subProduct: any) => {
                                            if (selectedSubProducts.length > 2 && subProduct.id === selectedSubProducts[2].id) {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id} selected>{subProduct.name}</option>
                                                )
                                            } else {
                                                return (
                                                    <option key={subProduct.id} value={subProduct.id}>{subProduct.name}</option>
                                                )
                                            }
                                        })}
                                    </Select>
                                    {selectedSubProducts.length > 2 && (
                                        <>
                                            <Text fontSize='md' color={textColor} pl='10px' me='10px' whiteSpace={'nowrap'} minWidth={'50px'}>
                                                Antal:{" "}
                                            </Text>
                                            <Input type='number' ref={subProduct3Amount} disabled={!selectedSubProducts[2].allowMultiple} w={'50px'} defaultValue={selectedSubProducts[2].allowMultiple ? selectedSubProducts[2].amount : 1} />
                                        </>
                                    )}
                                </Flex>
                            </>
                        )}
                    </Flex>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { onClose(false) }}>Fortryd</Button>
                    <Button colorScheme='green' onClick={() => { handleSaveChanges() }} ml={3}>Ok</Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    );
}

export default OrderChangeProductsDialog;