// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  Button
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import OrderHeader from "../components/orderHeader"
import OrderMediaList from "../components/orderMediaList"
import { FaPaperPlane } from "react-icons/fa";
import MediaProcesserV4 from "../components/mediaProcesserV4"
import { useState } from "react";
import { SubcontractorType } from "../../../../types";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import moment from "moment";
//import MessageModal, { messageType, messageViewType } from "../../../../components/MessageModal/MessageModal";
import { useAuth } from "../../../../auth-context/auth.context";
import SentMessageDialog, { messageTypeEnum } from "../../Messages/components/sentMessageDialog";


const GET_ORDER = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      internalNote
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
      }
      customer {
        id 
        name
        address
        zipCode
        city
        email
        phoneNo
      }
      subcontractorOrders {
        id
        subcontractor {
          id
          name
          subcontractorType
          isGlobal
          language
        }
        status
        orderUiUrl
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
      
    }
  }
`;

function OrderMedia() {
  const { orderId } = useParams() as any;
  const textColor = useColorModeValue("gray.700", "white");
  const [showOrderMediaProcessingDialog, setshowOrderMediaProcessingDialog] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [subcontractorOrder, setSubcontractorOrder] = useState(null);
  const [currentMessageType, setCurrentMessageType] = useState<messageTypeEnum>(messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION);
  const userData = useAuth()

  const { loading, error, data, refetch } = useQuery(
    GET_ORDER,
    {
      variables: { id: orderId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting order from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting order from server (2)</p>;
  }

  const mediaOrders = data.getOrder.subcontractorOrders.filter((order) => order.subcontractor.subcontractorType === SubcontractorType.MEDIA_PROCESSOR);

  const handleOpenUrl = (url) => {
    window.open(url, '_blank');
  }

  const handleCancelOrder = (subcontractorOrder) => {
    setSubcontractorOrder(subcontractorOrder);
    setCurrentMessageType(messageTypeEnum.SUBCONTRACTOR_ORDER_CANCEL);
    setShowMessageDialog(true);
  }

  const allowSubcontractorLogin = (subcontractor) => {
    if (!subcontractor.isGlobal) {
      return true;
    }
    if (['ADMIN'].includes(userData.user.userType)) {
      return true;
    }

    return false;
  }

  const orderStatusText = (order) => {
    switch (order.status) {
      case 'SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt media behandling hos <b>{order.subcontractor.name}</b> d. {moment(order.createdAt).format('DD-MM-YYYY')}. Filerne er IKKE leveret endnu. </p>
          <div style={{ display: 'display', gap: '10px' }}>
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" mr="10px" width={'160px'} onClick={() => { handleCancelOrder(order) }}>Annuller ordren</Button>
            {allowSubcontractorLogin(order.subcontractor) && (
              <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
            )}
          </div>
        </div>);
      case 'READY_FOR_ZIP':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt media behandling hos <b>{order.subcontractor.name}</b> d. {moment(order.createdAt).format('DD-MM-YYYY')}. Ordren behandles. </p>
          <div style={{ display: 'display', gap: '10px' }}></div>
        </div>);
      case 'DOWNLOADED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name}</b>, som har åbnet ordren. </p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'CORRECTION_SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er sendt en korrektion til <b>{order.subcontractor.name}</b>. </p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'COMPLETED':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt media behandling hos <b>{order.subcontractor.name}</b>, filerne er leveret d. {moment(order.updatedAt).format('DD-MM-YYYY')}. </p>
          <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'200px'} onClick={() => { setSubcontractorOrder(order); setCurrentMessageType(messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION); setShowMessageDialog(true) }}>Send besked ang. korrektur</Button>
        </div>);
      case 'REJECTED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name} </b>, som har afvist ordren.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'CANCELED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil media behandling hos <b>{order.subcontractor.name} </b>, ordren er annulleret.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      default:
        return '';
    }
  }

  return (
    <>
      <SentMessageDialog key={'OrderSentMessage'} isVisible={subcontractorOrder && showMessageDialog} onClose={() => { setShowMessageDialog(false); refetch(); }} order={data.getOrder} subcontractorOrder={subcontractorOrder} messageType={messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION} />
      
      <Flex direction="column"  pt={{ base: "20px", md: "35px" }}>
        <OrderHeader
          name={"Ordre - " + data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ' - ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity}
          description={'Billeder og video´er'}
          onMediaButtonClicked={() => { }}
          onTextButtonClicked={() => { }}
          showMediaButton={false}
          showTextButton={false}
          showBackButton={true}
          customButtonShow={true}
          customButtonIcon={FaPaperPlane}
          customButtonOnClick={() => { setshowOrderMediaProcessingDialog(true) }}
          customButtonText={"Send materiale til redigering"}
          onMessagesButtonClicked={() => { }}
          showMessagesButton={false}
          unReadMessages={0}
        />

        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="22px"
        >
          <GridItem w="100%" colSpan={4} rowSpan={2}>
            {mediaOrders.length > 0 && (
              <Card p="16px" bg="#efefef">
                <CardHeader p="12px 5px" mb="12px" style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  <Text fontSize="lg" color={textColor} fontWeight="bold"  >
                    Bestillinger
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    {mediaOrders.map((order) => (
                      <Flex align="center" mb="0px" key={order.id}>
                        <Text fontSize="md" color={textColor} width={"100%"} minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                          {orderStatusText(order)}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>

          <GridItem w="100%" colSpan={4} rowSpan={2}>
            <OrderMediaList orderId={data.getOrder.id} />
          </GridItem>
        </Grid>
      </Flex>

      <MediaProcesserV4 isOpen={showOrderMediaProcessingDialog} onClose={() => { setshowOrderMediaProcessingDialog(false); refetch(); }} orderId={data.getOrder.id} />
    </>
  );
}
export default OrderMedia;