import { useQuery, gql } from "@apollo/client";
import MiniStatistics from "./MiniStatistics";
import {  CartIcon } from "../../../../components/Icons/Icons";
import { useColorModeValue } from "@chakra-ui/react";

const GET_DATA = gql`
  query GET_Data {
    newLeadsToDay
  }
`;

const NewLeadsToDay = () => {
  const iconTeal = useColorModeValue("gray.400", "gray.400");
  const { loading, data } = useQuery(GET_DATA, {});

  if (loading) return <MiniStatistics
    title={"Nye ordrer denne måned"}
    amount={''}
    percentage={null}
    icon={<CartIcon h={"24px"} w={"24px"} color={iconTeal} />}
  />;

  return (
    <MiniStatistics
        title= { "Nye ordrer denne måned"}
        amount = { data.newLeadsToDay }
        percentage = { null}
        icon = {< CartIcon h = { "24px"} w = { "24px"} color = { iconTeal } />}
      />
  );
};

export default NewLeadsToDay;
