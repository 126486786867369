import { useQuery, gql } from "@apollo/client";
import {  DocumentIcon } from "./../../../../components/Icons/Icons";
import { Flex, Icon, useColorModeValue, Stack, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import IconBox from "./../../../../components/Icons/IconBox";

const GET_DATA = gql`
  query searchOrders($search: String!) {
    searchOrders(search: $search){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
    }
  }
`;

const GlobalSearchResult = (props) => {
  const textColor = useColorModeValue("gray.700", "white");
  const iconTeal = useColorModeValue("gray.400", "gray.400");
  
  const { loading, data } = useQuery(GET_DATA, {
    variables: {
      search: props.searchStr,
    }
  });


  if (loading) return <p></p>;

  if (!data) return <p></p>;


  let seachResults = data.searchOrders.map(order => {
    let keyCase = '';
    if (order.keyCase === "COORDINATE_WITH_CONTACTPERSON" || order.keyCase === "COORDINATE_WITH_ESTATEAGENT") {
      keyCase = ' 🔑';
    }

    return {
      id: order.id,
      address: order.estateAddress + ", " + order.estateZipCode + ' ' + order.estateCity,
      caseNo: order.caseNo + keyCase,
      addressLink: '/#/admin/order/' + order.id + '/planning',
      assignedUser: order.assignedUser ? order.assignedUser.name : null
    }
  });

  return (
    <>
      {seachResults.length > 0 ? (
        <Stack direction="column" spacing="20px">
          {seachResults.map((item, cid) => (
            <Flex align="center" key={`newOrder-${cid}`} className='draggable-calendar-event'>
              <div className="upCommingEventLink" onClick={()=>{props.orderClicked(item)}} style={{cursor:'pointer'}}>
                <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                  <Icon as={DocumentIcon} h={"22px"} w={"22px"} color={iconTeal} />
                </IconBox>
                <Flex direction="column">
                  <Text color={textColor} fontSize="sm" fontWeight="bold">
                    {item.caseNo}
                  </Text>
                  <Text color="gray.400" fontSize="sm" fontWeight="normal">
                    {item.address}
                  </Text>
                </Flex>
              </div>
            </Flex>
          ))}
        </Stack>
      ): ( 
        <Stack direction="column" spacing="20px">
        <text>Ingen sager matcher</text>
      </Stack>
      )}

    </>
  );
};

GlobalSearchResult.propTypes = {
  searchStr:PropTypes.string,
  orderClicked:  PropTypes.func,
};

export default GlobalSearchResult;
