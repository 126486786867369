// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import OrderInfo from "./../components/orderInfo"
import OrderHeader from "./../components/orderHeader"
import OrderAddress from "./../components/orderAddress"
import OrderNotes, { NoteType } from "./../components/orderNotes";
import OrderSeller from "./../components/orderSeller"
import OrderCustomer from "./../components/orderCustomer"
import { useHistory } from "react-router-dom";

const GET_ORDER = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      internalNote
      keyCase
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
        price
      }
      customer {
        id 
        name
        address
        zipCode
        city
        email
        phoneNo
      }
      status
      assignedUser {
        id
        name
      }
      createdAt
      updatedAt
      
    }
  }
`;

function Order() {
  const history = useHistory();
  const { orderId } = useParams();
  const { loading, error, data, refetch } = useQuery(
    GET_ORDER,
    {
      variables: { id: orderId },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting order from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting order from server (2)</p>;
  }

  function forceRerender() {
  
    refetch();
  }

  const handleTextButtonClicked = () => {
    history.push('/admin/order/' + orderId + '/text');
  }

  const handleMediaButtonClicked = () => {
    history.push('/admin/order/' + orderId + '/media');
  };

  const handleMessagesButtonClicked = () => {
    history.push('/admin/order/' + orderId + '/messages');
  };

  return (
    <>

      <Flex direction="column">
        <OrderHeader
          name={"Ordre - " + data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ' - ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity}
          description={'Overblik'}
          onMediaButtonClicked={handleMediaButtonClicked}
          showMediaButton={true}
          showBackButton={true}
          showTextButton={true}
          onTextButtonClicked={handleTextButtonClicked}
          onMessagesButtonClicked={handleMessagesButtonClicked}
        />
        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="22px"
        >

          <GridItem w="100%" colSpan={2} rowSpan={2}>
            <OrderInfo data={data.getOrder} dataChanged={forceRerender} />
          </GridItem>

          <GridItem w="100%" colSpan={2} rowSpan={2}>
            <OrderAddress data={data.getOrder} />
          </GridItem>

          <GridItem w="100%" colSpan={2} rowSpan={1}>
            <OrderCustomer data={data.getOrder} />
          </GridItem>
          <GridItem w="100%" colSpan={2} rowSpan={1}>
            <OrderSeller data={data.getOrder} dataChanged={forceRerender} />
          </GridItem>

          <GridItem w="100%" colSpan={4} rowSpan={1}>
            <OrderNotes data={data.getOrder} dataChanged={forceRerender} noteType={NoteType.note} heading={'Note - delt med kunde'} />
          </GridItem>

          <GridItem w="100%" colSpan={4} rowSpan={1}>
            <OrderNotes data={data.getOrder} dataChanged={forceRerender} noteType={NoteType.internalNote} heading={'Note - intern'} />
          </GridItem>
        </Grid>
      </Flex>
    </>

  );
}
export default Order;