// Chakra imports
import React from "react";
import './messageViewEmpty.css';
import imageEmptyThread from "./../../../../assets/img/empty_thread.jpg";
import chatSvg from "./../../../../assets/img/chat.svg";
import {
  Hide
} from "@chakra-ui/react";
interface IProps {

}

const messageViewEmpty: React.FC<IProps> = () => {
  return (
    <div className="messageViewEmpty">
     
        <div className="messageViewEmptyContainer">
          <Hide breakpoint='(max-width: 850px)'>
          <img src={imageEmptyThread} alt="empty thread" className="messageViewEmptyImage" />
          </Hide>
          <div className="messageViewEmptyText">
          <div className="messageViewEmptyTextHeading">
            Kommunikér med dine kunder og samarbejdspartnere.
            </div>
            <div className="messageViewEmptyTextNormal">
              Opret eller vælg en samtale for at komme i gang.
            </div>
          </div>
        </div>

    </div>)
}

export default messageViewEmpty;
