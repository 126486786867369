// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import { useHistory } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import SettleTable from "./../../../../components/Tables/SettleTable";

const GET_STANDARD_MESSAGES = gql`
query standardMessages {
  standardMessages {
    id
    name
    description
    messageType
    updatedAt
  }
}`

function StandardMessages() {
  // Chakra color mode
  const history = useHistory();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { loading, error, data } = useQuery(GET_STANDARD_MESSAGES);

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }

  function rowClicked(obj) {
    history.push('/admin/setting/message/' + obj.messageType);
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "messageType",
      accessor: "messageType",
      hidden: true
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Beskrivelse",
      accessor: "description",
      onClick: rowClicked,
      width: 200,
    },

    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: rowClicked,
    }
  ];


  let tableData = JSON.parse(JSON.stringify(data.standardMessages));

  for (var i = 0; i < tableData.length; i++) {

    tableData[i].updatedAt = tableData[i].updatedAt.split('T')[0];
    tableData[i].messageType = (tableData[i].messageType + '').toLowerCase();
    if (!tableData[i].id) {
      tableData[i].id = 'none-' + i;
    }

    switch ((tableData[i].messageType + '').toUpperCase()) {
      case 'ORDER_CONFIRMATION':
        tableData[i].name = 'Ordrebekræftelse';
        break;
      case 'UNSUCCESSFULL_CONTACT_ATTEMPT':
        tableData[i].name = 'Forgæves kontakt';
        break;
      case 'ORDER_BOOKED':
        tableData[i].name = 'Ordre booket';
        break
      case 'ORDER_WEATHER_UPDATE_BOOKED':
        tableData[i].name = 'Vejropdatering planlagt';
        break
      case 'ORDER_SEASON_UPDATE_BOOKED':
        tableData[i].name = 'Sæsonopdatering planlagt';
        break
      case 'ORDER_DELIVERED':
        tableData[i].name = 'Ordre leveret';
        break;
      case 'ORDER_CANCELED':
        tableData[i].name = 'Ordre annulleret';
        break;
      case 'ORDER_REMINDER':
        tableData[i].name = 'Ordre påmindelse';
        break;

      default:
        break;
    }
  }
  console.log('tableData:', tableData)
  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Standard beskeder"}
        description={'Her kan du redigere dine standard beskeder.'}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={false}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Card px="0px">
        <CardBody>
          <SettleTable data={tableData} columnsData={columns} setDragData={false} csvData={[]} enableSelect={false} />
        </CardBody>
      </Card>
    </Flex>


  );
}

export default StandardMessages;
