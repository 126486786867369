import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import Header from "../../../components/Headers/Header";
import ReportCard from "./components/ReportCard";
import { useHistory } from "react-router-dom";
import imageEarningreport from "./../../../assets/img/earningreport.jpg";
import imageDetailedReport from "./../../../assets/img/detailedReport.png";
import { useAuth } from "./../../../auth-context/auth.context";

function Reports() {
  const history = useHistory();
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const userData = useAuth();
  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <Header backgroundHeader={ProfileBgImage} backgroundProfile={bgProfile} name={"Rapporter"} />
      <Grid templateColumns={{ sm: "2fr", xl: "repeat(3, 2fr)" }} gap="22px">

        <ReportCard
          title={"Omsætning fordelt på produkter"}
          description={"Detaljeret rapport over omsætning fordelt på produkter"}
          image={imageDetailedReport}
          onclick={() => {
            history.push("report/product_turnover");
          }}
        />

        <ReportCard
          title={"Faktureringsrapport"}
          description={"Faktureringsrapport for leverede ordrer"}
          image={imageEarningreport}
          onclick={() => {
            history.push("report/billingreport");
          }}
        />

        {['ADMIN'].includes(userData.user.userType) && (
          <ReportCard
          title={"Leverandør forbrugsrapport"}
          description={'Leverandør forbrugsrapport.'}
            image={imageEarningreport}
            onclick={() => {
              history.push("report/globalsubcontractorbilling");
            }}
          />
        )}
      </Grid>
    </Flex>
  );
}

export default Reports;
