// Chakra imports
import {
    Flex, useColorModeValue, Text,
    Input,
    useToast,
    Tooltip,
    Image
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import Header from "./../../../../../components/Headers/Header";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import { useQuery, useMutation, gql } from "@apollo/client";
import MindworkingLogoLight from "./../../../../../assets/img/Mindworking-logo-light.svg";
import './../integrations.css';
import { FaInfoCircle } from "react-icons/fa";

const CUSTOMERS_GQL = gql`
query customers {
    getCustomers {
        id
        name
        mwShopId
        mwSystemName
    }
}`

const UPDATE_CUSTOMER_GQL = gql`
mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input:$input){
        id
    }
}`

function Mindworking() {
    const toast = useToast()

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [updateCustomer] = useMutation(
        UPDATE_CUSTOMER_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const handleCustomerShopNoChange = (e, customerId) => {
        const value = e.target.value;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    mwShopId: value
                }
            }
        }).then(() => {
            toast({
                title: "Butiks Nr. opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af Butiks Nr.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    const handleCustomerSystemNameChange = (e, customerId) => {
        const value = e.target.value;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    mwSystemName: value
                }
            }
        }).then(() => {
            toast({
                title: "System navn opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af System navn.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    return (
        <div className="MindworkingSettings">
            <Flex direction="column">
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Indstillinger - Mindworking"}
                    description={'Opsætning af Mindworking'}
                    showBackButton={true}
                />

                <Card p="16px" my={{ sm: "0px", xl: "24px" }}>
                    <CardHeader p="12px 5px" justifyContent="space-between" >
                        <Flex justifyContent="center" align="center" style={{ width: "100%" }}>
                            <Image
                                src={MindworkingLogoLight}
                                alt='Mindworking logo'
                                maxWidth={'320px'}
                            />
                        </Flex>
                    </CardHeader>
                </Card>

                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">

                                <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                    Du er nu forbundet med Mindworking!
                                </Text>
                                <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                    Nedenfor skal du indtaste Butiks nr. og System navn for hver kunde.
                                </Text>


                            </Flex>
                        </Flex>

                        <div className="CustomersContainer">
                            <div className="CustomerContainerHeader">
                                <div className="CustomerContainerHeaderName">Kunde</div>
                                <div className="CustomerContainerHeaderUrl">
                                    <span>Butiks Nr.</span>
                                    <Tooltip hasArrow label={'Alle kunder i Mindworking har et butik nr. dette nummer skal indtastes nedenfor.'} aria-label='A tooltip' placement='bottom'>
                                        <span>
                                            <FaInfoCircle style={{ color: '#000' }} />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="CustomerContainerHeaderShopNo">
                                    <span>System navn</span>
                                    <Tooltip hasArrow label={'Alle kunder i Mindworking har et system navn som er en del af den url som kunden tilgår Mindworking med. Hvis kunden f.eks. tilgår Mindworking med https://danbolig.mindworking.eu, så er system navnet danbolig'} aria-label='A tooltip' placement='bottom'>
                                        <span>
                                            <FaInfoCircle style={{ color: '#000' }} />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>

                            {data.getCustomers.map((customer) => (
                                <div className="CustomerContainer">
                                    <div className="CustomerName">{customer.name}</div>
                                    <div className="CustomerShopNoInput">
                                        <Input defaultValue={customer.mwShopId} onBlur={(e) => handleCustomerShopNoChange(e, customer.id)} className="CustomerInput" />
                                    </div>
                                    <div className="CustomerUrlInput">
                                        <Input defaultValue={customer.mwSystemName} onBlur={(e) => handleCustomerSystemNameChange(e, customer.id)} className="CustomerInput" />
                                    </div>
                                </div>
                            ))}

                        </div>
                    </CardBody>
                </Card>
            </Flex>
        </div>
    );
}

export default Mindworking;
