// Chakra imports
import {
  Flex, Text, useColorModeValue, useToast, ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
// Custom components
import Card from "../../../../components/Card/Card";
import { MultiValue, Select as ChakraSelect } from 'chakra-react-select';
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useState, useRef, useEffect } from "react";
import './sentMessageDialog.css';
import MessageViewTextBar from "./messageViewTextBar";
import moment from "moment";
import { use } from "i18next";

const SENT_MESSAGE_GQL = gql`
  mutation sentInternalMessage($input: SentMessageInput!) {
    sentInternalMessage(input: $input) {
      id
      threadId
    }
  }
`;

const SEARCH_ORDERS_GQL = gql`
  query searchOrders($search: String!) {
    searchOrders(search: $search){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
      customer {
        id
      }
      subcontractorOrders {
        id
        createdAt
        subcontractor {
          id
          name
        }
      }
    }
  }
`;

export enum messageTypeEnum {
  SUBCONTRACTOR_ORDER_CORRECTION = "SUBCONTRACTOR_ORDER_CORRECTION",  // eslint-disable-line
  NORMAL_MESSAGE = "NORMAL_MESSAGE",  // eslint-disable-line
  SUBCONTRACTOR_ORDER_CANCEL = "SUBCONTRACTOR_ORDER_CANCEL",  // eslint-disable-line
}

interface IProps {
  isVisible: boolean
  onClose: any
  subcontractorOrder?: any
  order?: any
  messageType: messageTypeEnum
}

const SentMessageDialog: React.FC<IProps> = ({ onClose, isVisible, order, subcontractorOrder, messageType }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const textSubjectRef = useRef(null);
  const messageTextRef = useRef('');
  const toast = useToast();
  const client = useApolloClient();
  const [isFilterOrdersLoading, setIsFilterOrdersLoading] = useState(false);
  const [filteredOrderOptions, setFilteredOrderOptions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState<any>(order ? order : { id: '' });
  const [selectedSubContractOrderId, setSelectedSubContractOrderId] = useState<any>(subcontractorOrder ? subcontractorOrder.id : null);
  const [selectedMedias, setSelectedMedias] = useState<any[]>();
  const [selectedMessageType, setSelectedMessageType] = useState<messageTypeEnum | string>(messageType);
  const [sentMessageMutation] = useMutation(SENT_MESSAGE_GQL);

  let initselectedReceivers = null;
  if (subcontractorOrder) {
    initselectedReceivers = { value: 'sentMailToSubContractor', label: 'Leverandør' };
  }

  const [selectedReceivers, setSelectedReceivers] = useState<{ value: string; label: string; } | null>(initselectedReceivers);

  useEffect(() => {
    setSelectedMessageType(messageType);
  });

  if (!selectedReceivers && initselectedReceivers) {
    setSelectedReceivers(initselectedReceivers);
    setSelectedSubContractOrderId(subcontractorOrder.id);
  }

  const CloseSentMessage = () => {
    onClose('');
  }

  const handleOrderChange = (value, item) => {

    setSelectedOrder(item.originalValue);
    if (item.originalValue.subcontractorOrders.length > 0) {
      setSelectedSubContractOrderId(item.originalValue.subcontractorOrders[0].id);
    }
  }

  const SentMessage = () => {
    if (messageTextRef.current === '') {
      toast({
        title: "Besked mangler",
        description: "Besked skal udfyldes",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (selectedOrder.id === '') {
      toast({
        title: "Ordre mangler",
        description: "Vælg venligst en ordre",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (textSubjectRef.current.value === '') {
      toast({
        title: "Emne mangler",
        description: "Emne skal udfyldes",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (selectedReceivers === null) {
      toast({
        title: "Modtager mangler",
        description: "Vælg venligst en modtager",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    let newMessage = {} as any;
    newMessage.messageSubject = textSubjectRef.current.value + '';
    newMessage.messageText = messageTextRef.current + '';
    newMessage.messageSender = 'PHOTOGRAPHY_COMPANY';
    newMessage.orderId = selectedOrder.id;



    if (isSubSupplierSelected() && selectedSubContractOrderId) {
      //find subcontractorId from selectedSubContractOrderId
      const subcontractorOrder = selectedOrder.subcontractorOrders.find((subcontractorOrder) => subcontractorOrder.id === selectedSubContractOrderId);
      newMessage.subcontractorId = subcontractorOrder.subcontractor.id;
      newMessage.subcontractorOrderId = selectedSubContractOrderId;
      newMessage.messageType = selectedMessageType;
    } else {
      newMessage.customerId = selectedOrder.customerId;
      newMessage.messageType = messageTypeEnum.NORMAL_MESSAGE;
    }

    //convert selectedMedias to correct format
    let medias = [];
    selectedMedias.forEach((media) => {
      medias.push({
        mediaId: media.id,
        markerOverLay: media.markerOverLay ? media.markerOverLay : ""
      });
    });
    newMessage.medias = medias;

    sentMessageMutation({
      variables: {
        input: newMessage
      }
    }).then((data) => {
      //reload messages and set this in the view
      onClose(data.data.sentInternalMessage.threadId);
    });
  };


  const getHeading = (language) => {
    switch (selectedMessageType) {
      case messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION:
        if (language === "da-DK") {
          return "Korrektion af ordre";
        }
        return "Correction of order";
      case messageTypeEnum.SUBCONTRACTOR_ORDER_CANCEL:
        if (language === "da-DK") {
          return "Annullering af ordre";
        }
        return "Cancellation of order";
      default:
        if (language === "da-DK") {
          return "Besked";
        }
        return "Message";
    }
  }

  const isSubSupplierSelected = () => {



    if (!selectedReceivers && initselectedReceivers) {
      setSelectedReceivers(initselectedReceivers);
      return true;
    }

    if (selectedReceivers === null) {
      return false;
    }

    return selectedReceivers.value === 'sentMailToSubContractor';

    /*
    const selectedValues = selectedReceivers.map((selectedReceiver) => {
      return selectedReceiver.value;
    });

    return selectedValues.includes('sentMailToSubContractor');
    */
  }

  if (!isVisible) {
    return null;
  }

  const onChangeInputHandler = (evt) => {
    setIsFilterOrdersLoading(true);

    client.query({
      query: SEARCH_ORDERS_GQL,
      variables: {
        search: evt.target.value
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }).then((data) => {

      const orders = data.data.searchOrders;
      if (orders.length === 0) {
        setFilteredOrderOptions([]);
        setIsFilterOrdersLoading(false);
        return;
      }

      const mappedOrders = orders.map((order) => {
        return {
          id: order.id,
          caseNo: order.caseNo,
          address: order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity,
          estateAddress: order.estateAddress,
          estateZipCode: order.estateZipCode,
          estateCity: order.estateCity,
          customerId: order.customer.id,
          subcontractorOrders: order.subcontractorOrders,
        }
      });

      setFilteredOrderOptions(mappedOrders);
      setIsFilterOrdersLoading(false);
    });
  };

  const sentToOptions = [
    { value: 'sentMailToCustomer', label: 'Kunde/Ejendomsmægler' },
    //{ value: 'sentMailToContactPerson', label: 'Kontaktperson' },
    { value: 'sentMailToSubContractor', label: 'Leverandør' },
  ]

  const handleSentMessageClicked = () => {
    //not needed button is disabled in this view
    console.log('send besked');
  }

  const handleMessageChanged = (messageText: string, selectedMedias: any[]) => {
    messageTextRef.current = messageText;
    setSelectedMedias(selectedMedias);
  }

  const handleSubContractOrderChanged = (value: string) => {
    setSelectedSubContractOrderId(value);
  }

  return (
    <Modal onClose={CloseSentMessage} size="xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>Send besked</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" style={{ width: "100%", height: "100%" }}>
            <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
              <CardHeader p="0px" mb="0px">
              </CardHeader>
              <CardBody px="5px" height={"100%"}>
                <Flex direction="column" style={{ width: "100%", height: "100%" }}>
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                      Til:{" "}
                    </Text>
                    <ChakraSelect
                      name="sentTo"
                      className="sentToSelector"
                      options={sentToOptions}
                      isMulti={false}
                      placeholder="Vælg modtager..."
                      closeMenuOnSelect={true}
                      defaultValue={(initselectedReceivers && subcontractorOrder) ? initselectedReceivers : selectedReceivers}
                      onChange={(value) => { setSelectedReceivers(value) }}
                    />
                  </Flex>
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                      Emne:{" "}
                    </Text>
                    <Input
                      ref={textSubjectRef}
                      type="text"
                      name="mailSubject"
                      defaultValue={subcontractorOrder ? getHeading(subcontractorOrder.subcontractor.language) : ''}
                    />
                  </Flex>

                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                      Ordre/Sag:{" "}
                    </Text>

                    {order ? (
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                        {selectedOrder.caseNo + ' - ' + selectedOrder.estateAddress + ', ' + selectedOrder.estateZipCode + ' ' + selectedOrder.estateCity}
                      </Text>
                    ) : (
                      <AutoComplete isLoading={isFilterOrdersLoading} onChange={(value, item) => { handleOrderChange(value, item) }} >
                        <AutoCompleteInput variant="filled" onChange={onChangeInputHandler} />
                        <AutoCompleteList>
                          {filteredOrderOptions.map((order, cid) => (
                            <AutoCompleteItem
                              key={`option-${cid}`}
                              value={order}
                              id={order.id}
                              label={order.caseNo + ' - ' + order.address}
                            >
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                    )}
                  </Flex>

                  {(isSubSupplierSelected() && selectedOrder.id) && (
                    <>
                      <Flex align="center" mb="18px">
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                          Leverandør ordre:{" "}
                        </Text>
                        {(selectedOrder && selectedOrder.subcontractorOrders && selectedOrder.subcontractorOrders.length > 0) ? (
                          <Select onChange={(e) => { handleSubContractOrderChanged(e.currentTarget.value) }} defaultValue={selectedSubContractOrderId}>
                            {selectedOrder.subcontractorOrders.map((subcontractorOrder, cid) => (
                              <option key={`option-${cid}`} value={subcontractorOrder.id}>{subcontractorOrder.subcontractor.name} - Bestilt: {moment(subcontractorOrder.createdAt).format('DD-MM-YYYY HH:MM')}</option>
                            ))}
                          </Select>
                        ) : (
                          <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                            {subcontractorOrder ? subcontractorOrder.subcontractor.name + ' - Bestilt: ' + moment(subcontractorOrder.createdAt).format('DD-MM-YYYY HH:MM') : 'Ingen leverandør ordre fundet, du kan kun sende til kunden på denne sag.'}

                          </Text>
                        )
                        }
                      </Flex>

                      <Flex align="center" mb="18px">
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                          Besked type:{" "}
                        </Text>
                        {(selectedOrder && selectedOrder.subcontractorOrders && selectedOrder.subcontractorOrders.length > 0) ? (
                          <Select onChange={(e) => { setSelectedMessageType(e.currentTarget.value) }} defaultValue={selectedMessageType}>
                            <option key={`messageType-1`} value={messageTypeEnum.NORMAL_MESSAGE}>Normal</option>
                            <option key={`messageType-2`} value={messageTypeEnum.SUBCONTRACTOR_ORDER_CANCEL}>Annullering af ordre</option>
                            <option key={`messageType-3`} value={messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION}>Korrektion til ordre</option>
                          </Select>
                        ) : (
                          <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"140px"}>
                          </Text>
                        )
                        }
                      </Flex>
                    </>
                  )}
                  <Flex align="center" mb="18px">
                    <div style={{ width: '100%' }}>
                      <MessageViewTextBar onSentMessageClicked={handleSentMessageClicked} orderId={selectedOrder.id} showSentButton={false} onMessageChanged={handleMessageChanged} MakeInlineStyle={true} />
                    </div>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Flex>
        </ModalBody >
        <ModalFooter>
          <Button onClick={CloseSentMessage}>Annuller</Button>
          {(isSubSupplierSelected() && selectedOrder.id) ? (
            <>
              {((selectedOrder && selectedOrder.subcontractorOrders && selectedOrder.subcontractorOrders.length > 0) || subcontractorOrder) && (
                <Button colorScheme='green' onClick={SentMessage} ml={3}>Send besked</Button>
              )}
            </>
          ) : (
            <>
              {selectedOrder.id && (
                <Button colorScheme='green' onClick={SentMessage} ml={3}>Send besked</Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalContent >
    </Modal >
  );
};

export default SentMessageDialog;
