import { Stack, InputGroup, InputLeftElement, Input, Divider } from "@chakra-ui/react";
import { FaSistrix } from "react-icons/fa";

import { useState } from "react";
import GlobalSearchResult from "./GlobalSearchResult";
import PropTypes from "prop-types";


const GlobalSearch = (props) => {
  const [searchStr, setsearchStr] = useState('');

  return (
    <>
      <Stack spacing={4}>
        <InputGroup autoFocus>
          <InputLeftElement
            pointerEvents='none'
            children={<FaSistrix color='gray.300' />}
          />
          <Input autoFocus type='text' placeholder='Søg på sagsnr eller adresse' onChange={(e) => { setsearchStr(e.target.value + '') }} />
        </InputGroup>
      </Stack>
      <Divider my={4} />

      {searchStr.length > 2 && ( 
      <GlobalSearchResult searchStr={searchStr + ''} orderClicked={(item) => {props.orderClicked(item)}} />
    )}
    </>
  );
};

GlobalSearchResult.propTypes = {
  orderClicked:  PropTypes.func,
};

export default GlobalSearch;
