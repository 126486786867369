// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Checkbox } from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "./../../../../../auth-context/auth.context";

const updatePhotographyCompanyInfoGql = gql`
  mutation UpdatePhotographyCompanyInput($input: UpdatePhotographyCompanyInput!) {
    updatePhotographyCompany(input: $input) {
      id
    }
  }
`;


const ProfileAddOns = ({ data, forceRerender }) => {
  const toast = useToast();
  const userData = useAuth()
  const [updateCustomerInfo] = useMutation(updatePhotographyCompanyInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (field, value) => {
    const dataObj = {}

    dataObj['id'] = data.id;
    dataObj[field] = value;

    updateCustomerInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        forceRerender();
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Forbrug
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>

          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Lagringsplads:{" "}
            </Text>
            <Text fontSize="md" color={textColor} >
              {data.usedStorageSpace} GB benyttet
            </Text>
          </Flex>

          {data.extraUsers > 0 && (

            <Flex align="center" mb="18px">
              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                Ekstra brugere (tilkøb):{" "}
              </Text>
              <Text fontSize="md" color={textColor} >
                {data.extraUsers}
              </Text>

            </Flex>
          )}
          {data.authers.length > 0 && (
            <Flex align="center" mb="18px">

              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                Tekstforfattere  (tilkøb):{" "}
              </Text>
              <Text fontSize="md" color={textColor} >
                {data.authers.length}
              </Text>

            </Flex>
          )}
          {data.mediaprocessors.length > 0 && (
            <Flex align="center" mb="18px">

              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                Media behandlere (tilkøb):{" "}
              </Text>
              <Text fontSize="md" color={textColor} >
                {data.mediaprocessors.length}
              </Text>

            </Flex>
          )}
          {(data.smsEnabled || ['ADMIN'].includes(userData.user.userType)) && (
            <Flex align="center" mb="18px">

              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                SMS service (tilkøb):{" "}
              </Text>
              <Checkbox
                size='lg' iconColor='gray.800' colorScheme="white"
                variant="flushed"
                type="chaeckbox"
                name="smsEnabled"
                disabled={!['ADMIN'].includes(userData.user.userType)}
                defaultChecked={data.smsEnabled}
                onChange={(e) => updateData('smsEnabled', e.currentTarget.checked)}
              />

            </Flex>
          )}
          {(['ADMIN'].includes(userData.user.userType)) && (
            <Flex align="center" mb="18px">
              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                Egen Mindworking aftale:{" "}
              </Text>
              <Checkbox
                size='lg' iconColor='gray.800' colorScheme="white"
                variant="flushed"
                type="chaeckbox"
                name="ownMindworkingAccountEnabled"
                disabled={!['ADMIN'].includes(userData.user.userType)}
                defaultChecked={data.ownMindworkingAccountEnabled}
                onChange={(e) => updateData('ownMindworkingAccountEnabled', e.currentTarget.checked)}
              />
            </Flex>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileAddOns;
