// Chakra imports
import React from "react";
import { FaRegEdit, FaRegWindowClose } from "react-icons/fa";
import './messageViewTextBar.css';
import ResizeTextarea from "react-textarea-autosize";
import { FaImage, FaSmile, FaPaperPlane } from "react-icons/fa";
import pickerData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import MessageViewImageSelector from "./messageViewImageSelector";
import MessageViewImageMarker from "./messageViewImageMarker";

interface IProps {
  onSentMessageClicked: (messageText: string, selectedMedias: any[]) => void;
  orderId: string;
  showSentButton: boolean;
  onMessageChanged? : (messageText: string, selectedMedias: any[]) => void;
  MakeInlineStyle: boolean;
}

const MessageViewTextBar: React.FC<IProps> = ({ orderId, showSentButton, onSentMessageClicked, onMessageChanged, MakeInlineStyle }) => {
  const [emojiPopupVisible, setEmojiPopupVisible] = React.useState(false);
  const [showMessageImageSelector, setShowMessageImageSelector] = React.useState(false);
  const [showMessageImageMarker, setShowMessageImageMarker] = React.useState(false);
  const [ImageMarkerMedia, setImageMarkerMedia] = React.useState(null);
  const [selectedMedias, setSelectedMedias] = React.useState([]);
  const textAreaRef = React.useRef(null);

  const handleSentMessageClicked = () => {
    if (textAreaRef.current.value === '') {
      return;
    }
    onSentMessageClicked(textAreaRef.current.value, selectedMedias);
    textAreaRef.current.value = '';
  }

  const handleMessageChanged = () => {
    if (textAreaRef.current.value === '') {
      return;
    }
    if (onMessageChanged) {
      onMessageChanged(textAreaRef.current.value, selectedMedias);
    }
  }

  const handleShowEmojiPopup = () => {
    emojiPopupVisible ? setEmojiPopupVisible(false) : setEmojiPopupVisible(true);
  }

  const handleShowImageSelector = () => {
    showMessageImageSelector ? setShowMessageImageSelector(false) : setShowMessageImageSelector(true);
  }

  const handleShowImageMarker = (mediaId: string) => {
    let selectedMedia = selectedMedias.find((media) => media.id === mediaId);
    if (selectedMedia.markerOverLay) {
      //remove markerOverlay from obj
      selectedMedia = { ...selectedMedia };
      delete selectedMedia.markerOverLay;

    }

    setImageMarkerMedia(selectedMedia);
    setShowMessageImageMarker(true);
  }

  const handleShowMediaOverlay = (mediaId: string) => {
    setImageMarkerMedia(selectedMedias.find((media) => media.id === mediaId));
    setShowMessageImageMarker(true);
  }

  const AddMediaToMessage = (medias: []) => {
    setShowMessageImageSelector(false)

    //loop medias, and look for .media if found convert to media obj
    medias = medias.map((media: any) => {
      if (media.media) {
        let newMedia = {...media.media}
        newMedia.markerOverLay = media.markerOverLay;
        return newMedia;
      }
      return media;
    }) as any;

    setSelectedMedias(medias);

    if (onMessageChanged) {
      onMessageChanged(textAreaRef.current.value, medias);
    }
  }

  const UpdateMediaInMessage = (media: any) => {

    setShowMessageImageMarker(false);

    if (media === null) {
      return;
    }

    const newSelectedMedias = selectedMedias.map((selectedMedia) => {
      if (selectedMedia.id === media.id) {
        return media;
      }
      return selectedMedia;
    });
    setSelectedMedias(newSelectedMedias);
    handleMessageChanged();
  }

  const handleRemoveSelectedMedia = (mediaId: string) => {
    const newSelectedMedias = selectedMedias.filter((media) => media.id !== mediaId);
    setSelectedMedias(newSelectedMedias);
    if (onMessageChanged) {
      onMessageChanged(textAreaRef.current.value, newSelectedMedias);
    }
  }

  const handleEmojiSelect = (emoji: any) => {
    setEmojiPopupVisible(false);

    //insert emoji into text area
    const textArea = textAreaRef.current;
    const cursorPosition = textArea.selectionStart;
    const text = textArea.value;
    const textBefore = text.substring(0, cursorPosition);
    const textAfter = text.substring(cursorPosition, text.length);
    textArea.value = textBefore + emoji.native + textAfter;

    handleMessageChanged();
  }

  let messageViewTextBarContainerClass = 'messageViewTextBarContainer';
  let messageViewTextBarClass = 'messageViewTextBar';
  if (MakeInlineStyle) {
    messageViewTextBarClass = 'messageViewTextBarInline';
    messageViewTextBarContainerClass = 'messageViewTextBarContainerInline';
  }

  return (
    <>
      {emojiPopupVisible && (
        <div className="emojiPickerContainer">
          <Picker data={pickerData}
            previewPosition="none"
            noCountryFlags={true}
            onEmojiSelect={handleEmojiSelect}
            i18n={
              {
                "search": "Søg",
                "search_no_results_1": "Øh nej!",
                "search_no_results_2": "Den emoji kunne ikke findes",
                "pick": "Vælg en emoji…",
                "add_custom": "Add custom emoji",
                "categories": {
                  "activity": "Aktivitet",
                  "custom": "Custom",
                  "flags": "Flag",
                  "foods": "Mad og drikke",
                  "frequent": "Seneste",
                  "nature": "Dyr og natur",
                  "objects": "Objekter",
                  "people": "Smilende ansigter og personer",
                  "places": "Rejse og steder",
                  "search": "Søge resultater",
                  "symbols": "Symboler"
                },
                "skins": {
                  "choose": "Choose default skin tone",
                  "1": "Default",
                  "2": "Light",
                  "3": "Medium-Light",
                  "4": "Medium",
                  "5": "Medium-Dark",
                  "6": "Dark"
                }
              }
            } />
        </div>
      )}

      {orderId && (
        <MessageViewImageSelector orderId={orderId} onClose={AddMediaToMessage} isVisible={showMessageImageSelector} />
      )}
      <MessageViewImageMarker media={ImageMarkerMedia} onClose={UpdateMediaInMessage} isVisible={showMessageImageMarker} />
      <div className={messageViewTextBarClass}>
        <div className={messageViewTextBarContainerClass}>
          {orderId && (
            <div className="messageViewTextBarImageIcon">
              <FaImage style={{ width: '20px', height: '20px' }} onClick={handleShowImageSelector} />
            </div>
          )}
          <div className="messageViewTextBarInputContainer">
            {selectedMedias.length > 0 && (
              <div className="messageViewTextBarImageContainer">
                {selectedMedias.map((media) => (
          
                    <div className="selectedMediaContainer" key={'mediaThumbnail_' + media.id}>
                      <img onClick={() => { handleShowMediaOverlay(media.id) }} className="selectedMediaContainerImage" src={media.thumpnailUrl} alt={media.name} />
                      <div className="selectedMediaButtonContainer">
                        <div className="selectedMediaButton" onClick={() => { handleRemoveSelectedMedia(media.id) }}>
                          <FaRegWindowClose style={{ width: '20px', height: '20px' }} />
                        </div>
                        <div className="selectedMediaButton" onClick={() => { handleShowImageMarker(media.id) }}>
                          <FaRegEdit style={{ width: '20px', height: '20px' }} />
                        </div>
                      </div>
                      {media.markerOverLay && (
                        <div onClick={() => { handleShowMediaOverlay(media.id) }} className="mediaOverlay" dangerouslySetInnerHTML={{ __html: media.markerOverLay }}></div>
                      )}
                    </div>
              
                ))}
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ResizeTextarea ref={textAreaRef} placeholder="Aa" className="messageViewTextBarInput" onKeyUp={handleMessageChanged} />
              <div className="messageViewTextBarInputSmiles" onClick={handleShowEmojiPopup}>
                <FaSmile style={{ width: '20px', height: '20px' }} />
              </div>
            </div>
          </div>
          {showSentButton && (
            <div className="messageViewTextBarSend" onClick={handleSentMessageClicked}>
              <FaPaperPlane style={{ width: '20px', height: '20px' }} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MessageViewTextBar;
