import {
    Flex,
    useColorModeValue,
    GridItem,
    Grid,
    Card,
    CardBody,
    CardFooter,
    Stack,
    Text,
    Heading,
    Image,
    FormControl,
    FormLabel,
    Switch,
    Button
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import MindworkingLogoLight from "./../../../../assets/img/Mindworking-logo-light.svg";
import leadHub_logo from "./../../../../assets/img/leadHub_logo.png";
import cb_logo from "./../../../../assets/img/cb_logo.png";
import dinero_logo from "./../../../../assets/img/dinero_logo.jpg";
import eConomic_logo from "./../../../../assets/img/eConomic_logo.png";
import SettingsHeader from "../components/SettingsHeader";
import './integrations.css';
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

const INTEGRATIONS_GQL = gql`
query getPhotographyCompany {
    getPhotographyCompany {
        id
        integrations {
            id
            integrationType
        }
    }
}`

const CREATE_INTEGRATION_GQL = gql`
mutation createIntegration($input: CreateIntegrationInput!){
    createIntegration(input:$input){
        id
    }
}`

const DELETE_INTEGRATION_GQL = gql`
mutation deleteIntegration($id: ID!){
    deleteIntegration(id:$id)
}`

function Integrations() {
    const history = useHistory();
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [createIntegration] = useMutation(
        CREATE_INTEGRATION_GQL,
        {
            refetchQueries: [INTEGRATIONS_GQL],
        }
    )

    const [deleteIntegration] = useMutation(
        DELETE_INTEGRATION_GQL,
        {
            refetchQueries: [INTEGRATIONS_GQL],
        }
    )

    const { loading, error, data } = useQuery(INTEGRATIONS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });


    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting integrations from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting integrations from server (2)</p>);
    }

    const isIntegrationActive = (integrationType: string) => {
        return data.getPhotographyCompany.integrations.find((integration: any) => integration.integrationType === integrationType) !== undefined;
    }

    const handleIntegrationChanged = (event: any, integrationType: string) => {
        if (event.target.checked) {
            createIntegration({
                variables: {
                    input: {
                        integrationType: integrationType
                    }
                }
            });
        } else {
            deleteIntegration({
                variables: {
                    id: data.getPhotographyCompany.integrations.find((integration: any) => integration.integrationType === integrationType).id
                }
            });
        }
    }

    const handleIntegrationSettingsClicked = (integrationType: string) => {

        switch (integrationType) {
            case 'MINDWORKING':
                history.push('/admin/setting/integration/mindworking');
                break;
            case 'LEADHUB':
                history.push('/admin/setting/integration/leadhub');
                break;
            case 'CB':
                history.push('/admin/setting/integration/cb');
                break;
            case 'DINERO':
                history.push('/admin/setting/integration/dinero');
                break;
            case 'ECONOMIC':
                history.push('/admin/setting/integration/economic');
                break;
            default:
                break;
        }

    }

    return (
        <>
            <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
                <SettingsHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Integrationer"}
                    description={'Tag et kig og aktivere vores integrationer, og se hvordan de kan hjælpe dig i din hverdag.'}
                    showCreateUserButton={false}
                    onCreateUserClicked={() => { }}
                    showBackButton={false}
                    showUserSentWellcomesMessageButton={false}
                    onSentWellcomesMessageClicked={() => { }}
                />
                <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px" my="26px" mb={{ lg: "26px" }}>
                    <GridItem colSpan={1} rowSpan={1}>
                        <Card maxW='sm'>
                            <CardBody>

                                <div className="loginImageContainer" >
                                    <Image
                                        src={MindworkingLogoLight}
                                        alt='Mindworking'
                                        borderRadius='lg'
                                    />
                                </div>
                                <Stack mt='6' spacing='3'>
                                    <Heading size='md'>Mindworking</Heading>
                                    <Text height={'80px'}>
                                        Overfør nemt dine billeder, plantegninger og videoer direkte til Mindworking.
                                        Intregrationen understøtter også sortering og beskrivelsen af dine medier.
                                    </Text>
                                </Stack>

                            </CardBody>
                            <CardFooter alignContent={'center'} alignItems={'center'} height={'100px'}>
                                {isIntegrationActive('MINDWORKING') && (
                                    <Button variant={"outline"} width={'150px'} onClick={() => { handleIntegrationSettingsClicked('MINDWORKING') }}>
                                        Indstillinger
                                    </Button>
                                )}
                                <FormControl display='flex' alignItems='right' justifyContent='flex-end'>
                                    <FormLabel htmlFor='activateMindworking' mb='0'>
                                        Aktiv
                                    </FormLabel>
                                    <Switch id='activateMindworking' defaultChecked={isIntegrationActive('MINDWORKING')} onChange={(e) => { handleIntegrationChanged(e, 'MINDWORKING') }} />
                                </FormControl>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem colSpan={1} rowSpan={1}>
                        <Card maxW='sm'>
                            <CardBody>
                                <div className="loginImageContainer" style={{ background: 'rgb(123 123 123)' }} >
                                    <Image
                                        src={leadHub_logo}
                                        alt='LeadHub'
                                        maxWidth={'200px'}
                                    />
                                </div>
                                <Stack mt='6' spacing='3'>
                                    <Heading size='md'>LeadHub</Heading>
                                    <Text height={'80px'}>
                                        Med integrationen til LeadHub, kan du nemt overføre dine billeder og videoer til dem af dine kunder som benytter LeadHub.
                                    </Text>
                                </Stack>
                            </CardBody>
                            <CardFooter alignContent={'center'} alignItems={'center'} height={'100px'}>
                                {isIntegrationActive('LEADHUB') && (
                                    <Button variant={"outline"} width={'150px'} onClick={() => { handleIntegrationSettingsClicked('LEADHUB') }}>
                                        Indstillinger
                                    </Button>
                                )}
                                <FormControl display='flex' alignItems='right' justifyContent='flex-end'>
                                    <FormLabel htmlFor='activateLeadHub' mb='0'>
                                        Aktiv
                                    </FormLabel>
                                    <Switch id='activateLeadHub' defaultChecked={isIntegrationActive('LEADHUB')} onChange={(e) => { handleIntegrationChanged(e, 'LEADHUB') }} />
                                </FormControl>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    {/*}
                    <GridItem colSpan={1} rowSpan={1}>
                        <Card maxW='sm'>
                            <CardBody>
                                <div className="loginImageContainer" style={{ padding: '40px' }}>
                                    <Image
                                        src={cb_logo}
                                        alt='C&B'
                                        maxWidth={'150px'}
                                    />
                                </div>
                                <Stack mt='6' spacing='3' >
                                    <Heading size='md'>C&B</Heading>
                                    <Text height={'80px'}>
                                        Hjælp dine kunder som benytter C&B, med at få billeder overført direkte til dem.
                                        Integrationen understøtter også sortering og beskrivelsen af dine medier.

                                    </Text>
                                </Stack>
                            </CardBody>
                            <CardFooter alignContent={'center'} alignItems={'center'} height={'100px'}>
                                {isIntegrationActive('CB') && (
                                    <Button variant={"outline"} width={'150px'} onClick={() => { handleIntegrationSettingsClicked('CB') }}>
                                        Indstillinger
                                    </Button>
                                )}
                                <FormControl display='flex' alignItems='right' justifyContent='flex-end'>
                                    <FormLabel htmlFor='activateCB' mb='0'>
                                        Aktiv
                                    </FormLabel>
                                    <Switch id='activateCB' defaultChecked={isIntegrationActive('CB')} onChange={(e) => { handleIntegrationChanged(e, 'CB') }} />
                                </FormControl>
                            </CardFooter>
                        </Card>
                    </GridItem>
{*/}

                    <GridItem colSpan={1} rowSpan={1}>
                        <Card maxW='sm'>
                            <CardBody>
                                <div className="loginImageContainer" style={{ padding: '40px' }}>
                                    <Image
                                        src={dinero_logo}
                                        alt='Dinero'
                                        maxWidth={'270px'}
                                    />
                                </div>
                                <Stack mt='6' spacing='3' >
                                    <Heading size='md'>Dinero</Heading>
                                    <Text height={'80px'}>
                                        Gør afslutningen på måneden lidt lettere. Med integrationen til Dinero kan du nemt og automatisk oprette dine faktuaraer.<br />
                                        (Kræver Dinero Pro abo.)
                                    </Text>
                                </Stack>
                            </CardBody>
                            <CardFooter alignContent={'center'} alignItems={'center'} height={'100px'}>
                                {isIntegrationActive('DINERO') && (
                                    <Button variant={"outline"} width={'150px'} onClick={() => { handleIntegrationSettingsClicked('DINERO') }}>
                                        Indstillinger
                                    </Button>
                                )}
                                <FormControl display='flex' alignItems='right' justifyContent='flex-end'>
                                    <FormLabel htmlFor='activateDINERO' mb='0'>
                                        Aktiv
                                    </FormLabel>
                                    <Switch id='activateDINERO' defaultChecked={isIntegrationActive('DINERO')} onChange={(e) => { handleIntegrationChanged(e, 'DINERO') }} />
                                </FormControl>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    
                    <GridItem colSpan={1} rowSpan={1}>
                        <Card maxW='sm'>
                            <CardBody>
                                <div className="loginImageContainer" style={{ padding: '40px' }}>
                                    <Image
                                        src={eConomic_logo}
                                        alt='eConomic'
                                        maxWidth={'270px'}
                                    />
                                </div>
                                <Stack mt='6' spacing='3' >
                                    <Heading size='md'>e-Conomic</Heading>
                                    <Text height={'80px'}>
                                        Med e-conomic integrationen kan du nemt og automatisk oprette dine faktuaraer direkte fra Magna Multi.<br />
                                    </Text>
                                </Stack>
                            </CardBody>
                            <CardFooter alignContent={'center'} alignItems={'center'} height={'100px'}>
                                {isIntegrationActive('ECONOMIC') && (
                                    <Button variant={"outline"} width={'150px'} onClick={() => { handleIntegrationSettingsClicked('ECONOMIC') }}>
                                        Indstillinger
                                    </Button>
                                )}
                                <FormControl display='flex' alignItems='right' justifyContent='flex-end'>
                                    <FormLabel htmlFor='activateECONOMIC' mb='0'>
                                        Aktiv
                                    </FormLabel>
                                    <Switch id='activateECONOMIC' defaultChecked={isIntegrationActive('ECONOMIC')} onChange={(e) => { handleIntegrationChanged(e, 'ECONOMIC') }} />
                                </FormControl>
                            </CardFooter>
                        </Card>
                    </GridItem>
              
                </Grid>
            </Flex>
        </>
    );
}

export default Integrations;