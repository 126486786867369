// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  Textarea,
  Input,
  useToast,
  Button
} from "@chakra-ui/react";

import ResizeTextarea from "react-textarea-autosize";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import OrderHeader from "./../components/orderHeader"
import { FaPaperPlane } from "react-icons/fa";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { useState } from "react";
import TextOrderDialog from "../components/textOrderDialog";
import { SubcontractorType } from "../../../../types";
import moment from "moment";
import { useAuth } from "../../../../auth-context/auth.context";
import CharCounter from "../components/charCounter";
import { useRef, useEffect } from 'react';
import SentMessageDialog, {messageTypeEnum} from "../../Messages/components/sentMessageDialog";

const GET_ORDER = gql`
  query order($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      texts {
        id
        name
        sorting
        text
        section {
          name
          textType
          textLength
        }
      }
      textHeading
      textSmall
      textMedium
      textLarge
      textSoMe
      textWebsite
      subcontractorOrders {
        id
        subcontractor {
          id
          name
          subcontractorType
          isGlobal
          language
        }
        orderUiUrl
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const UPDATE_TEXT_GQL = gql`
  mutation updateText($input: UpdateTextInput!) {
    updateText(input: $input) {
      id
    }
  }
`;

function OrderText() {
  const { orderId } = useParams() as any;
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const [updateTextOnServer] = useMutation(UPDATE_TEXT_GQL);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [subcontractorOrder, setSubcontractorOrder] = useState(null);
  const [showOrderTextDialog, setShowOrderTextDialog] = useState(false);
  const [updateState, setUpdateState] = useState(null);
  const [currentMessageType, setCurrentMessageType] = useState<messageTypeEnum>(messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION);
  const userData = useAuth()
  const textFieldRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  //hack to init text counters
  useEffect(() => {
    setTimeout(() => {
      if (!updateState){
        setUpdateState(true);
      }
    },1000);
}, [updateState]);
  
  const { loading, error, data, refetch } = useQuery(
    GET_ORDER,
    {
      variables: { id: orderId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

  if (loading) return <p></p>; //loading
  if (error) {
    return <p>Error getting order from server (1)</p>; //TODO: make error component
  }
  if (!data) {
    return <p>Error getting order from server (2)</p>;
  }

  const textOrders = data.getOrder.subcontractorOrders.filter((order) => order.subcontractor.subcontractorType === SubcontractorType.AUTHER);

  const updateText = (field, value) => {
    const fieldId = field.split('_')[2];

    updateTextOnServer({ variables: { input: { id: fieldId, text: value } } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  }

  const updateData = (field, value) => {
    const dataObj = {}

    dataObj['id'] = data.getOrder.id;
    dataObj[field] = value;

    updateOrderInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const handleCancelOrder = (subcontractorOrder) => {
    setSubcontractorOrder(subcontractorOrder);
    setCurrentMessageType(messageTypeEnum.SUBCONTRACTOR_ORDER_CANCEL);
    setShowMessageDialog(true);
  }

  const handleOpenUrl = (url) => {
    window.open(url, '_blank');
  }

  const allowSubcontractorLogin = (subcontractor) => {
    if (!subcontractor.isGlobal) {
      return true;
    }
    if (['ADMIN'].includes(userData.user.userType)) {
      return true;
    }

    return false;
  }


  const orderStatusText = (order) => {
    switch (order.status) {
      case 'SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt tekster hos <b>{order.subcontractor.name}</b> d. {moment(order.createdAt).format('DD-MM-YYYY')}. Teksterne er IKKE leveret endnu.</p>
          <div style={{ display: 'display', gap: '10px' }}>
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" mr="10px" width={'160px'} onClick={() => { handleCancelOrder(order) }}>Annuller ordren</Button>
            {allowSubcontractorLogin(order.subcontractor) && (
              <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
            )}
          </div>
        </div>);
      case 'DOWNLOADED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil tekster hos <b>{order.subcontractor.name}</b>, som åbnet ordren.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'CORRECTION_SENT':
        return (<div className="subcontractorOrderStatusText"><p>Der er sendt en korrektion til <b>{order.subcontractor.name}</b>.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'COMPLETED':
        return (<div className="subcontractorOrderStatusText"><p>Der er bestilt tekster hos <b>{order.subcontractor.name}</b>, tekterne er leveret d. {moment(order.updatedAt).format('DD-MM-YYYY')}.</p>
          {order.subcontractor.id !== process.env.REACT_APP_ESTATEPITCH_SUBCONTRACTOR_ID && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'200px'} onClick={() => { setSubcontractorOrder(order); setCurrentMessageType(messageTypeEnum.SUBCONTRACTOR_ORDER_CORRECTION); setShowMessageDialog(true) }}>Send besked ang. korrektur</Button>
          )}
        </div>);
      case 'REJECTED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil tekster hos <b>{order.subcontractor.name} </b>, som har afvist ordren.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      case 'CANCELED':
        return (<div className="subcontractorOrderStatusText"><p>D. {moment(order.createdAt).format('DD-MM-YYYY')} blev der bestil tekster hos <b>{order.subcontractor.name} </b>, ordren er annulleret.</p>
          {allowSubcontractorLogin(order.subcontractor) && (
            <Button colorScheme="#a4a4a4" variant="outline" size="sm" width={'160px'} onClick={() => { handleOpenUrl(order.orderUiUrl) }}>Login som leverandør</Button>
          )}
        </div>);
      default:
        return (<div className="subcontractorOrderStatusText"></div>);
    }
  }

  return (
    <>
      <SentMessageDialog key={'OrderSentMessage'} isVisible={subcontractorOrder && showMessageDialog} onClose={() => { setShowMessageDialog(false); refetch(); }} order={data.getOrder} subcontractorOrder={subcontractorOrder} messageType={currentMessageType} />

      <Flex direction="column"  pt={{ base: "20px", md: "35px" }}>
        <OrderHeader
          name={"Ordre - " + data.getOrder.caseNo + ' - ' + data.getOrder.estateAddress + ' - ' + data.getOrder.estateZipCode + ' ' + data.getOrder.estateCity}
          description={'Tekster'}
          onMediaButtonClicked={() => { }}
          onTextButtonClicked={() => { }}
          showMediaButton={false}
          showTextButton={false}
          showBackButton={true}
          customButtonShow={true}
          customButtonIcon={FaPaperPlane}
          customButtonOnClick={() => { setShowOrderTextDialog(true); }}
          customButtonText={"Bestil tekster"}
          onMessagesButtonClicked={() => { }}
          showMessagesButton={false}
          unReadMessages={0}
        />
        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="22px"
        >
          <GridItem w="100%" colSpan={4} rowSpan={2}>
            {textOrders.length > 0 && (
              <Card p="16px" bg="#efefef">
                <CardHeader p="12px 5px" mb="12px" style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  <Text fontSize="lg" color={textColor} fontWeight="bold"  >
                    Bestilte tekster
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    {textOrders.map((order) => (
                      <Flex align="center" mb="18px" key={order.id}>
                        <Text fontSize="md" color={textColor} width={"100%"} minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                          {orderStatusText(order)}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </CardBody>
              </Card>
            )}
          </GridItem>
          <GridItem w="100%" colSpan={4} rowSpan={2}>
            <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
              <CardHeader p="12px 5px" mb="12px" style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'column' }}>
                <Text fontSize="lg" color={textColor} fontWeight="bold"  >
                  Tekster
                </Text>
              </CardHeader>
              <CardBody px="5px">
                {data.getOrder.texts.length > 0 ? (
                  <Flex direction="column" style={{ width: "100%" }}>
                    {data.getOrder.texts.map((text, index) => {
                      // Initialize the ref for each text item
                      if (!textFieldRefs.current[index]) {
                        textFieldRefs.current[index] = null;
                      }
                      return (
                        <Flex align="center" mb="18px" key={text.id}>

                          {text.section ? (
                            <Text fontSize="md" color={textColor} minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                              {text.section.name} ({text.section.textLength} tegn):{" "}
                            </Text>
                          ) : (
                            <Text fontSize="md" color={textColor} minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                              {text.name} :{" "}
                            </Text>
                          )}

                          <Flex align="center" mb="18px" flexDirection={'column'} alignContent={'flex-end'} alignItems={'flex-end'} width={'100%'} >
                            {text.section && (
                              <CharCounter textFieldRef={{ current: textFieldRefs.current[index] }} maxChars={text.section.textLength} />
                            )}
                            <Textarea ref={(el) => (textFieldRefs.current[index] = el)} as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name={'text_id_' + text.id} defaultValue={text.text} onBlur={(e) => updateText('text_id_' + text.id, e.target.value)} />
                          </Flex>
                        </Flex>
                      )
                    })}
                  </Flex>
                ) : (
                  <Flex direction="column" style={{ width: "100%" }}>
                    <Flex align="center" mb="18px">
                      <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                        Overskrift:{" "}
                      </Text>
                      <Input
                        variant="outline"
                        type="text"
                        name="textHeading"
                        defaultValue={data.getOrder.textHeading}
                        onBlur={(e) => updateData('textHeading', e.target.value)}
                      />
                    </Flex>
                    <Flex direction='column' style={{ width: '100%' }}>
                      <Flex align='center' mb='18px'>
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"} height={'100%'}>
                          Tekst - lille:{" "}
                        </Text>
                        <Textarea as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name="textSmall" defaultValue={data.getOrder.textSmall} onBlur={(e) => updateData('textSmall', e.target.value)} />
                      </Flex>
                    </Flex>
                    <Flex direction='column' style={{ width: '100%' }}>
                      <Flex align='center' mb='18px'>
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"} height={'100%'}>
                          Tekst - mellem:{" "}
                        </Text>
                        <Textarea as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name="textMedium" defaultValue={data.getOrder.textMedium} onBlur={(e) => updateData('textMedium', e.target.value)} />
                      </Flex>
                    </Flex>
                    <Flex direction='column' style={{ width: '100%' }}>
                      <Flex align='center' mb='18px'>
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"} height={'100%'}>
                          Tekst - lang:{" "}
                        </Text>
                        <Textarea as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name="textLarge" defaultValue={data.getOrder.textLarge} onBlur={(e) => updateData('textLarge', e.target.value)} />
                      </Flex>
                    </Flex>
                    <Flex direction='column' style={{ width: '100%' }}>
                      <Flex align='center' mb='18px'>
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"} height={'100%'}>
                          Tekst - SoMe:{" "}
                        </Text>
                        <Textarea as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name="textSoMe" defaultValue={data.getOrder.textSoMe} onBlur={(e) => updateData('textSoMe', e.target.value)} />
                      </Flex>
                    </Flex>
                    <Flex direction='column' style={{ width: '100%' }}>
                      <Flex align='center' mb='18px'>
                        <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"130px"} height={'100%'}>
                          Tekst - Website:{" "}
                        </Text>
                        <Textarea as={ResizeTextarea} overflow="hidden" padding={2} paddingLeft={'18px'} variant="outline" name="textWebsite" defaultValue={data.getOrder.textWebsite} onBlur={(e) => updateData('textWebsite', e.target.value)} />
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </Flex>
      <TextOrderDialog isOpen={showOrderTextDialog} onClose={() => { setShowOrderTextDialog(false); refetch(); }} orderId={data.getOrder.id} />
    </>
  );
}
export default OrderText;