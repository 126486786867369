// Chakra imports
import {
    Flex, useColorModeValue, Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    Select,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../components/SettingsHeader";
import React from "react";
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import SettleTable from "./../../../../components/Tables/SettleTable";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useAuth } from "./../../../../auth-context/auth.context";
import moment from "moment";

const USERS_GQL = gql`
query users {
    users {
        id
        name
        email
        userType
        createdAt
    }
}`

const DELETE_USER_GQL = gql`
mutation deleteUser($id: ID!){
    deleteUser(id:$id)
}`

const CREATE_USER_GQL = gql`
mutation createUser($input: CreateUserInput!){
    createUser(input:$input){
        id
    }
}`

enum UserType { // eslint-disable-line
    ADMIN = "ADMIN", // eslint-disable-line
    PHOTOGRAPHY_COMPANY_ADMIN = "PHOTOGRAPHY_COMPANY_ADMIN", // eslint-disable-line
    PHOTOGRAPHER = "PHOTOGRAPHER", // eslint-disable-line
}

const CreateUserObj = {
    name: '' as String,
    phoneNo: '' as String,
    email: '' as String,
    userType: 'PHOTOGRAPHER' as UserType
}

function Users() {
    const toast = useToast()
    const userData = useAuth()
    const history = useHistory();
    const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
    const [validate, setValidate] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState('');

    const [createUserData, setCreateUserData] = React.useState(CreateUserObj);
    const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
    const { isOpen: createUserIsOpen, onOpen: createUserOnOpen, onClose: createUserOnClose } = useDisclosure();

    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [deleteUser] = useMutation(
        DELETE_USER_GQL,
        {
            refetchQueries: [USERS_GQL]
        }
    )

    const [createNewUser] = useMutation(
        CREATE_USER_GQL,
        {
            refetchQueries: [USERS_GQL],
            variables: {
                userId: localStorage.getItem("system_user_id"),
            },
        }
    )

    const { loading, error, data } = useQuery(USERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting users from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting users from server (2)</p>);
    }

    const deleteUserClicked = (obj) => {
        setSelectedUserId(obj.id)
        deleteWarningOnOpen();
    }

    const deleteUserConfirmed = () => {
        deleteUser({ variables: { id: selectedUserId } } as any);
        deleteWarningOnClose();
    }

    const columns = [
        {
            header: "",
            accessor: "id",
            hidden: true
        },
        {
            header: "Oprettet",
            accessor: "createdAt",
            onClick: rowClicked
        },
        {
            header: "Navn",
            accessor: "name",
            onClick: rowClicked
        },
        {
            header: "E-mail",
            accessor: "email",
            onClick: rowClicked
        },
        {
            header: "Rolle",
            accessor: "typeText",
            onClick: rowClicked
        },
        {
            header: "Slet",
            icon: "delete",
            alignHeader: "center",
            alignData: "right",
            width: 100,
            accessor: "delete",
            onClick: deleteUserClicked,
        }
    ];

    function rowClicked(obj) {
        history.push('/admin/setting/user/' + obj.id);
    }

    const createUser = () => {
        if (CreateUserObj.name === '' || CreateUserObj.email === '' || CreateUserObj.phoneNo === '') {
            setValidate(true)
        } else {
            createNewUser({
                variables: { input: CreateUserObj } as any
            }).then(() => {
                createUserOnClose()
            }).catch((() => {
                toast({
                    title: 'Opret bruger fejlede.',
                    description: "Det var ikke muligt at oprette brugeren.",
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            })
            );
        }
    }

    //create obj for table component
    let tableData = JSON.parse(JSON.stringify(data.users));

    for (var i = 0; i < tableData.length; i++) {
        tableData[i].createdAt = moment(tableData[i].createdAt).format('DD-MM-YYYY');

        switch (tableData[i].userType) {
            case 'ADMIN':
                tableData[i].typeText = 'System administrator'
                break;
            case 'PHOTOGRAPHY_COMPANY_ADMIN':
                tableData[i].typeText = 'Fotograf (admin)'
                break;
            case 'PHOTOGRAPHER':
                tableData[i].typeText = 'Fotograf'
                break;
            default:
                break;
        }
    }

    if (userData.user.userType !== 'ADMIN') {
        //filter out ADMIN users
        tableData = tableData.filter((obj) => obj.userType !== 'ADMIN')
    }

    const createUserClicked = () => {
        //CreateUserObj.photographyCompanyId = '';
        CreateUserObj.name = '';
        CreateUserObj.phoneNo = '';
        CreateUserObj.email = '';
        CreateUserObj.userType = UserType.PHOTOGRAPHER
        setCreateUserData(CreateUserObj)
        setValidate(false)
        createUserOnOpen();
    }

    const updateData = (key, value) => {
        CreateUserObj[key] = value;
        setCreateUserData(CreateUserObj)
        setValidate(false)
    }

    return (
        <div>
            <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
                <SettingsHeader
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Brugere"}
                    description={'Opret og rediger brugere i systemet'}
                    showCreateUserButton={false}
                    onCreateUserClicked={createUserClicked}
                    showBackButton={false}
                    showUserSentWellcomesMessageButton={false}
                    onSentWellcomesMessageClicked={() => { }}
                />

                <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                    <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">

                        <Flex direction="column">
                            <Text fontSize="lg" color={textColor} fontWeight="bold">
                                Brugere
                            </Text>
                        </Flex>
                        <Flex direction="column">
                            <Text
                                color={textColor}
                                fontSize="12px"
                                fontWeight="bold"
                                mb="6px"
                                cursor="pointer"
                                onClick={() => {
                                    createUserClicked();
                                }}
                            >
                                Opret ny bruger
                            </Text>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <SettleTable data={tableData} columnsData={columns} setDragData={false} csvData={[]} enableSelect={false} />
                    </CardBody>
                </Card>
            </Flex>


            <Modal onClose={createUserOnClose} size="xl" isOpen={createUserIsOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Opret ny bruger</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Du er ved at oprettet en ny bruger, udfyld nedenstående og klik opret.</Text>
                        <br /><br />
                        <Text fontSize='md' fontWeight='bold'>Bruger:</Text>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Type:{" "}
                                </Text>
                                <Select multiple={false} onChange={(e) => { updateData('userType', e.target.value) }}>
                                    <option key={'PHOTOGRAPHY_COMPANY_ADMIN'} value={'PHOTOGRAPHY_COMPANY_ADMIN'}>Fotograf administator</option>
                                    <option key={'PHOTOGRAPHER'} value={'PHOTOGRAPHER'}>Fotograf</option>
                                </Select>
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Navn:{" "}
                                </Text>
                                <Input

                                    variant='flushed'
                                    type='text'
                                    name="name"
                                    onKeyUp={(e) => { updateData('name', e.currentTarget.value) }}
                                    className={validate && createUserData.name === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    E-mail:{" "}
                                </Text>
                                <Input

                                    variant='flushed'
                                    type='text'
                                    name="email"
                                    onKeyUp={(e) => { updateData('email', e.currentTarget.value) }}
                                    className={validate && createUserData.email === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>
                        <Flex direction='column' style={{ width: '100%' }}>
                            <Flex align='center' mb='18px'>
                                <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                    Telefon nr.:{" "}
                                </Text>
                                <Input

                                    variant='flushed'
                                    type='text'
                                    name="phoneNo"
                                    onKeyUp={(e) => { updateData('phoneNo', e.currentTarget.value) }}
                                    className={validate && createUserData.phoneNo === '' ? 'error' : ''}
                                />
                            </Flex>
                        </Flex>



                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createUserOnClose}>Fortryd</Button>
                        <Button colorScheme='green' onClick={createUser} ml={3}>Opret</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slet bruger
                        </AlertDialogHeader>

                        <AlertDialogBody>Du er ved at slette en bruger. <br />Er du sikker?</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={deleteWarningOnClose}>
                                Nej, behold bruger
                            </Button>
                            <Button colorScheme="red" onClick={deleteUserConfirmed} ml={3}>
                                Ja, jeg er sikker
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </div>
    );
}

export default Users;
