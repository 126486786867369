// Chakra imports
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import './messageView.css';
import MessageViewEmpty from "./messageViewEmpty";
import moment from "moment";
import MessageViewTextBar from "./messageViewTextBar";
import MessageViewImageMarker from "./messageViewImageMarker";
import { useHistory } from "react-router-dom";

const GET_MESSAGE_GQL = gql`
query getMessageTreads($threadId: ID!){
  getMessageTreads(threadId: $threadId){
    id
    threadId
    messageSubject
    messageText
    messageSender
    dateRead
    createdAt
    medias {
      id
      markerOverLay
      media {
        id
        filename
        thumpnailUrl
        mediaType
      }
    } 
    subcontractorOrder {
      id
      subcontractor {
        id
        name
      }
    }
    customer {
      id
      name
    }
    subcontractor {
      id
      name
    }
    photographyCompany {
      id
      name
    }
    order {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
    }
  }
}`;

const SENT_MESSAGE_GQL = gql`
  mutation sentInternalMessage($input: SentMessageInput!) {
    sentInternalMessage(input: $input) {
      id
    }
  }
`;


export enum MessageViewFilter {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  READ = 'READ',
}

interface IProps {
  fotographerUserIds: [String] | null
  threadId: String
  filter: MessageViewFilter
}

const MessageView: React.FC<IProps> = ({ threadId }) => {
  const [showMessageImageMarker, setShowMessageImageMarker] = React.useState(false);
  const [selectedMedia, setSelectedMedia] = React.useState(null);
  const history = useHistory();
  const [sentMessage] = useMutation(SENT_MESSAGE_GQL, {
    refetchQueries: [GET_MESSAGE_GQL]
  });

  const { loading, error, data } = useQuery(GET_MESSAGE_GQL, {
    variables: { threadId: threadId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting message from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting message from server (2)</p>);
  }


  if (data.getMessageTreads.length === 0) {
    return (
      <MessageViewEmpty />
    )
  }

  const handleGotoCaseClicked = () => {
    history.push('/admin/order/' + data.getMessageTreads[0].order.id + '/planning');
  }

  const getMessageSender = (message: any) => {
    if (message.customer) {
      return message.customer.name;
    }
    if (message.subcontractor) {
      return message.subcontractor.name;
    }
  }

  const handleSentMessageClicked = (messageText: string, selectedMedias: any[]) => {

    if (messageText === '') {
      return;
    }

    //convert selectedMedias to correct format
    let medias = [];
    selectedMedias.forEach((media) => {
      medias.push({
        mediaId: media.id,
        markerOverLay: media.markerOverLay ? media.markerOverLay : ""
      });
    });

    //find last message
    const lastMessage = data.getMessageTreads[data.getMessageTreads.length - 1];

    //copy the last message into a new message
    let newMessage = { ...lastMessage };
    newMessage.messageText = messageText;
    newMessage.messageSender = 'PHOTOGRAPHY_COMPANY';
    newMessage.messageType = 'NORMAL_MESSAGE';
    newMessage.parentMessageId = newMessage.id;
    newMessage.medias = medias;

    if (newMessage.subcontractorOrder) {
      newMessage.subcontractorOrderId = newMessage.subcontractorOrder.id;
    }
    newMessage.orderId = newMessage.order.id;
    newMessage.threadId = newMessage.threadId;
    if (newMessage.customer) {
      newMessage.customerId = newMessage.customer.id;
    }


    //remove id
    delete newMessage.id;
    delete newMessage.order;
    delete newMessage.subcontractor;
    delete newMessage.photographyCompany;
    delete newMessage.subcontractorOrder;
    delete newMessage.dateRead;
    delete newMessage.createdAt;
    delete newMessage.customer;
    delete newMessage.__typename;

    sentMessage({
      variables: {
        input: newMessage
      }
    });
  }

  const getMessageReceivers = () => {
    let receivers = '';
    data.getMessageTreads.forEach((message) => {

      if (message.customer) {
        if (receivers.indexOf(message.customer.name) === -1) {
          if (receivers === '') {
            receivers = message.customer.name;
          } else {
            receivers = receivers + ', ' + message.customer.name;
          }
        }
      }

      if (message.subcontractor) {
        if (receivers.indexOf(message.subcontractor.name) === -1) {
          if (receivers === '') {
            receivers = message.subcontractor.name;
          } else {
            receivers = receivers + ', ' + message.subcontractor.name;
          }
        }
      }
    });
    return receivers;
  }

  const handleShowMediaOverlay = (media: any) => {
    //const media = data.getMessageTreads[0].medias.find((media) => media.id === mediaId)

    //map media to messageMedia
    const messageMedia = {
      id: media.media.id,
      filename: media.media.filename,
      thumpnailUrl: media.media.thumpnailUrl,
      mediaType: media.media.mediaType,
      markerOverLay: media.markerOverLay ? media.markerOverLay : "<svg></svg>"
    }

    setSelectedMedia(messageMedia);
    setShowMessageImageMarker(true);
    //console.log('handleShowMediaOverlay', mediaId);
  }

  setTimeout(() => {
    const test = document.getElementsByClassName("messageViewContent")[0];
    if (test) {
      test.scrollTop = test.scrollHeight;
    }
  }, 100);

  return (
    <>
      <div className="messageViewContainer">
        <MessageViewImageMarker media={selectedMedia} onClose={() => { setShowMessageImageMarker(false) }} isVisible={showMessageImageMarker} />
        <table className="messageViewTop">
          <tr>
            <td className="messageViewTopTitle">
              Til:
            </td>
            <td>
              {getMessageReceivers()}
            </td>
          </tr>
          <tr>
            <td>
              Emne:
            </td>
            <td>
              {data.getMessageTreads[0].messageSubject}
            </td>
          </tr>
          <tr>
            <td>
              Sag:
            </td>
            <td style={{display: 'flex', paddingTop: '4px'}}>
              {data.getMessageTreads[0].order.caseNo} - {data.getMessageTreads[0].order.estateAddress}, {data.getMessageTreads[0].order.estateZipCode} {data.getMessageTreads[0].order.estateCity}
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleGotoCaseClicked}>
                            <g id="Interface / External_Link">
                              <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
            
            </td>
          </tr>
          {data.getMessageTreads[0].subcontractorOrder && (
            <tr>
            <td>
              Ordre:
            </td>
            <td>
            {data.getMessageTreads[0].subcontractorOrder.subcontractor.name} - Bestilt: {moment(data.getMessageTreads[0].subcontractorOrder.createdAt).format('DD-MM-YYYY HH:MM')}
            </td>
          </tr>
          )}
        </table>
        <div className="messageViewContent scrollbar">
          {data.getMessageTreads.map((message) => (
            <>
              {message.messageSender === 'PHOTOGRAPHY_COMPANY' ? (
                <div className="messageUser">
                  <div className="messageUserContainer">
                    <div className="messageUserFromAndDate">
                      <div className="messageUserFrom">{message.photographyCompany.name}</div>
                      <div className="messageUserDate">{moment(message.createdAt).format('DD-MM-YYYY HH:mm')}</div>
                    </div>

                    {message.medias.map((media) => (
                      <div className="selectedMediaContainer">
                        <img onClick={() => { handleShowMediaOverlay(media) }} className="selectedMediaContainerImage" src={media.media.thumpnailUrl} alt={media.media.name} />
                        {media.markerOverLay && (
                          <div onClick={() => { handleShowMediaOverlay(media) }} className="mediaOverlay" dangerouslySetInnerHTML={{ __html: media.markerOverLay }}></div>
                        )}
                      </div>
                    ))}


                    <div className="messageUserText" dangerouslySetInnerHTML={{ __html: message.messageText.replace(/\n/g, '<br>') }}></div>
                  </div>
                </div>
              ) : (
                <div className="messageOther">
                  <div className="messageOtherContainer">
                    <div className="messageOtherFromAndDate">
                      <div className="messageOtherFrom">{getMessageSender(message)}</div>
                      <div className="messageOtherDate">{moment(message.createdAt).format('DD-MM-YYYY HH:mm')}</div>
                    </div>
                    {message.medias.map((media) => (
                      <div className="selectedMediaContainer">
                        <img onClick={() => { handleShowMediaOverlay(media) }} className="selectedMediaContainerImage" src={media.media.thumpnailUrl} alt={media.media.name} />
                        {media.markerOverLay && (
                          <div onClick={() => { handleShowMediaOverlay(media) }} className="mediaOverlay" dangerouslySetInnerHTML={{ __html: media.markerOverLay }}></div>
                        )}
                      </div>
                    ))}

                    <div className="messageOtherText" dangerouslySetInnerHTML={{ __html: message.messageText.replace(/\n/g, '<br>') }}></div>
                  </div>
                </div>
              )
              }
            </>
          ))}
        </div>
        <div className="messageViewTextBarContainer">
          <MessageViewTextBar onSentMessageClicked={handleSentMessageClicked} orderId={data.getMessageTreads[0].order.id} showSentButton={true} MakeInlineStyle={false} />
        </div>
      </div>
    </>
  );
};

export default MessageView;
