// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Select } from "@chakra-ui/react";
// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth } from "../../../../auth-context/auth.context";

const updateCustomerInfoGql = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const GET_TEXT_PROFILES = gql`
  query GET_TEXT_PROFILES {
  getTextProfiles {
    id
    name
  }
}`

const CustomerSettings = ({ data }) => {
  const { loading, error, data: textProfilesData } = useQuery(GET_TEXT_PROFILES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const userData = useAuth()
  const toast = useToast();
  const [updateCustomerInfo] = useMutation(updateCustomerInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  if (loading) {
    return <p></p>; //loading
  }
  if (error) {
    return <p>Error getting text profiles server (1)</p>; //TODO: make error component
  }
  if (!textProfilesData) {
    return <p>Error getting text profiles from server (2)</p>;
  }

  const updateData = (customerId, field, data) => {
    let inputData = JSON.parse('{"' + field + '":"' + data + '"}');
    inputData.id = customerId;

    console.log('inputData', inputData)
    updateCustomerInfo({ variables: { input: inputData } }).then(() => {
      toast({
        title: "Ændringen er blevet gemt.",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(() => {
      toast({
        title: "Der opstod en fejl, ændringen blev ikke gemt.",
        description: "",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };


  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Indstillinger
        </Text>
      </CardHeader>
      <CardBody px="5px" height={"100%"}>
        <Flex direction="column" style={{ width: "100%", height: "100%" }}>
          <Flex align="center" mb="18px">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              me="10px"
              whiteSpace={"nowrap"}
              minWidth={"150px"}
            >
              Tekst profil:{" "}
            </Text>
            <Select multiple={false} disabled={userData.user.userType === 'PHOTOGRAPHER'} onChange={(e) =>
              updateData(data.id, "textProfileId", e.target.value)
            }>
              {data.mainPhotographerUser === null ? (
                <option key={'NONE'} selected value={'NONE'}>
                  Ingen
                </option>
              ) : (
                <option key={'NONE'} value={'NONE'}>
                  Ingen
                </option>
              )}



              {
                textProfilesData.getTextProfiles.map((textProfile) => {
                  if (data.textProfile && textProfile.id === data.textProfile.id) {
                    return (
                      <option key={textProfile.id} selected value={textProfile.id}>
                        {textProfile.name}
                      </option>
                    );
                  } else {
                    return (
                      <option key={textProfile.id} value={textProfile.id}>
                        {textProfile.name}
                      </option>
                    );
                  }
                })
              }

            </Select>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CustomerSettings;
