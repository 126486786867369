// Chakra imports
import { Text, Flex, Grid, GridItem, Input, useColorModeValue, useToast } from "@chakra-ui/react";
import ProfileBgImage from "../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import TextSectionList from "./../components/textSectionList";

const GET_TEXTPROFILE = gql`
query getTextProfile($id: ID!) {
  getTextProfile(id: $id) {
    id
    name
    textSections {
      id 
      name
      textType
      textLength
      sorting
    }
  },
}`


const UPDATE_TEXTPROFILE = gql`
mutation updateTextProfile($input: UpdateTextProfileInput!){
  updateTextProfile(input:$input){
    id
  }
}`

/*
const CreateProductObj = {
  name: '' as string,
  productCategoryId: '' as string,
}

const UpdateProductCategoryObj = {
  id: '' as string,
  name: '' as string,
  showOnOrderForm: false as boolean,
}
*/

function TextProfile() {
  // Chakra color mode
  const toast = useToast()

  interface Params {
    textProfileId: string;
  }

  const { textProfileId } = useParams<Params>();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );


  const [updateTextProfile] = useMutation(
    UPDATE_TEXTPROFILE,
    {
      refetchQueries: [GET_TEXTPROFILE],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const { loading, error, data, refetch } = useQuery(GET_TEXTPROFILE, {
    variables: {
      id: textProfileId
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting text profile from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting text profile from server (2)</p>);
  }


  const updateData = (field, value) => {
    // value = value.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");

    const dataObj = {}

    dataObj['id'] = textProfileId;
    dataObj[field] = value;

    updateTextProfile({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Tekst profil"}
        description={"Her kan du redigere og tilføje tekst felter til din profil."}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={true}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Tekst profil
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="0px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"110px"} me="10px" whiteSpace={"nowrap"}>
                    Navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.getTextProfile.name}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>


        <GridItem colSpan={3} rowSpan={0} >
          <TextSectionList textSections={data.getTextProfile.textSections} forceRerender={refetch} profileId={textProfileId} />
        </GridItem>

      </Grid>
    </Flex>
  );
}

export default TextProfile;
