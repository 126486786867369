import {
    Flex, Text, useColorModeValue, useToast,
    Select,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Button
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrders($input: UpdateOrdersInput!) {
    updateOrders(input: $input) 
  }
`;

const GET_USERS = gql`
  query users {
    users {
        id
        name
    }   
}`

interface IProps {
    onClose: any,
    assignedUserId: string,
    orderIds: string[],
    isOpen: boolean
}

const OrderChangeAssignedUserDialog: React.FC<IProps> = ({ orderIds, assignedUserId, isOpen, onClose }) => {
    const [selectedUserId, setSelectedUserId] = useState(assignedUserId);
    const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();

    console.log('orderIds', orderIds)   

    const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
    const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(GET_USERS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });


    if (loadingUsers) return <p></p>; //loading

    if (errorUsers) {
        return <p>Error getting users from server (1)</p>;
    }

    if (!dataUsers) {
        return <p>Error getting users from server (2)</p>;
    }


    const handleSaveChanges = () => {


        let inputData = {} as any;
        inputData.orderIds = orderIds;
        inputData.assignedUserId = selectedUserId
        
        updateOrderInfo({
            variables: { input: inputData },
        })
            .then(() => {
                onClose(true);
                toast({
                    title: "Ændringen er blevet gemt.",
                    description: "",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                console.error(e)
                toast({
                    title: "Opdatering af ordren fejlede.",
                    description: "Det var ikke muligt at opdatere ordren.",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            });


    }

    const handleUserChange = (userId: string) => {
        //console.log('userId', userId)
        setSelectedUserId(userId);
    }

    const sortUsers = (users: any) => {
        return users.slice().sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }

    return (
        <Modal onClose={() => { onClose(false) }} size="xl" isOpen={isOpen} >
            <ModalOverlay />
            <ModalContent maxW="800px" bg={'#f9f9f9'}>
                <ModalHeader>Vælg fotograf</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Flex direction='column' style={{ width: '100%' }}>
                        <Flex align='center' mb='18px'>
                            <Text fontSize='md' color={textColor} me='10px' whiteSpace={'nowrap'} minWidth={'150px'}>
                                Fotograf:{" "}
                            </Text>
                            <Select multiple={false} onChange={(e) => { handleUserChange(e.target.value) }}>
                            <option key={'assignedUser_0'} value={''}>Ikke tildelt</option>
                                {sortUsers(dataUsers.users).map((user: any) => {

                                

                                    if (selectedUserId === user.id) {
                                        return (
                                            <option key={user.id} value={user.id} selected>{user.name}</option>
                                        )
                                    } else {
                                        return (
                                            <option key={user.id} value={user.id}>{user.name}</option>
                                        )
                                    }





                                })}
                            </Select>
                        </Flex>

                    </Flex>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { onClose(false) }}>Fortryd</Button>
                    <Button colorScheme='green' onClick={() => { handleSaveChanges() }} ml={3}>Ok</Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    );
}

export default OrderChangeAssignedUserDialog;