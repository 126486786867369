// Chakra imports
import { Text, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, Checkbox, useToast } from "@chakra-ui/react";
import ProfileBgImage from "../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import SettleTable from "./../../../../../components/Tables/SettleTable";
import React from "react";
import CardHeader from "../../../../../components/Card/CardHeader";
import ConditionList, { conditionType } from "../components/conditionList";

const GET_PRODUCTS = gql`
query getProductsByCategory($productCategoryId: ID!) {
  getProductsByCategory(productCategoryId: $productCategoryId) {
    id
    name
    description
  }
  getProductCategory(id: $productCategoryId) {
    id
    name
    showOnOrderForm
    conditions {
      id
      conditionType
      field {
        id
        name
        fieldType
      }
      operator
      value
    }
  }
  conditionFields {
    id
    name
    fieldType
    values
  }
  
}`

const DELETE_PRODUCT = gql`
mutation deleteProduct($id: ID!){
    deleteProduct(id:$id)
}`

const CREATE_PRODUCT = gql`
mutation createProduct($input: CreateProductInput!){
  createProduct(input:$input){
    id
  }
}`

const UPDATE_PRODUCT_CATEGORY = gql`
mutation updateProductCategory($input: UpdateProductCategoryInput!){
  updateProductCategory(input:$input){
    id
  }
}`

const UPDATE_PRODUCT_SORTING = gql`
mutation updateProductSorting($input: [UpdateProductSortingInput]){
  updateProductSorting(input:$input)
}`

const CreateProductObj = {
  name: '' as string,
  productCategoryId: '' as string,
}

/*
const UpdateProductCategoryObj = {
  id: '' as string,
  name: '' as string,
  showOnOrderForm: false as boolean,
}
*/

function ProductCategory() {
  // Chakra color mode
  const toast = useToast()

  interface Params {
    productCategoryId: string;
  }

  const { productCategoryId } = useParams<Params>();
  const history = useHistory();
  const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
  const [validate, setValidate] = React.useState(false);
  const [createProductData, setCreateProductData] = React.useState(CreateProductObj);
  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const { isOpen: createProductIsOpen, onOpen: createProductOnOpen, onClose: createProductOnClose } = useDisclosure();
  const [selectedProductId, setSelectedProductId] = React.useState('');

  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [deleteProduct] = useMutation(
    DELETE_PRODUCT,
    {
      refetchQueries: [GET_PRODUCTS],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [createNewProduct] = useMutation(
    CREATE_PRODUCT,
    {
      refetchQueries: [GET_PRODUCTS],
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const [updateProductCategory] = useMutation(
    UPDATE_PRODUCT_CATEGORY,
    {
      refetchQueries: [GET_PRODUCTS],
    }
  )

  const [updateProductSorting] = useMutation(
    UPDATE_PRODUCT_SORTING,
    {
      refetchQueries: [GET_PRODUCTS],
    }
  )

  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS, {
    variables: {
      productCategoryId: productCategoryId
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting customer from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting customer from server (2)</p>);
  }



  function rowClicked(obj) {
    //redirect to product page
    history.push('/admin/setting/product/' + obj.id);
  }

  const createProduct = () => {
    if (CreateProductObj.name === '') {
      console.log('Validate - CreateUserObj:', CreateProductObj)
      setValidate(true)
    } else {
      createNewProduct({
        variables: { input: CreateProductObj } as any
      }).then(() => createProductOnClose())
        .catch((() => {
          toast({
            title: 'Opret produkt fejlede.',
            description: "Det var ikke muligt at oprette produkt.",
            status: 'error',
            duration: 4000,
            isClosable: true,
          });

        })
        );
    }
  }

  const createProductClicked = () => {
    CreateProductObj.name = '';
    CreateProductObj.productCategoryId = productCategoryId;

    setCreateProductData(CreateProductObj)
    setValidate(false)
    createProductOnOpen();
  }


  const updateProductData = (field, value) => {
    CreateProductObj[field] = value;
    setCreateProductData(CreateProductObj);
  }


  const updateData = (field, value) => {
    const dataObj = {}

    dataObj['id'] = productCategoryId;
    dataObj[field] = value;

    updateProductCategory({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const deleteProductClicked = (obj) => {
    setSelectedProductId(obj.id)
    deleteWarningOnOpen();
  }

  const deleteProductConfirmed = () => {
    deleteProduct({ variables: { id: selectedProductId } } as any);
    deleteWarningOnClose();
  }

  const setTableData = (obj) => {
    var tableData = obj(data.getProductsByCategory);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((product) => ({ id: product.id, sorting: product.sorting }));

    updateProductSorting({
      variables: {
        input: result,
      },
    });
  };

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "Sortering",
      accessor: "sorting",
      alignHeader: "center",
      alignData: "right",
      hidden: true,
      onClick: rowClicked,
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: rowClicked,
      width: 200,
    },
    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: rowClicked,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: deleteProductClicked,
    },
  ];

  console.log('data:', data)
  let tableData = JSON.parse(JSON.stringify(data.getProductsByCategory));



  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={"Produkter"}
        description={"Her kan du redigere dine produkt kategorier og produkter."}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showBackButton={true}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
      />

      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Produktkategori
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                    navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.getProductCategory.name}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
                </Flex>
                <Flex direction='column' style={{ width: '100%' }}>
                  <Flex align='center' mb='18px'>
                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={"110px"}>
                      Vis:{" "}
                    </Text>
                    <Checkbox onChange={(e) => updateData('showOnOrderForm', e.target.checked)} defaultChecked={data.getProductCategory.showOnOrderForm}></Checkbox>
                  </Flex>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={3} rowSpan={0} >
          <ConditionList conditionFields={data.conditionFields} refId={data.getProductCategory.id} type={conditionType.ProductCategory} forceRerender={refetch} conditions={data.getProductCategory.conditions} />
        </GridItem>
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
            <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
              <Flex direction="column">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Produkter
                </Text>
              </Flex>
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize="12px"
                  fontWeight="bold"
                  mb="6px"
                  cursor="pointer"
                  onClick={() => {
                    createProductClicked();
                  }}
                >
                  Opret nyt produkt
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <SettleTable data={tableData} columnsData={columns} setDragData={setTableData} csvData={[]} enableSelect={false} />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>



      <Modal onClose={createProductOnClose} size="xl" isOpen={createProductIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret produkt</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprette et nyt produkt, udfyld nedenstående og klik opret.</Text>
            <br /><br />
            <Text fontSize='md' fontWeight='bold'>Produkt kategori:</Text>

            <Flex direction='column' style={{ width: '100%' }}>
              <Flex align='center' mb='18px'>
                <Text fontSize='md' color={textColor} fontWeight="bold" me='10px' whiteSpace={'nowrap'} minWidth={'110px'}>
                  Navn:{" "}
                </Text>
                <Input
                  variant='flushed'
                  type='text'
                  name="name"
                  defaultValue={createProductData.name}
                  onKeyUp={(e) => { updateProductData('name', e.currentTarget.value) }}
                  className={validate && createProductData.name === '' ? 'error' : ''}
                />
              </Flex>
            </Flex>

          </ModalBody>
          <ModalFooter>
            <Button onClick={createProductOnClose}>Fortryd</Button>
            <Button colorScheme='green' onClick={createProduct} ml={3}>Opret</Button>

          </ModalFooter>
        </ModalContent>
      </Modal>


      <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet produkt
            </AlertDialogHeader>

            <AlertDialogBody>Du er ved at slette et produkt. <br />Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteWarningOnClose}>
                Nej, behold
              </Button>
              <Button colorScheme="red" onClick={deleteProductConfirmed} ml={3}>
                Ja, jeg er sikker
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>


  );
}

export default ProductCategory;
