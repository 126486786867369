// Chakra imports
import { Flex, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
import ProfileBgImage from "./../../../assets/img/ProfileBackground.png";
import { useState } from "react";
import Card from  "./../../../components/Card/Card";
import CardBody from "./../../../components/Card/CardBody";
import Header from "./../../../components/Headers/Header";
import SettleTable from "./../../../components/Tables/SettleTable";
import DataSelectorCardDates from "./components/DataSelectorCardDates"
import { useQuery, gql } from "@apollo/client";
import moment from "moment";

const REPORT_GQL = gql`
query globalSubcontractorBilling($input: GlobalSubcontractorBillingInput){
    globalSubcontractorBilling(input: $input){
        id
        subcontractorName
        subcontractorEmail
        photographyCompanyName
        caseNo
        estateAddress
        picturePrice
        floorPlanPrice
        count
        itemType
        createdAt
    }
}`

const columns = [
    {
        header: "",
        accessor: "id",
        hidden: true
    },
    {
        header: "Kunde",
        accessor: "photographyCompanyName",
    },
    {
        header: "Sags nr.",
        accessor: "caseNo",
    },
    {
        header: "Addresse",
        accessor: "estateAddress",
    },

    {
        header: "Leverandør",
        accessor: "subcontractorName",
    },
    {
        header: "Leverandør email",
        accessor: "subcontractorEmail",
    },

    {
        header: "Type",
        accessor: "itemTypeConverted",
    },
    {
        header: "Antal",
        accessor: "count",
    },
    {
        header: "Oprettet",
        accessor: "created",
    }
   
    
];


function GlobalSubcontractorBilling() {
    // Chakra color mode

    const today = new Date();
    const defaultfromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

    const [requestData, setrequestData] = useState({
        fromDate: defaultfromDate.toISOString().split('T')[0],
        toDate: today.toISOString().split('T')[0],
    });

    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const { loading, error, data } = useQuery(REPORT_GQL, {
        variables: {
            input: {
                fromDate: requestData.fromDate,
                toDate: requestData.toDate
            }
        },
        fetchPolicy: 'network-only',
        manual: true,
    });

    if (loading) return (
        <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Leverandør forbrugsrapport"}
                description={'Leverandør forbrugsrapport.'}
            />
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                   
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                </CardBody>
            </Card>
        </Flex>
    ); //loading

    if (error) {
        return (<p>Error getting stats from server, error: {error}</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting stats from server (2)</p>);
    }

    const updatefn = (fromDate, toDate) => {
        setrequestData({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    const translateItemType = (itemType) => {
        switch (itemType) {
            case 'IMAGE':
                return 'Billeder';
            case 'FLOORPLAN':
                return 'Plantegninger';
            default:
                return 'Ukendt';
        }
    }

    //add fake id for table component
    let tableData = JSON.parse(JSON.stringify(data.globalSubcontractorBilling));
   //add fake id for table component
    tableData.map((item, index) => {
        item.id = index;
        item.itemTypeConverted = translateItemType(item.itemType);
        item.created = "" + moment(item.createdAt).format('DD-MM-YYYY');
        return item;
    });

    //function numberWithCommas(x) {
    //    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //}

    //pricesTotal = numberWithCommas(pricesTotal);

    

    var csvData = tableData.map(item => (
        { 
            Kunde: item.photographyCompanyName,
            SagsNr: item.caseNo,
            Addresse: item.estateAddress,
            Leverandør: item.subcontractorName,
            Leverandør_email: item.subcontractorEmail,
            Type: translateItemType(item.itemType),
            Antal: item.count,
            Oprettet: moment(item.createdAt).format('DD-MM-YYYY')
        }
    ));



    //sum all prices in csvData
    //var pricesTotal = csvData.reduce((sum, item) => sum + parseFloat(item.Ialt), 0);
    //pricesTotal = pricesTotal.toFixed(0);
    //csvData.push({ Kunde: '', SagsNr: '', Addresse: '', Ialt: '' });
    //csvData.push({ Kunde: '', SagsNr: '', Addresse: 'I alt', Ialt: pricesTotal });


    return (
        <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                name={"Leverandør forbrugsrapport"}
                description={'Leverandør forbrugsrapport.'}
            />
    
            <Grid templateColumns={{ sm: "2fr", xl: "repeat(4, 2fr)" }} gap="22px">
                <GridItem w='100%' colSpan={4} rowSpan={2} >
                    <DataSelectorCardDates
                        updatefn={updatefn}
                        fromDate={requestData.fromDate}
                        toDate={requestData.toDate}
                    />
                </GridItem>
            </Grid>
            <br />
            <Card px="0px">
                <CardBody>
                    <SettleTable csvData={csvData} data={tableData} columnsData={columns} />
                </CardBody>
            </Card>
        </Flex>
    );
}

export default GlobalSubcontractorBilling;
