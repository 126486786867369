// Chakra imports
import {
  Flex, useColorModeValue, Text, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Input,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Select
} from "@chakra-ui/react";
//import { Select as ChakraSelect } from 'chakra-react-select';
import { MultiSelect } from "react-multi-select-component";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import React, { useState, useRef } from "react";
import { useQuery, useApolloClient,  useMutation, gql } from "@apollo/client";
import PropTypes from "prop-types";
// assets

// Custom icons
import EventCalendar from "./../../../../components/Calendars/EventCalendar";
import Card from "./../../../../components/Card/Card.js";
import CardBody from "./../../../../components/Card/CardBody.js";
import CardHeader from "./../../../../components/Card/CardHeader.js";
import moment from "moment";

import { signOut } from "../../../../auth-context/authHelper";
import OrderSentMessage, { MessageType } from "./../../Orders/components/orderSentMessage"
import QRCode from 'qrcode'
import { useAuth } from "../../../../auth-context/auth.context";
import { useHistory } from "react-router-dom";

const UPDATE_CALENDER_ITEM_GQL = gql`
mutation updateCalenderItem($input: UpdateCalenderItemsInput!) {
  updateCalenderItem(input: $input) {
    id
  }
}
`;

const DELETE_CALENDER_ITEM_GQL = gql`
mutation deleteCalenderItem($id: ID! ) {
  deleteCalenderItem(id: $id)
}`

const CREATE_CALENDER_ITEM_GQL = gql`
  mutation createCalenderItem($input: CreateCalenderItemsInput!) {
    createCalenderItem(input: $input) {
      id
    }
  }
`;

const GET_DASHBOARD_INFO = gql`
  query GET_DASHBOARD_INFO($getOrdersinput: GetOrdersByUserInput!) {
    getOrdersByUser(input: $getOrdersinput){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
      createdAt
    }
    users {
      id
      name
      userType
      calenderColor
    }
  }
`;

const GET_CALENDER_INFO = gql`
  query GET_CALENDER_INFO($input: GetCalenderItemsInput!) {
    getCalenderItems(input: $input){
      id
      start
      end
      title
      user {
        id
        name
        calenderColor
      }
      description
      eventType
      order {
        id
        caseNo
        estateZipCode
        estateCity
        keyCase
      }
    }
   
  }
`;

const AppointmentObj = {
  id: '',
  startDate: '',
  startTime: '12:00',
  endDate: '',
  endTime: '14:00',
  orderId: '',
  title: '',
  description: '',
  addressLink: '',
  mailHeader: '',
  mailText: '',
  userId: '',
  eventType: 'PHOTOSHOT'
}



const UserCalender = (props) => {
  const selectedOrder = props.selectedOrder;
  const client = useApolloClient();
  const { isOpen: calenderCreateNewIsOpen, onOpen: calenderCreateNewOnOpen, onClose: calenderCreateNewOnClose } = useDisclosure();
  const { isOpen: downloadCalsIsOpen, onOpen: downloadCalsOnOpen, onClose: downloadCalsOnClose } = useDisclosure();
  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const { isOpen: overlapWarningIsOpen, onOpen: overlapWarningOnOpen, onClose: overlapWarningOnClose } = useDisclosure();

  const [createCalenderItem] = useMutation(CREATE_CALENDER_ITEM_GQL);
  const [updateCalenderItem] = useMutation(UPDATE_CALENDER_ITEM_GQL);
  const [deleteCalenderItem] = useMutation(DELETE_CALENDER_ITEM_GQL);

  const history = useHistory();
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [calsUrl, setCalsUrl] = useState("");
  const [currentAppointment, setCurrentAppointment] = useState(AppointmentObj);

  const calendarData = useRef([]);
  const calendarShowingUsers = useRef([]);
  const calendarForceUpdate = useRef(false);
  const calendarDates = useRef({
    calendarStartDate: new Date(),
    calendarEndDate: new Date()
  });

  const [showSentMessage, setShowSentMessage] = useState(false);
  let CalenderSelectedUser = localStorage.getItem("dashboard_calenderSelectedUser") ? localStorage.getItem("dashboard_calenderSelectedUser").split(',') : [localStorage.getItem("system_user_id")];

  if (selectedOrder && selectedOrder.assignedUser) {
    CalenderSelectedUser = [selectedOrder.assignedUser.id];
  }

  const [calenderSelectedUser, setCalenderSelectedUser] = useState(CalenderSelectedUser);
  const deleteWarningCancelRef = React.useRef < HTMLButtonElement > (null); // eslint-disable-line
  const overlapWarningCancelRef = React.useRef < HTMLButtonElement > (null); // eslint-disable-line

  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const userData = useAuth();

  //current date minus 3 months
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 3);
  fromDate.setDate(1);

  //current date plus 3 months
  const toDate = new Date();
  toDate.setMonth(toDate.getMonth() + 3);
  toDate.setDate(1);


  const { loading, error, data, refetch } = useQuery(GET_DASHBOARD_INFO, {
    variables: {
      getOrdersinput: {
        userId: localStorage.getItem("system_user_id"),
        status: ['RECEIVED']
      }
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  },
  );


  if (loading) {
    return <p></p>; //loading
  }
  if (error) {
    if (error.message.includes('401')) {
      signOut();
    }
    return <p>Error getting calender data from server (1)</p>; //TODO: make error component
  }
  if (!data) {
    return <p>Error getting calender data from server (2)</p>;
  }

  let currentDate = new Date();

  //Orders not planned (used for new calender items)
  let notPlanedOrders = data.getOrdersByUser.map((order) => {
    let keyCase = '';
    if (order.keyCase === "COORDINATE_WITH_CONTACTPERSON" || order.keyCase === "COORDINATE_WITH_ESTATEAGENT") {
      keyCase = ' 🔑';
    }

    return {
      id: order.id,
      address: order.estateAddress + ", " + order.estateZipCode + ' ' + order.estateCity,
      caseNo: order.caseNo + keyCase,
      addressLink: '/#/admin/order/' + order.id + '/planning'
    }
  });


  const updateNewAppointmentData = (field, data) => {
    if (field === 'startDate') {
      AppointmentObj['endDate'] = data;
    }

    AppointmentObj[field] = data;
    setCurrentAppointment({ ...AppointmentObj })
  }

  const deleteCalenderItemOnServer = () => {
    deleteCalenderItem({ variables: { id: AppointmentObj.id } }).then(() => {
      calendarForceUpdate.current = true;
      deleteWarningOnClose();
      calenderCreateNewOnClose();
      refetch();
      props.updateComponent('NewOrders');

    });
  }

  const handleNewAppointmentOrderChange = (value, item) => {
    AppointmentObj.orderId = value;
    AppointmentObj.title = item.label;
    setCurrentAppointment(AppointmentObj)
  }


  const handleCreateUpdateAppointmentOnServer = (validateOverlap) => {
    let startDate = new Date(AppointmentObj.startDate)
    startDate.setHours(AppointmentObj.startTime.split(':')[0])
    startDate.setMinutes(AppointmentObj.startTime.split(':')[1])

    let endDate = new Date(AppointmentObj.endDate)
    endDate.setHours(AppointmentObj.endTime.split(':')[0])
    endDate.setMinutes(AppointmentObj.endTime.split(':')[1])

    //setScrollPosition(window.pageYOffset);

    if (validateOverlap) {
      //loop through all calender items and check if there is a overlap
      for (let i = 0; i < calendarData.current.length; i++) {
        if (calendarData.current[i].id !== AppointmentObj.id) {
          let calenderStartDate = new Date(calendarData.current[i].start)
          let calenderEndDate = new Date(calendarData.current[i].end)

          // Calculate the overlap duration in milliseconds
          let overlapDuration = Math.min(endDate, calenderEndDate) - Math.max(startDate, calenderStartDate);

          // Convert overlap duration to minutes
          let overlapDurationMinutes = overlapDuration / (1000 * 60);

          // Show warning if overlap is larger than 5 minutes
          if (overlapDurationMinutes > 5) {
            overlapWarningOnOpen();
            return;
          }
        }
      }
    }
    
    let inputData = {};
    inputData.start = startDate
    inputData.end = endDate
    inputData.title = AppointmentObj.title
    inputData.description = ''
    inputData.eventType = AppointmentObj.eventType
    inputData.sentMail = false
    inputData.mailHeader = ''
    inputData.mailContent = ''
    inputData.userId = AppointmentObj.userId;

    if (AppointmentObj.id !== '') {
      //updatering
      inputData.id = AppointmentObj.id;

      updateCalenderItem({ variables: { input: inputData } }).then(() => {
        calendarForceUpdate.current = true;
        refetch();

        //setUpdateState(new Date().getTime());
        setShowSentMessage(true);


        //dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        calenderCreateNewOnClose();

      })
        .catch(() => {
          toast({
            title: "Opdatering af ordren fejlede.",
            description: "Det var ikke muligt at opdatere ordren.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    } else {
      //oprettelse

      if (AppointmentObj.eventType !== 'OTHER' && AppointmentObj.orderId === '') {
        toast({
          title: "Der er ikke valgt en ordre.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      //console.log('AppointmentObj userId:', AppointmentObj.userId)
      // inputData.userId = AppointmentObj.userId; // localStorage.getItem("system_user_id")
      inputData.orderId = AppointmentObj.orderId;

      createCalenderItem({ variables: { input: inputData } }).then(() => {
        calendarForceUpdate.current = true;
        refetch();
        setShowSentMessage(true);

        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        calenderCreateNewOnClose();
        props.updateComponent('NewOrders');
      })
        .catch((e) => {
          console.log('Error:', e)
          toast({
            title: "Opdatering af ordren fejlede.",
            description: "Det var ikke muligt at opdatere ordren.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }

  const handleCreateNewAppointment = () => {
    
    const today = new Date();
    AppointmentObj.id = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.orderId = ''
    AppointmentObj.startDate = today.toISOString().substring(0, 10);
    AppointmentObj.endDate = today.toISOString().substring(0, 10);
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";
    AppointmentObj.userId = userData.user.id;

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }

  const handleDownloadCalsClicked = async () => {
    let calendarUrlLink = process.env.REACT_APP_EXTERNAL_API_URL.replace('https', 'webcals').replace('http', 'webcals') + "/calender/" + userData.user.photographyCompany.id;
    let qrcodeUrl = process.env.REACT_APP_ADMIM_UI_URL + "/#/handlers/calendar/" + userData.user.photographyCompany.id;

    //if (calenderSelectedUser !== '0') {
    calendarUrlLink = calendarUrlLink + "/" + calenderSelectedUser
    qrcodeUrl = qrcodeUrl + "/" + calenderSelectedUser
    //}

    const qrCodeImageUrl = await QRCode.toDataURL(qrcodeUrl, { errorCorrectionLevel: 'H' })
    setQrCodeUrl(qrCodeImageUrl)
    setCalsUrl(calendarUrlLink)
    downloadCalsOnOpen();
  }

  const handleEventMoved = (startStr, endStr, itemId) => {
    //find event
    let event = undefined;

    for (let i = 0; i < calendarData.current.length; i++) {
      if (itemId === calendarData.current[i].id) {
        event = calendarData.current[i];
        break;
      }
    }

    if (event) {
      const eventStartDate = new Date(event.start)
      const eventEndDate = new Date(event.end)
      AppointmentObj.title = event.title
      AppointmentObj.id = event.id
      AppointmentObj.eventType = event.eventType
      AppointmentObj.orderId = event.orderId
      AppointmentObj.userId = event.userId
      AppointmentObj.addressLink = '/#/admin/order/' + event.orderId + '/planning'
      AppointmentObj.startDate = startStr.substr(0, 10);
      AppointmentObj.endDate = endStr.substr(0, 10);
      AppointmentObj.startTime = String(eventStartDate.getHours()).padStart(2, '0') + ":" + String(eventStartDate.getMinutes()).padStart(2, '0')
      AppointmentObj.endTime = String(eventEndDate.getHours()).padStart(2, '0') + ":" + String(eventEndDate.getMinutes()).padStart(2, '0')
      setCurrentAppointment(AppointmentObj);


      calenderCreateNewOnOpen()
    }
  }

  const handleEventDblClicked = (e) => {
    console.log('UserCalender - handleEventDblClicked - e:', e);

    //find event
    let event = undefined;

    for (let i = 0; i < calendarData.current.length; i++) {
      if (e.event.id === calendarData.current[i].id) {
        event = calendarData.current[i];
        break;
      }
    }
    
    history.push('/admin/order/' + event.orderId + '/planning');
  }

  const handleEventClicked = (e) => {
    console.log('UserCalender - handleEventClicked - e:', e);
    //find event
    let event = undefined;

    for (let i = 0; i < calendarData.current.length; i++) {
      if (e.event.id === calendarData.current[i].id) {
        event = calendarData.current[i];
        break;
      }
    }

    if (event) {
      const eventStartDate = new Date(event.start)
      const eventEndDate = new Date(event.end)
      AppointmentObj.title = event.title
      AppointmentObj.id = event.id
      AppointmentObj.eventType = event.eventType
      AppointmentObj.orderId = event.orderId
      AppointmentObj.userId = event.userId
      AppointmentObj.addressLink = '/#/admin/order/' + event.orderId + '/planning'
      AppointmentObj.startDate = event.start.substr(0, 10);
      AppointmentObj.endDate = event.end.substr(0, 10);
      AppointmentObj.startTime = String(eventStartDate.getHours()).padStart(2, '0') + ":" + String(eventStartDate.getMinutes()).padStart(2, '0')
      AppointmentObj.endTime = String(eventEndDate.getHours()).padStart(2, '0') + ":" + String(eventEndDate.getMinutes()).padStart(2, '0')
      setCurrentAppointment(AppointmentObj);
      calenderCreateNewOnOpen();
    }
  }

  const handleCalenderItemDropped = (dateStr, orderId, orderTitle) => {
    AppointmentObj.id = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.title = orderTitle
    AppointmentObj.description = ''
    AppointmentObj.orderId = orderId
    AppointmentObj.addressLink = '/#/admin/order/' + orderId + '/planning'
    AppointmentObj.startDate = dateStr;
    AppointmentObj.endDate = dateStr;
    AppointmentObj.userId = userData.user.id
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }

  const handleDateClicked = (e) => {
    AppointmentObj.title = ''
    AppointmentObj.eventType = 'PHOTOSHOT'
    AppointmentObj.description = ''
    AppointmentObj.orderId = ''
    AppointmentObj.id = ''
    AppointmentObj.startDate = e.dateStr;
    AppointmentObj.endDate = e.dateStr;
    AppointmentObj.userId = userData.user.id
    AppointmentObj.startTime = "12:00";
    AppointmentObj.endTime = "14:00";

    setCurrentAppointment(AppointmentObj);
    calenderCreateNewOnOpen()
  }

  if (props.selectedOrder) {
    if (AppointmentObj.orderId === '') {
      AppointmentObj.orderId = props.selectedOrder.id;
      AppointmentObj.title = props.selectedOrder.caseNo + ' - ' + props.selectedOrder.estateAddress;
      setCurrentAppointment(AppointmentObj);
    }
  }

  const getMessageType = (eventType) => {
    switch (eventType) {
      case 'PHOTOSHOT':
        return MessageType.ORDER_BOOKED;
      case 'WEATHER_UPDATE':
        return MessageType.ORDER_WEATHER_UPDATE_BOOKED;
      case 'SEASON_FOTOSHOT':
        return MessageType.ORDER_SEASON_UPDATE_BOOKED;
      default:
        return null;
    }
  }

  const filterUsers = () => {
    if (userData.user.userType === 'PHOTOGRAPHER') {
      return sortUsers(data.users.filter((user) => user.id === userData.user.id));
    }

    if (userData.user.userType === 'PHOTOGRAPHY_COMPANY_ADMIN') {

      return sortUsers(data.users.filter((user) => user.userType !== 'ADMIN'));
    }

    if (userData.user.userType === 'ADMIN') {
      return sortUsers(data.users);
    }
  }

  const sortUsers = (users) => {
    let sortedUsers = users.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortedUsers;
  }

  const mappedUsers = filterUsers().map((user) => {
    return {
      value: user.id,
      label: user.name
    }
  });

  const closeSentMessage = () => {
    setShowSentMessage(false);
  }

  const handleUserChange = (selectedOptions) => {
    //get selected user ids
    let selectedUserIds = selectedOptions.map((option) => option.value);
    
    setCalenderSelectedUser(selectedUserIds);
    localStorage.setItem("dashboard_calenderSelectedUser", selectedUserIds.join(','))
  };

  const getAllSelectedUsers = () => {
    let selectedUsers = [];
    for (let i = 0; i < calenderSelectedUser.length; i++) {
      let user = data.users.find((x) => x.id === calenderSelectedUser[i]);
      if (user) {
        selectedUsers.push({
          value: user.id,
          label: user.name
        })
      }
    }
    return selectedUsers
  }

  setTimeout(() => {
    if (['PHOTOGRAPHY_COMPANY_ADMIN', 'ADMIN'].includes(userData.user.userType)) {
      //renderEventFilterButton()
    } else {
      let button = document.querySelectorAll('button[title="Vis mine aftaler"]');
      if (button.length > 0) {
        button[0].style.display = 'none';
      }
    }

    let getCalenderbutton = document.querySelectorAll('button[title="Hent kalender"]');
    if (getCalenderbutton.length > 0) {
      if (calenderSelectedUser.length === 1) {
        getCalenderbutton[0].style.display = 'block';
      } else {
        getCalenderbutton[0].style.display = 'none';
      }
    }
  }, 500);

  const userDropdownLocalize = {
    "allItemsAreSelected": "Alle fotografer er valgt.",
    "clearSearch": "Ryd søgning",
    "clearSelected": "Ryd valgte",
    "noOptions": "Ingen valgmuligheder",
    "search": "Søg",
    "selectAll": "Vælg alle",
    "selectAllFiltered": "Vælg alle (Filtreret)",
    "selectSomeItems": "Vælg fotograf",
    "create": "Opret",
  }


  const getCalendarItems = async (startStr, endStr, resultCallback) => {
    const calendarStartDate = new Date(startStr); //.substr(0, 10);
    const calendarEndDate = new Date(endStr); //.substr(0, 10);

    console.log('calendarForceUpdate :', calendarForceUpdate.current );
    if (calendarForceUpdate.current || calendarShowingUsers.current.length !== calenderSelectedUser.length || calendarStartDate.toLocaleDateString() !== calendarDates.current.calendarStartDate.toLocaleDateString() || calendarEndDate.toLocaleDateString() !== calendarDates.current.calendarEndDate.toLocaleDateString()) {
      calendarDates.current.calendarStartDate = calendarStartDate;
      calendarDates.current.calendarEndDate = calendarEndDate;
      calendarShowingUsers.current = calenderSelectedUser;
    }else{
      console.log('USING OLD CALENDER DATA');
      resultCallback( calendarData.current);
      return;
    }

    

    try {
      console.log('LOADING CALENDER DATA');
      const { data } = await client.query({
        query: GET_CALENDER_INFO,
        variables: {
          input: {
            userId: calenderSelectedUser,
            fromDate: moment(calendarStartDate).format('YYYY-MM-DD'),
            toDate: moment(calendarEndDate).format('YYYY-MM-DD'),
          },
        },
        manual: true,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
      });
      calendarForceUpdate.current = false;
      let calenderData = data.getCalenderItems.map((x) => {
        let itemClass = 'calenderItem calenderItem_' + x.user.calenderColor

        let orderId = '';
        if (x.order) {
          orderId = x.order.id;
        }

        if (selectedOrder && x.order && selectedOrder.id === x.order.id) {
          itemClass = 'currentOrder'
        }

        let keyCase = '';
        if (x.order && (x.order.keyCase === "COORDINATE_WITH_CONTACTPERSON" || x.order.keyCase === "COORDINATE_WITH_ESTATEAGENT")) {
          keyCase = ' 🔑';
        }

        let title = x.title;
        if (x.eventType === 'SEASON_FOTOSHOT' || x.eventType === 'PHOTOSHOT' || x.eventType === 'WEATHER_UPDATE') {
          //fix for calender title (line break and zip code)
          if (!x.title.includes(x.order.estateZipCode)) {
            title = x.title.replace(' - ', '\n\r') + '\n\r' + x.order.estateZipCode + ' ' + x.order.estateCity;
          }
          if (x.title.includes(' - ')) {
            title = x.title.replace(' - ', '\n\r')
          }

          if (title.indexOf(' 🔑') === -1) {
            title = title.replace(x.order.caseNo, x.order.caseNo + keyCase);
          }
        }

        return {
          id: x.id,
          orderId: orderId,
          userId: x.user.id,
          title: title,
          status: "A",
          description: '',
          start: x.start,
          end: x.end,
          eventType: x.eventType,
          className: itemClass,
        }
      });

      calendarData.current = calenderData;
      resultCallback(calenderData);
    } catch (error) {
      console.error(error);
      return []
    }
  }

  return (
    <>
      {(showSentMessage && AppointmentObj.eventType !== 'OTHER') && (
        <OrderSentMessage key={'OrderSentMessage'} messageType={getMessageType(AppointmentObj.eventType)} isVisible={(showSentMessage && AppointmentObj.eventType !== 'OTHER')} orderId={AppointmentObj.orderId} onClose={() => { closeSentMessage() }} />
      )}
      <Card w={{ sm: "100%" }} >
        <CardHeader pt="6px" mb="8px"></CardHeader>
        <CardBody position="relative" display="block" >
          <>
            <MultiSelect
              options={mappedUsers}
              value={getAllSelectedUsers()}
              onChange={handleUserChange}
              labelledBy="Select"
              placeholder="Vælg bruger"
              className="userDropdown"
              overrideStrings={userDropdownLocalize}
            />


            <EventCalendar 
              initialDate={currentDate}
              onCreateClicked={() => { handleCreateNewAppointment() }}
              onDateDblClicked={(e) => { handleDateClicked(e) }}
              onEventDblClicked={(e) => { handleEventDblClicked(e) }}
              onEventClicked={(e) => { handleEventClicked(e) }}
              onDownloadClicked={() => { handleDownloadCalsClicked() }}
              onExternalItemDropped={(dateStr, orderId, orderTitle) => { handleCalenderItemDropped(dateStr, orderId, orderTitle) }}
              onItemChanged={(startStr, endStr, itemId) => { handleEventMoved(startStr, endStr, itemId) }}
              users={filterUsers()}
              getCalendarEvents={(startStr, endStr, resultCallback) => { getCalendarItems(startStr, endStr, resultCallback) }}
            />
          </>
        </CardBody>
      </Card>

      {/* Modal for download calender */}
      <Modal onClose={downloadCalsOnClose} size="xl" isOpen={downloadCalsIsOpen}>
        <ModalOverlay />
        <ModalContent width={470}>
          <ModalHeader>Importer kalender</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" justifyContent="Center" style={{ width: "100%", height: "100%" }}>
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                me="10px"
                whiteSpace={"nowrap"}
                mb="18px"
                minWidth={"150px"}
              >
                Scan QR kode eller klik på linket under for at importere<br /> kalender til din telefon eller computer.
              </Text>

              <Flex align="center" mb="18px" justifyContent="Center">
                <img src={qrCodeUrl} alt="QR code" />
              </Flex>
              <Flex align="center" mb="18px" justifyContent="Center">
                <a href={calsUrl} referrerPolicy="no-referrer">Klik her for at importer kalender</a>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for create new calender item */}
      <Modal onClose={calenderCreateNewOnClose} size="xl" isOpen={calenderCreateNewIsOpen}>
        <ModalOverlay />
        <ModalContent>
          {currentAppointment.id === '' ? (
            <ModalHeader>Opret ny kalender aftale</ModalHeader>
          ) : (
            <ModalHeader>Redigere kalender aftale</ModalHeader>
          )}
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" style={{ width: "100%", height: "100%" }}>
              {currentAppointment.id === '' ? (
                <Flex align="center" mb="18px">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    me="10px"
                    whiteSpace={"nowrap"}
                    minWidth={"150px"}
                  >
                    Aftale:{" "}
                  </Text>
                  <Select multiple={false} defaultValue={currentAppointment.eventType} onChange={(e) => updateNewAppointmentData("eventType", e.target.value)}>
                    <option key={'eventType1'} value={"PHOTOSHOT"}>
                      Fotografering - Normal
                    </option>
                    <option key={'eventType2'} value={"WEATHER_UPDATE"}>
                      Fotografering - Vejropdatering
                    </option>
                    <option key={'eventType3'} value={"SEASON_FOTOSHOT"}>
                      Fotografering - Seasonopdatering
                    </option>
                    <option key={'eventType4'} value={"OTHER"}>
                      Andet
                    </option>
                  </Select>
                </Flex>
              ) : (
                <Flex align="center" mb="18px">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    me="10px"
                    whiteSpace={"nowrap"}
                    minWidth={"150px"}
                  >
                    Aftale:{" "}
                  </Text>
                  <Text
                    fontSize="md"
                    me="10px"
                    whiteSpace={"nowrap"}
                    minWidth={"150px"}
                  >
                    {currentAppointment.eventType === 'PHOTOSHOT' ? 'Fotografering - Normal' : ''}
                    {currentAppointment.eventType === 'WEATHER_UPDATE' ? 'Fotografering - Vejropdatering' : ''}
                    {currentAppointment.eventType === 'SEASON_FOTOSHOT' ? 'Fotografering - Seasonopdatering' : ''}
                    {currentAppointment.eventType === 'OTHER' ? 'Andet' : ''}
                  </Text>
                </Flex>

              )}

              {currentAppointment.eventType === 'OTHER' && (
                <>
                  <Flex align="center" mb="18px">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight="bold"
                      me="10px"
                      whiteSpace={"nowrap"}
                      minWidth={"150px"}
                    >
                      Title:{" "}
                    </Text>
                    <Input
                      variant="flushed"
                      name="title"
                      defaultValue={currentAppointment.title}
                      onChange={(e) =>
                        updateNewAppointmentData("title", e.target.value)
                      }
                    />
                  </Flex>
                </>
              )}
              {currentAppointment.eventType !== 'OTHER' && (
                <>
                  <Flex align="center" mb="18px">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight="bold"
                      me="10px"
                      whiteSpace={"nowrap"}
                      minWidth={"150px"}
                    >
                      Ordre:{" "}
                    </Text>
                    {currentAppointment.orderId || currentAppointment.id || props.selectedOrder ? (
                      <>
                        <Text
                          fontSize="md"
                          me="10px"
                          whiteSpace={"nowrap"}
                          minWidth={"150px"}
                          overflow="hidden"
                        >
                          {currentAppointment.title.replace(' 🔑', '')}
                        </Text>
                        <a href={currentAppointment.addressLink} target="_blank" rel="noreferrer" referrerPolicy="no-referrer">
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Interface / External_Link">
                              <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </a>
                      </>


                    ) : (
                      <AutoComplete openOnFocus onChange={(value, item) => { handleNewAppointmentOrderChange(value, item) }}>
                        <AutoCompleteInput variant="filled" />
                        <AutoCompleteList>
                          {notPlanedOrders.map((order, cid) => (
                            <AutoCompleteItem
                              key={`option-${cid}`}
                              value={order.id}
                              id={order.id}
                              label={order.caseNo + ' - ' + order.address}
                            >
                              {order.caseNo + ' - ' + order.address}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                    )}


                  </Flex>

                </>
              )}
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                  whiteSpace={"nowrap"}
                  minWidth={"150px"}
                >
                  Fotograf:{" "}
                </Text>
                <Select multiple={false} onChange={(e) =>
                  updateNewAppointmentData("userId", e.target.value)
                }>
                  {userData.user.userType === 'PHOTOGRAPHER' ? (
                    <option key={userData.user.id} selected value={userData.user.id}>
                      {userData.user.name}
                    </option>

                  ) : (

                    data.users.map((user) => {
                      if (user.id === currentAppointment.userId) {
                        return (
                          <option key={user.id} selected value={user.id}>
                            {user.name}
                          </option>
                        );
                      } else {


                        if (userData.user.userType !== 'ADMIN') {
                          if (user.userType !== 'ADMIN') {
                            return (
                              <option key={user.id} value={user.id}>
                                {user.name}
                              </option>
                            );
                          } else {
                            return ""
                          }
                        }

                        if (userData.user.userType === 'ADMIN') {
                          return (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          );
                        }
                      }

                    })

                  )}
                </Select>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                  whiteSpace={"nowrap"}
                  minWidth={"150px"}
                >
                  Starttidspunkt:{" "}
                </Text>
                <Input
                  variant="flushed"
                  type="date"
                  name="startDate"
                  defaultValue={currentAppointment.startDate}
                  onChange={(e) =>
                    updateNewAppointmentData("startDate", e.target.value)
                  }
                />
                <Input
                  variant="flushed"
                  type="time"
                  name="startTime"
                  defaultValue={currentAppointment.startTime}
                  onChange={(e) =>
                    updateNewAppointmentData("startTime", e.target.value)
                  }
                />
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                  whiteSpace={"nowrap"}
                  minWidth={"150px"}
                >
                  Sluttidspunkt:{" "}
                </Text>
                <Input
                  variant="flushed"
                  type="date"
                  name="endDate"
                  value={currentAppointment.endDate}
                  onChange={(e) =>
                    updateNewAppointmentData("endDate", e.target.value)
                  }
                />
                <Input
                  variant="flushed"
                  type="time"
                  name="endTime"
                  defaultValue={currentAppointment.endTime}
                  onChange={(e) =>
                    updateNewAppointmentData("endTime", e.target.value)
                  }
                />
              </Flex>

            </Flex>
          </ModalBody>
          <ModalFooter>
            {currentAppointment.id === '' ? (
              <Button onClick={() => { handleCreateUpdateAppointmentOnServer(true) }}>Opret aftale</Button>
            ) : (
              <>
                <Button onClick={() => { deleteWarningOnOpen() }}>Slet aftale</Button>
                <Button onClick={() => { handleCreateUpdateAppointmentOnServer(true) }} ml={3}>Opdater aftale</Button>
              </>
            )
            }
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet aftale
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteWarningOnClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={() => { deleteCalenderItemOnServer() }} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>


      <AlertDialog isOpen={overlapWarningIsOpen} leastDestructiveRef={overlapWarningCancelRef} onClose={overlapWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Overlappende aftale
            </AlertDialogHeader>

            <AlertDialogBody>Aftalen overlapper en eksisterende aftale.<br />Vil du stadig oprette den?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={overlapWarningOnClose}>
                Fortryd
              </Button>
              <Button onClick={() => { overlapWarningOnClose(); handleCreateUpdateAppointmentOnServer(false) }} ml={3}>
                Opret
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </>
  );
};

UserCalender.propTypes = {
  selectedOrder: PropTypes.any,
  updateComponent: PropTypes.func,
};

export default UserCalender;
